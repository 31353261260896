import {
    JackpotAction,
    JackpotActionTypeList,
    JackpotState,
    SWITCH_JACKPOT_DISPLAY,
    UPDATE_GRAND_JACKPOT,
    UPDATE_JACKPOTS,
    UPDATE_MAJOR_JACKPOT,
} from './types';
import { assertNeverAction, isActionType } from '../action-assertions';
import { LobbyReducer } from '../reducer';

const initialState: JackpotState = {
    grandJackpots: {},
    majorJackpots: {},
    displayJackpot: 'GRAND',
};

export const jackpotReducer: LobbyReducer<JackpotState> = (state = initialState, action): JackpotState => {
    if (isActionType<JackpotAction>(action, JackpotActionTypeList)) {
        switch (action.type) {
            case UPDATE_JACKPOTS:
                return {
                    ...state,
                    grandJackpots: action.grandJackpots,
                    majorJackpots: action.majorJackpots,
                };
            case UPDATE_GRAND_JACKPOT: {
                const newGrandJackpots = { ...state.grandJackpots };
                newGrandJackpots[action.name] = {
                    ...newGrandJackpots[action.name],
                    ...action.values,
                };
                return {
                    ...state,
                    grandJackpots: newGrandJackpots,
                };
            }
            case UPDATE_MAJOR_JACKPOT: {
                const newMajorJackpots = { ...state.majorJackpots };
                newMajorJackpots[action.name] = {
                    ...newMajorJackpots[action.name],
                    ...action.values,
                };
                return {
                    ...state,
                    majorJackpots: newMajorJackpots,
                };
            }
            case SWITCH_JACKPOT_DISPLAY: {
                const displayJackpot = state.displayJackpot === 'GRAND' ? 'MAJOR' : 'GRAND';
                return {
                    ...state,
                    displayJackpot,
                };
            }
            default:
                assertNeverAction(action as never);
        }
    }
    return state;
};
