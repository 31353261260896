type ClientToggleConfig = {
    // When enabled, the store will get items from the store-api (instead of chu-offers)
    readonly ENABLE_NEW_STORE_OVERRIDE_PT: boolean;
    readonly USE_TAP_TO_FULL_SCREEN: boolean;
    readonly ENABLE_QUESTS: boolean;
    readonly ENABLE_BANK_VERIFICATIONS: boolean;
    readonly ENABLE_ACCOUNT_VERIFICATIONS: boolean;
    readonly ENABLE_CHUMBA_FALLBACK_BANNER: boolean;
    readonly ENABLE_NEW_SUB_NAV: boolean;
    readonly ALLOW_INSTALL_PWA: boolean;
    readonly ENABLE_FEATURE_RESTRICTIONS: boolean;
};

const envClientToggleConfigs: {
    readonly local: ClientToggleConfig;
    readonly dev: ClientToggleConfig;
    readonly test: ClientToggleConfig;
    readonly prod: ClientToggleConfig;
} = {
    local: {
        ENABLE_NEW_STORE_OVERRIDE_PT: true,
        USE_TAP_TO_FULL_SCREEN: true,
        ENABLE_QUESTS: true,
        ENABLE_BANK_VERIFICATIONS: true,
        ENABLE_ACCOUNT_VERIFICATIONS: true,
        ENABLE_CHUMBA_FALLBACK_BANNER: true,
        ENABLE_NEW_SUB_NAV: true,
        ALLOW_INSTALL_PWA: true,
        ENABLE_FEATURE_RESTRICTIONS: true,
    },
    dev: {
        ENABLE_NEW_STORE_OVERRIDE_PT: true,
        USE_TAP_TO_FULL_SCREEN: true,
        ENABLE_QUESTS: true,
        ENABLE_BANK_VERIFICATIONS: false, //No Payments dev env
        ENABLE_ACCOUNT_VERIFICATIONS: false,
        ENABLE_CHUMBA_FALLBACK_BANNER: true,
        ENABLE_NEW_SUB_NAV: true,
        ALLOW_INSTALL_PWA: true,
        ENABLE_FEATURE_RESTRICTIONS: true,
    },
    test: {
        ENABLE_NEW_STORE_OVERRIDE_PT: true,
        USE_TAP_TO_FULL_SCREEN: false,
        ENABLE_QUESTS: true,
        ENABLE_BANK_VERIFICATIONS: true,
        ENABLE_ACCOUNT_VERIFICATIONS: true,
        ENABLE_CHUMBA_FALLBACK_BANNER: true,
        ENABLE_NEW_SUB_NAV: true,
        ALLOW_INSTALL_PWA: false,
        ENABLE_FEATURE_RESTRICTIONS: true,
    },
    prod: {
        ENABLE_NEW_STORE_OVERRIDE_PT: false,
        USE_TAP_TO_FULL_SCREEN: false,
        ENABLE_QUESTS: false, //TODO: To start the staff beta, set this to true.
        ENABLE_BANK_VERIFICATIONS: true,
        ENABLE_ACCOUNT_VERIFICATIONS: true,
        ENABLE_CHUMBA_FALLBACK_BANNER: false,
        ENABLE_NEW_SUB_NAV: true,
        ALLOW_INSTALL_PWA: false,
        ENABLE_FEATURE_RESTRICTIONS: false,
    },
};

export const ClientToggleConfig = ((): ClientToggleConfig => {
    switch (window.location.hostname) {
        case 'localhost':
        case '127.0.0.1':
            return envClientToggleConfigs.local;
        case 'lobby-dev.dev.chumbacasino.com':
        case 'lobby-dev-dr.dev.chumbacasino.com':
            return envClientToggleConfigs.dev;
        case 'lobby-test.dev.chumbacasino.com':
            return envClientToggleConfigs.test;
        case 'lobby.chumbacasino.com':
            return envClientToggleConfigs.prod;
    }
    throw new Error('Invalid hostname');
})();
