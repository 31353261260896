import React, { useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { hideModal } from 'store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { UPDATE_BANK_ACCOUNT } from '../modal-props-types';
import { paymentsVerificationsRedirect } from 'common/services/customer';
import { UpdateBankAccountModalActions } from './update-bank-account-modal-actions';
import { ReactComponent as Unsuccessful } from '../../../../../common/images/exclamation-icon.svg';

import './update-bank-account-modal.scss';

export const UpdateBankAccountModal = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const id: GenericModalTypes = UPDATE_BANK_ACCOUNT;
    const modalName = 'update-bank-account-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <Unsuccessful className={`${modalName}__icon`} />
            <h2 className={`${modalName}__header`}>Update your bank account</h2>
            <p className={`${modalName}__text`}>
                Any pending redemptions will go to your previously verified bank account. All future redemptions will go
                to your new bank account.
            </p>
            <p className={`${modalName}__text`}>
                Verifying bank details can take up to 24hrs, during this period you won’t be able to submit new
                redemption requests.
            </p>
            <UpdateBankAccountModalActions
                modalName={modalName}
                isLoading={isLoading}
                handleContinue={handleContinue}
                handleCloseModal={handleCloseModal}
            />
        </GenericModalLayout>
    );

    async function handleContinue(e: MouseEvent<HTMLButtonElement>) {
        try {
            e.preventDefault();
            setIsLoading(false);
            await paymentsVerificationsRedirect({
                bankDetails: true,
                photoId: false,
                residentialAddress: false,
            });
        } catch (err) {
            console.error('redirectToPayment ', err);
        } finally {
            setIsLoading(true);
        }
    }

    function handleCloseModal() {
        dispatch(hideModal(UPDATE_BANK_ACCOUNT));
    }
};

export const getUpdateBankAccountModalProps = () => ({ type: UPDATE_BANK_ACCOUNT } as const);
