import { makeLobbyIcons } from '../lobby-icons/lobby-icons';

const GOLD_COINS_ICON = '/images/games/currency-icons/gold-icon.svg';
const SWEEPS_COINS_ICON = '/images/games/currency-icons/sweeps-icon.svg';
const SWEEPS_COINS_DARK_ICON = '/images/games/currency-icons/sweeps-icon-dark.svg';
const GOLD_COINS_ICON_3D = '/images/games/currency-icons/gold-icon-3d.svg';
const SWEEPS_COINS_ICON_3D = '/images/games/currency-icons/sweeps-icon-3d.svg';
const GOLD_COINS_IMG_3D = '/images/games/currency-icons/gold-img-3d.png';
const SWEEPS_COINS_IMG_3D = '/images/games/currency-icons/sweeps-img-3d.png';

export const GoldCoinsImg3D = GOLD_COINS_IMG_3D;
export const SweepsCoinsImg3D = SWEEPS_COINS_IMG_3D;
export const GoldCoinsIcon = makeLobbyIcons(GOLD_COINS_ICON, 'Gold Coins Icon');
export const SweepsCoinsIcon = makeLobbyIcons(SWEEPS_COINS_ICON, 'Sweeps Coins Icon');
export const SweepsCoinsDarkIcon = makeLobbyIcons(SWEEPS_COINS_DARK_ICON, 'Sweeps Coins Icon');
export const GoldCoinsIcon3D = makeLobbyIcons(GOLD_COINS_ICON_3D, 'Gold Coins Icon');
export const SweepsCoinsIcon3D = makeLobbyIcons(SWEEPS_COINS_ICON_3D, 'Sweeps Coins Icon');
