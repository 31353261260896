import * as types from './third-party-types';
import * as helpers from './third-party-helpers';
import { logger } from 'common/services/log/logger';
import store from '../store';
import { queueModal } from '../store/modal/actions';
import { getGameUnavailableModalProps } from 'components/elements/modal/game-unavailable';

const GAME_HAS_BEEN_DISABLED = 19;
const GAME_SESSION_WAS_NOT_FOUND = 20;
const GAME_SESSION_HAS_BEEN_CLOSED = 21;
const SELF_EXCLUSION_LIMIT = 81;
const ACCOUNT_BLOCKED = 8;
const SESSION_CLOSED_TWO_WINDOWS = 9;
const SESSION_CLOSED_TIMEOUT = 10;
const INSUFFICIENT_FUNDS = 31;

const showInformationWindowWithRefresh = (errorDescription: string): boolean => {
    helpers.showInformationWindow({
        type: 'SHOW_INFORMATION_WINDOW',
        text: errorDescription,
        refreshButton: true,
        xButton: false,
    });
    return true;
};

export const showGameUnavailableWarning = (heading: string, body: string): boolean => {
    store.dispatch(
        queueModal(
            getGameUnavailableModalProps({
                heading,
                body,
            })
        )
    );
    return true;
};

type ErrorMap = {
    [key: number]: () => boolean;
};

export const checkForErrors = (oRet: types.IFrameErrorResponse) => {
    const { errorCode, errorDesc } = oRet as {
        errorCode: number;
        errorDesc: string;
    };
    if (!errorCode) {
        return false;
    }

    logger.error('Found an error on iFrame response.', oRet);

    const errorMap: ErrorMap = {
        [GAME_HAS_BEEN_DISABLED]: () =>
            showGameUnavailableWarning(
                'Game Unavailable',
                'Sorry, the game is temporarily unavailable, please try again later.'
            ),
        [GAME_SESSION_WAS_NOT_FOUND]: () => showInformationWindowWithRefresh(errorDesc),
        [GAME_SESSION_HAS_BEEN_CLOSED]: () => showInformationWindowWithRefresh(errorDesc),
        [SELF_EXCLUSION_LIMIT]: () => showInformationWindowWithRefresh(errorDesc),
        [ACCOUNT_BLOCKED]: () => {
            helpers.showInformationWindow({
                type: 'SHOW_INFORMATION_WINDOW',
                text: errorDesc,
                refreshButton: false,
                xButton: false,
            });
            return true;
        },
        [SESSION_CLOSED_TWO_WINDOWS]: () => showInformationWindowWithRefresh(errorDesc),
        [SESSION_CLOSED_TIMEOUT]: () => showInformationWindowWithRefresh(errorDesc),

        // This error contains HRL from the game server.
        [INSUFFICIENT_FUNDS]: () =>
            showInformationWindowWithRefresh(
                'You have insufficient coins for this play. Please check your balance before trying again.'
            ),
    } as const;

    return errorMap[errorCode]
        ? errorMap[errorCode]()
        : errorDesc
        ? showInformationWindowWithRefresh(errorDesc)
        : false;
};
