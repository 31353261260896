import { CheckoutRejectedReason } from 'components/domain/new-store/api/new-store-http-client';
import { LegacyStoreItemType, StoreItemType } from 'store/store/new-store/types';

// Store types
export const STORE_FINALIZED_CHECKOUT_RECEIPT = 'STORE_FINALIZED_CHECKOUT_RECEIPT';
export const STORE_FAILED_CHECKOUT_RECEIPT = 'STORE_FAILED_CHECKOUT_RECEIPT';
export const STORE_INCOMPLETE_CHECKOUT_RECEIPT = 'STORE_INCOMPLETE_CHECKOUT_RECEIPT';
export const STORE_SHOW_STORE_MODAL = 'STORE_SHOW_STORE_MODAL';
export const STORE_CLOSE_STORE_MODAL = 'STORE_CLOSE_STORE_MODAL';
export const STORE_ITEM_CLICKED = 'STORE_ITEM_CLICKED';
export const LEGACY_STORE_ITEM_CLICKED = 'LEGACY_STORE_ITEM_CLICKED';

export type STORE_CLICK_STREAM_TYPE =
    | typeof STORE_FINALIZED_CHECKOUT_RECEIPT
    | typeof STORE_FAILED_CHECKOUT_RECEIPT
    | typeof STORE_INCOMPLETE_CHECKOUT_RECEIPT
    | typeof STORE_SHOW_STORE_MODAL
    | typeof STORE_CLOSE_STORE_MODAL
    | typeof STORE_ITEM_CLICKED
    | typeof LEGACY_STORE_ITEM_CLICKED;

export interface CheckoutReceiptPayload {
    durationInMs: number;
}

export interface ShowStorePayload {
    entryFrom: StoreEntryFrom;
    segmentIdentifier: string;
}

export interface CloseStorePayload {
    segmentIdentifier: string;
}

export interface StoreItemClickedPayload {
    itemType: StoreItemType;
    itemId: string;
    originalPrice: number;
    discountPrice: number;
    result: 'SUCCESS' | 'FAILED' | 'REJECTED';
    segmentIdentifier: string;
    reason?: CheckoutRejectedReason;
}

export interface LegacyStoreItemClickedPayload {
    itemType: LegacyStoreItemType;
    itemId: string;
    originalPrice: number;
    discountPrice: number;
    result: 'SUCCESS' | 'FAILED' | 'REJECTED';
    segmentIdentifier: string;
    reason?: string;
}

export enum StoreEntryFrom {
    TOP_HUB = 'TOP_HUD',
    SIDE_BAR = 'SIDE_BAR',
    FOOTER = 'FOOTER',
    URL = 'URL',
    PREVIEW_STORE = 'PREVIEW_STORE',
    OTHER = 'OTHER',
}
