import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { MARKET_EXIT_PENDING } from '../modal-prop-types';
import { MarketExitPendingModalActions } from './market-exit-pending-modal-actions';
import { logout } from 'helpers/logout';
import { ReactComponent as Pending } from '../../../2k-verification/pending-state.svg';

import './market-exit-pending-modal.scss';

export const MarketExitPendingModal = () => {
    const dispatch = useDispatch();
    const id: GenericModalTypes = MARKET_EXIT_PENDING;
    const modalName = 'market-exit-pending-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <Pending height='20%' width='20%' />
            <h2 className={`${modalName}__header`}>Verification in progress</h2>
            <p className={`${modalName}__content`}>
                Verifying your documents usually takes less than 24hrs, during this period you won’t be able access
                Chumba. You’ll be notified by email once the review is done.
            </p>
            <MarketExitPendingModalActions handleLogOutBtn={handleClickLogOutBtn} modalName={modalName} />
        </GenericModalLayout>
    );

    function handleClickLogOutBtn() {
        dispatch(hideModal(MARKET_EXIT_PENDING));
        logout();
    }
};

export const getMarketExitPendingModalProps = (shouldCloseOnOverlayClick = false, shouldCloseOnEsc = false) =>
    ({ type: MARKET_EXIT_PENDING, shouldCloseOnOverlayClick, shouldCloseOnEsc } as const);
