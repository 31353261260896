import { createSaga } from '../create';
import { GameActionTypeList } from './types';
import { queueModal } from '../modal/actions';
import { Dispatch } from 'redux';
import { AppAction } from '../app-action';
import { getGameUnavailableModalProps } from 'components/elements/modal/game-unavailable';
import { getTemporarilyUnavailableModalProps } from 'components/elements/modal/temporarily-unavailable';
import { getOpenStandardPlayGameModalProps } from 'components/elements/modal/standard-play';
import { Currency } from '@chu/http';

export { saga as gameSaga };

const { saga, handle } = createSaga();
const { LAUNCH_GAME, CHECK_IS_TEMPORARILY_UNAVAILABLE, CHECK_PLAYABLE_TOKENS, OPEN_GAME } = GameActionTypeList;

const dispatchErrorModal = (dispatch: Dispatch<AppAction>) => {
    dispatch(
        queueModal(
            getGameUnavailableModalProps({
                heading: '',
                body: 'There was an error loading the game.',
            })
        )
    );
};

handle(LAUNCH_GAME, async (action, dispatch, state) => {
    const game = state.layout.games.find((game) => game.gameId === action.gameId);
    if (game !== undefined) {
        dispatch({
            type: CHECK_IS_TEMPORARILY_UNAVAILABLE,
            gameId: action.gameId,
            active: game.metadata.active,
            history: action.history,
        });
        return;
    }

    dispatchErrorModal(dispatch);
});

handle(CHECK_IS_TEMPORARILY_UNAVAILABLE, async (action, dispatch, state) => {
    if (!state.user.isStaff && action.active === 4) {
        dispatch(
            queueModal(
                getTemporarilyUnavailableModalProps({
                    redirectToLobby: false,
                    shouldCloseOnOverlayClick: true,
                    shouldCloseOnEsc: true,
                })
            )
        );
        return;
    }

    const game = state.layout.games.find((game) => game.gameId === action.gameId);
    if (game !== undefined) {
        dispatch({
            type: CHECK_PLAYABLE_TOKENS,
            gameId: action.gameId,
            playableTokens: game.metadata.playableTokens,
            history: action.history,
        });
        return;
    }

    dispatchErrorModal(dispatch);
});

handle(CHECK_PLAYABLE_TOKENS, async (action, dispatch, state) => {
    const game = state.layout.games.find((game) => game.gameId === action.gameId);
    if (game !== undefined) {
        const slug = game.alternateGameId ? game.alternateGameId : game.gameId;

        if (action.playableTokens === 'gold' && state.system.currency !== Currency.GOLD) {
            dispatch(
                queueModal(
                    getOpenStandardPlayGameModalProps({
                        slug,
                        shouldCloseOnOverlayClick: true,
                        shouldCloseOnEsc: true,
                    })
                )
            );
            return;
        }

        dispatch({ type: OPEN_GAME, slug, history: action.history });
        return;
    }

    dispatchErrorModal(dispatch);
});

handle(OPEN_GAME, async (action, _dispatch, _state) => {
    action.history.push(`/games/${action.slug}`);
});
