import { Action } from 'redux';
import { getGenericModalProps } from '../../components/elements/modal/generic/generic-modal-prop-creators';
import { getFullScreenModalProps } from '../../components/elements/modal/full-screen/modal-prop-types';
import { getSourceOfWealthModalProps } from '../../components/elements/source-of-wealth/modal-prop-types';
import { getInsufficientFundsModalProps } from '../../components/elements/modal/insufficient-funds';
import { getSwipeToFullScreenModalProps } from '../../components/elements/modal/swipe-to-full-screen';

import {
    get2kBlockedModalProps,
    get2kPendingModalProps,
    get2kVerfiModalProps,
    get2kPendingReminderModalProps,
} from '../../components/elements/2k-verification/twok-props-creators';

import {
    getOfferModalProps,
    getConfirmModalProps,
    getOfferErrorModalProps,
    getStoreModalProps,
} from 'components/domain/packages/modals/props-creator';
import { getStandardModalProps } from 'components/elements/modal/standard/standard-modal-prop-creators';
import { getStoreReceiptModalProps } from 'components/domain/store/modals/store-receipt-modal/prop-types';
import { getStoreCheckoutReceiptModalProps } from 'components/domain/store/modals/checkout-receipt-modal/prop-types';
import { getSaleTermsAndCondtionsModalProps } from 'components/domain/store/modals/sales-terms-and-conditions/prop-types';
import { getPurchaseCancelledModalProps } from 'components/domain/store/modals/purchase-cancelled/prop-types';
import { getResponsibleGameplayModalProps } from '../../components/elements/responsible-gameplay/responsible-gameplay-modal-props-creators';
import { getRegulatoryLimitModalProps } from '../../components/elements/modal/regulatory-limit';
import { getChallengesTermsAndConditions } from 'components/domain/challenges/terms-and-conditions/prop-types';
import {
    getActivityReminderErrorProps,
    getActivityReminderUnsavedChangesProps,
} from '../../components/domain/responsible-gameplay/modal-prop-creators';
import { getGameUnavailableModalProps } from 'components/elements/modal/game-unavailable';
import { getTapToFullScreenModalProps } from 'components/elements/modal/tap-to-full-screen';
import { getGCTopupModalProps } from 'components/elements/modal/gc-topup';
import { getEducationModalQueueProps } from '../../components/domain/education/education-modal-prop-creators';
import { getIdahoSweepsModalProps } from '../../components/domain/idaho-sweeps-rule-update/idaho-sweeps-rule-update';
import { getRedemptionErrorModalProps } from '../../components/domain/packages/modals/redeem/redemption-error-prop-creators';
import { getLogoutModalProps } from '../../components/elements/modal/logout/logout-modal-prop-creators';
import { getTemporarilyUnavailableModalProps } from 'components/elements/modal/temporarily-unavailable';
import { getOpenStandardPlayGameModalProps } from 'components/elements/modal/standard-play';
import { getUpdateAddressModalProps } from '../../components/elements/modal/account-verification/update-address-modal';
import { getGetVerifiedModalProps } from 'components/domain/account-verification/modals/get-verified-modal';
import { getTermsOfUseV2ModalProps } from '../../components/elements/modal/terms-of-use/terms-of-use-v2-props-creators';
import { getPendingVerificationModalProps } from 'components/domain/account-verification/modals/pending-verification-modal';
import { getUnsuccessfulVerificationModalProps } from 'components/domain/account-verification/modals/unsuccessful-verification-modal';
import { getReverifyVerificationModalProps } from 'components/domain/account-verification/modals/reverify-modal';
import { getReverifyAtLoginModalProps } from 'components/domain/account-verification/modals/reverify-at-login-modal';
import { getLegacyCustomerUpdateAddressModalProps } from 'components/elements/modal/account-verification/legacy-customer-update-address-modal';
import { getAccessRestrictionsDetailsModalProps } from '../../components/elements/modal/access-restrictions/access-restrictions-details-modal';
import { getTakeABreakConfirmationModalProps } from 'components/elements/modal/access-restrictions/take-a-break-confirmation/take-a-break-confirmation-modal';
import { getSelfExclusionConfirmationModalProps } from 'components/elements/modal/access-restrictions/self-exclusion-confirmation/self-exclusion-confirmation-modal';
import { getMarketExitNotYetVerifiedModalProps } from 'components/elements/modal/market-exit/not-yet-verified/market-exit-not-yet-verified-modal';
import { getMarketExitPendingModalProps } from 'components/elements/modal/market-exit/pending/market-exit-pending-modal';
import { getMarketExitRejectModalModalProps } from 'components/elements/modal/market-exit/reject/market-exit-reject-modal';
import { getMarketExitReverifyModalProps } from 'components/elements/modal/market-exit/reverify/market-exit-reverify-modal';
import { getMarketExitInformModalProps } from 'components/elements/modal/market-exit/inform/market-exit-inform-modal';
import { getGameplayLimitsDetailsModalProps } from 'components/elements/modal/gameplay-limits/gameplay-limits-details-modal';
import { getAddPurchaseLimitConfirmationModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/add-purchase-limit-confirmation-modal';
import { getMangeLimitSettingsModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/update-remove-limit-settings-modal';
import { getRemovePurchaseLimitConfirmationModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/remove-purchase-limit-confirmation-modal';
import { getCancelModificationLimitSettingsModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/cancel-modification-limit-settings-modal';
import { getCancelRevocationLimitSettingsModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/cancel-revocation-limit-settings-modal';
import { getCancelModificationPurchaseLimitConfirmationModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/cancel-purchase-limit-modification-confirmation-modal';
import { getCancelRevocationPurchaseLimitConfirmationModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/cancel-purchase-limit-revocation-confirmation-modal';
import { getEditPurchaseLimitConfirmationModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/edit-purchase-limit-confirmation-modal';
import { getGameplaySystemPurchaseLimitInfo } from 'components/elements/modal/gameplay-limits/gameplay-system-purchase-limit-info-modal';
import {
    getLinkYourBankAccountModalProps,
    getUpdateBankAccountModalProps,
} from 'components/elements/modal/bank-verification';
import { getIneligibleRegionModalProps } from 'components/elements/modal/sdd-compliance/ineligible-region-modal';
import { getPurchaseLimitReachedModalProps } from 'components/elements/modal/gameplay-limits/purchase-limit/purchase-limit-reached-modal';
import { getPurchaseDeclinedModalProps } from 'components/elements/modal/purchase/purchase-declined-modal';
import { getUnderageBlockModalProps } from 'components/elements/modal/sdd-compliance/underage-block-modal';
import { getMustKYCVerificationModalProps } from '../../components/elements/modal/must-kyc/must-kyc-modal';
import { getQuestsModalProps } from 'components/domain/quests/modals/quests-modal';
import { getMustKYCAccountPendingVerificationModalProps } from 'components/elements/modal/must-kyc/must-kyc-account-pending-verification-modal';
import { getMustKYCUnsuccessfulVerificationModalProps } from 'components/elements/modal/must-kyc/must-kyc-rejected-verification-modal';
import { getPushPrimerModalProps } from '../../components/elements/modal/push-notifications/push-notifications-primer-modal';

export const POP_MODAL = 'POP_MODAL';
export const QUEUE_MODAL_FRONT = 'QUEUE_MODAL_FRONT';
export const DEQUEUE_MODAL = 'DEQUEUE_MODAL';
export const QUEUE_MODAL = 'QUEUE_MODAL';
export const REPLACE_MODAL = 'REPLACE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const ModalActionTypeList = {
    POP_MODAL,
    QUEUE_MODAL_FRONT,
    DEQUEUE_MODAL,
    QUEUE_MODAL,
    REPLACE_MODAL,
    HIDE_MODAL,
} as const;

export type ModalEngineState = {
    queuedModals: MODAL_PROP_TYPES[];
    activeModalProps?: MODAL_PROP_TYPES;
};

export interface PopModalAction extends Action {
    type: typeof POP_MODAL;
}

export interface QueueModalAction extends Action {
    type: typeof QUEUE_MODAL;
    modalProps: MODAL_PROP_TYPES;
}

export interface DequeueModalAction extends Action {
    type: typeof DEQUEUE_MODAL;
    modalType: MODAL_TYPES;
}

export interface ReplaceModalAction extends Action {
    type: typeof REPLACE_MODAL;
    modalProps: MODAL_PROP_TYPES;
}

export interface QueueModalFrontAction extends Action {
    type: typeof QUEUE_MODAL_FRONT;
    modalProps: MODAL_PROP_TYPES;
}

export interface HideModalAction extends Action {
    type: typeof HIDE_MODAL;
    modalType: MODAL_TYPES;
}

export type ModalEngineAction =
    | PopModalAction
    | QueueModalFrontAction
    | DequeueModalAction
    | QueueModalAction
    | ReplaceModalAction
    | HideModalAction;

export type MODAL_TYPES = MODAL_PROP_TYPES['type'];

export type MODAL_PROP_TYPES = (
    | ReturnType<typeof getGenericModalProps>
    | ReturnType<typeof getFullScreenModalProps>
    | ReturnType<typeof getSourceOfWealthModalProps>
    | ReturnType<typeof getTermsOfUseV2ModalProps>
    | ReturnType<typeof getStoreModalProps>
    | ReturnType<typeof getInsufficientFundsModalProps>
    | ReturnType<typeof get2kBlockedModalProps>
    | ReturnType<typeof get2kPendingModalProps>
    | ReturnType<typeof get2kVerfiModalProps>
    | ReturnType<typeof getMustKYCVerificationModalProps>
    | ReturnType<typeof getMustKYCAccountPendingVerificationModalProps>
    | ReturnType<typeof getMustKYCUnsuccessfulVerificationModalProps>
    | ReturnType<typeof getLogoutModalProps>
    | ReturnType<typeof getOfferModalProps>
    | ReturnType<typeof getConfirmModalProps>
    | ReturnType<typeof getRedemptionErrorModalProps>
    | ReturnType<typeof getOfferErrorModalProps>
    | ReturnType<typeof getStandardModalProps>
    | ReturnType<typeof getEducationModalQueueProps>
    | ReturnType<typeof getSwipeToFullScreenModalProps>
    | ReturnType<typeof getStoreReceiptModalProps>
    | ReturnType<typeof getStoreCheckoutReceiptModalProps>
    | ReturnType<typeof getSaleTermsAndCondtionsModalProps>
    | ReturnType<typeof getPurchaseCancelledModalProps>
    | ReturnType<typeof getIdahoSweepsModalProps>
    | ReturnType<typeof getRegulatoryLimitModalProps>
    | ReturnType<typeof getChallengesTermsAndConditions>
    | ReturnType<typeof getResponsibleGameplayModalProps>
    | ReturnType<typeof getActivityReminderUnsavedChangesProps>
    | ReturnType<typeof getActivityReminderErrorProps>
    | ReturnType<typeof getTapToFullScreenModalProps>
    | ReturnType<typeof getGameUnavailableModalProps>
    | ReturnType<typeof getGCTopupModalProps>
    | ReturnType<typeof getTemporarilyUnavailableModalProps>
    | ReturnType<typeof get2kPendingReminderModalProps>
    | ReturnType<typeof getOpenStandardPlayGameModalProps>
    | ReturnType<typeof getUpdateAddressModalProps>
    | ReturnType<typeof getGetVerifiedModalProps>
    | ReturnType<typeof getPendingVerificationModalProps>
    | ReturnType<typeof getUnsuccessfulVerificationModalProps>
    | ReturnType<typeof getReverifyVerificationModalProps>
    | ReturnType<typeof getReverifyAtLoginModalProps>
    | ReturnType<typeof getLegacyCustomerUpdateAddressModalProps>
    | ReturnType<typeof getAccessRestrictionsDetailsModalProps>
    | ReturnType<typeof getGameplayLimitsDetailsModalProps>
    | ReturnType<typeof getSelfExclusionConfirmationModalProps>
    | ReturnType<typeof getTakeABreakConfirmationModalProps>
    | ReturnType<typeof getMarketExitNotYetVerifiedModalProps>
    | ReturnType<typeof getMarketExitPendingModalProps>
    | ReturnType<typeof getMarketExitRejectModalModalProps>
    | ReturnType<typeof getMarketExitReverifyModalProps>
    | ReturnType<typeof getMarketExitInformModalProps>
    | ReturnType<typeof getAddPurchaseLimitConfirmationModalProps>
    | ReturnType<typeof getEditPurchaseLimitConfirmationModalProps>
    | ReturnType<typeof getMangeLimitSettingsModalProps>
    | ReturnType<typeof getRemovePurchaseLimitConfirmationModalProps>
    | ReturnType<typeof getCancelModificationLimitSettingsModalProps>
    | ReturnType<typeof getCancelRevocationLimitSettingsModalProps>
    | ReturnType<typeof getCancelModificationPurchaseLimitConfirmationModalProps>
    | ReturnType<typeof getCancelRevocationPurchaseLimitConfirmationModalProps>
    | ReturnType<typeof getGameplaySystemPurchaseLimitInfo>
    | ReturnType<typeof getUpdateBankAccountModalProps>
    | ReturnType<typeof getLinkYourBankAccountModalProps>
    | ReturnType<typeof getIneligibleRegionModalProps>
    | ReturnType<typeof getPurchaseLimitReachedModalProps>
    | ReturnType<typeof getPurchaseDeclinedModalProps>
    | ReturnType<typeof getUnderageBlockModalProps>
    | ReturnType<typeof getQuestsModalProps>
    | ReturnType<typeof getPushPrimerModalProps>
) & {
    /**
     * Defaults to true
     */
    shouldCloseOnOverlayClick?: boolean;
    /**
     * Defaults to true
     */
    shouldCloseOnEsc?: boolean;
};
