import React from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';
import { LobbyConstants } from 'chumba/lobby-constants';

export const MarketExitNotYetVerifiedModalActions: React.FC<CallToActionProps> = ({
    handleLogOut,
    handleVerify,
    modalName,
}: CallToActionProps) => (
    <>
        <div className={`${modalName}__button-group`}>
            <ThemeButton
                id={`${modalName}__get-verified-btn`}
                data-testid={`${modalName}__get-verified-btn`}
                className={`${modalName}__get-verified-btn`}
                actionType='primary'
                theme='red-dark'
                size='md'
                onClick={handleVerify}
            >
                GET VERIFIED
            </ThemeButton>
            <ThemeButton
                id={`${modalName}__log-out-btn`}
                data-testid={`${modalName}__log-out-btn`}
                className={`${modalName}__log-out-btn`}
                theme='red-dark'
                actionType='secondary'
                size='md'
                onClick={handleLogOut}
            >
                LOG OUT
            </ThemeButton>
        </div>
        <a
            id={`${modalName}__zendesk-link`}
            data-testid={`${modalName}__zendesk-link`}
            className={`${modalName}__zendesk-link`}
            target='_blank'
            href={LobbyConstants.whyDoINeedToVerifyMyAccountUrl}
        >
            Why is this needed?
        </a>
    </>
);

interface CallToActionProps {
    handleLogOut: () => void;
    handleVerify: () => void;
    modalName: string;
}
