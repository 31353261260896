import { mapDueDiligenceStatusToVerificationStatus } from './player-verification-status';
import {
    DueDiligenceStatus,
    LOGIN_USER,
    LoginUserAction,
    LOGOUT_USER,
    LogoutUserAction,
    SYNC_USER_TOKEN_BALANCE,
    SyncUserTokenBalanceAction,
    UPDATE_USER_VERIFICATION_STATUS,
    UpdateUserVerificationStatus,
    User,
} from './types';

export const loginUser = (user: User): LoginUserAction => ({
    type: LOGIN_USER,
    payload: user,
});

export const logoutUser = (): LogoutUserAction => ({
    type: LOGOUT_USER,
});

export function setPlayerVerificationStatus(
    verificationStatus: DueDiligenceStatus | null
): UpdateUserVerificationStatus {
    const status = mapDueDiligenceStatusToVerificationStatus(verificationStatus);
    return {
        type: UPDATE_USER_VERIFICATION_STATUS,
        status,
    };
}

export function createSyncUserTokenBalanceAction(): SyncUserTokenBalanceAction {
    return {
        type: SYNC_USER_TOKEN_BALANCE,
    };
}
