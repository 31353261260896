import axios from 'axios';
import { logger } from 'common/services/log/logger';
import { ClientConfig } from 'config/client-config';

type CreateSessionRequest = {
    returnUrl: string;
};

type CreateSessionResponse = {
    success: boolean;
    redirectUrl?: string;
    error?: {
        code: number;
        message: string;
    };
};

export const createSession = async (request: CreateSessionRequest): Promise<CreateSessionResponse> => {
    try {
        const response = await axios.post<CreateSessionResponse>(
            `${ClientConfig.PURCHASE_URL}/amoe-hub/create-session`,
            request,
            {
                withCredentials: true,
            }
        );

        return {
            success: true,
            redirectUrl: response.data.redirectUrl,
        };
    } catch (error) {
        logger.error('AMOE Hub API Request Failed', error, request);

        const errorResponse = {
            code: 500,
            message: 'AMOE Hub API Request Failed',
        };

        if (axios.isAxiosError(error)) {
            errorResponse.code = error.response?.status ?? 500;
            errorResponse.message = JSON.stringify(error.response?.data) ?? 'AMOE Hub API Request Failed';
        }

        return {
            success: false,
            error: errorResponse,
        };
    }
};
