import * as React from 'react';
import { Dispatch } from 'redux';

import { userReducer } from './user/reducers';
import { layoutReducer } from './layout/reducers';
import { systemReducer } from './system/reducers';
import { gameReducer } from './game/reducers';
import { modalReducer } from './modal/reducers';
import { uiReducer } from './ui/reducers';
import { jackpotReducer } from './jackpot/reducers';
import { connect } from 'react-redux';
import { createGTMMiddleware } from '../common/services/analytics/gtm/middleware';
import { storeReducer } from './store/reducers';
import { layoutSaga } from './layout/saga';
import { userSaga } from './user/saga';
import { storeSaga } from './store/saga';
import { uiSaga } from './ui/saga';
import { gameSaga } from './game/saga';
import { challengesReducer } from './challenges/reducers';
import { challengeSaga } from './challenges/saga';
import { createClickstreamMiddleware } from 'common/services/analytics/clickstream/middleware';
import { Comp, StateMap } from './types';
import { AppState } from './app-state';
import { AppAction } from './app-action';
import { configureStore } from '@reduxjs/toolkit';
import { playerTokenSliceReducer } from './player-token/player-token-slice';

export const reduxConnector = (stateMap: StateMap) => connect(stateMap);

/**
 * @deprecated
 *
 * This function should not be used to retrieve state from the store as it causes issues with re-rendering and adds
 * unnecessary complexity.
 *
 * *useSelector* should be used instead. https://react-redux.js.org/api/hooks#useselector
 */
export function withRootState<T, TProps = {}>(
    map: StateMap<T>,
    comp: Comp<T & TProps & { dispatch: Dispatch<AppAction> }>
): React.FunctionComponent<TProps> {
    const Child = connect<T, AppState, TProps, AppState>((state) => ({
        ...map({ ...state, dispatch: store.dispatch }),
        dispatch: store.dispatch,
    }))(comp as any);
    const componentWrapper = (props: TProps) => <Child {...props} />;
    return componentWrapper;
}

const store = configureStore({
    reducer: {
        user: userReducer,
        system: systemReducer,
        layout: layoutReducer,
        game: gameReducer,
        ui: uiReducer,
        modal: modalReducer,
        jackpot: jackpotReducer,
        store: storeReducer,
        challenges: challengesReducer,
        playerToken: playerTokenSliceReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            createGTMMiddleware(),
            createClickstreamMiddleware(),
            layoutSaga,
            userSaga,
            uiSaga,
            storeSaga,
            challengeSaga,
            gameSaga
        ),
});

export default store;
