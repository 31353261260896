import { httpClient } from '../common/services/net/http-client';
import { LayoutState, Game } from 'store/layout/types';
import { ErrorResponse, GameLayoutInformation, GameMetadata, GameProvider, LobbyLayoutResponse } from '@chu/http';
import { gameCompanyMapper } from 'helpers/games';
import axios from 'axios';
import { ClientConfig } from '../config/client-config';

const addProviderAndMetaToGames = (games: GameLayoutInformation[], gamesMetadata: GameMetadata[]): Game[] => {
    return games.reduce((arr: Game[], game: GameLayoutInformation) => {
        const gameMetadata = gamesMetadata.find((meta) => meta.gameId === game.gameId);

        if (!gameMetadata) {
            console.error(`Metadata for [${game.gameId}] missing`);
            return arr;
        }

        const mappedProvider = gameCompanyMapper(gameMetadata.company);

        if (!mappedProvider) {
            console.error(`Game provider [${gameMetadata.company.toUpperCase()}] not recognised`);
            return arr;
        }
        return arr.concat([
            {
                ...game,
                metadata: { ...gameMetadata, provider: mappedProvider },
                alternateGameId: getAlternateGameId(game.gameId, mappedProvider),
            },
        ]);
    }, []);
};

const getAlternateGameId = (gameId: string, mappedProvider: GameProvider | undefined): string => {
    return mappedProvider === 'NETENT' ? gameId.replace('_not_mobile', '') : gameId;
};

const isErrorResponse = (response: LobbyLayoutResponse | ErrorResponse): response is ErrorResponse =>
    'message' in response;

export async function getLobbyLayout(): Promise<LayoutState> {
    const response = await httpClient.get('/api/lobby/layout');
    if (isErrorResponse(response)) {
        throw new Error(`Couldn't load lobby layout: ${response.message}`);
    }

    const { games, inactiveGames, metadata, id, recentlyPlayed, justDropped, blockedGameIds, blockedGameTypes } =
        response;

    const activeGames = addProviderAndMetaToGames(games, metadata);
    const inactiveGamesInfo = addProviderAndMetaToGames(inactiveGames, metadata);

    return {
        id,
        games: activeGames,
        inactiveGames: inactiveGamesInfo,
        recentlyPlayed,
        recommendedGames: [],
        topGames: [],
        justDropped,
        favoriteGames: [],
        categories: undefined,
        blockedGameIds,
        blockedGameTypes,
    };
}

export async function getTopGames(): Promise<string[]> {
    const res = await axios.get(ClientConfig.REQUEST_PATH_HOST_SEGMENT + '/api/top-games', {
        withCredentials: true,
    });

    if (res.status === 200) {
        const { gameIds } = res.data;
        return gameIds;
    } else {
        //TODO confirm if we need the default list client side too
        console.error('Error retrieving top games from server.');
        return [];
    }
}
