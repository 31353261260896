import axios from 'axios';
import { UserVerificationStatus } from 'store/user/types';
import { logger } from './log/logger';
import { ClientConfig } from 'config/client-config';

export type CustomerVerificationPayload = {
    photoId: boolean;
    residentialAddress: boolean;
    bankDetails: boolean;
};

export async function paymentsVerificationsRedirect(payload: CustomerVerificationPayload) {
    try {
        const res = await axios.post<{ redirectUrl: string }>(
            `${ClientConfig.CUSTOMER_API_URL}/verifications/verify`,
            { ...payload },
            {
                withCredentials: true,
            }
        );

        if (res.status === HTTP_STATUS_CREATED && res.data.redirectUrl) {
            window.location.href = res.data.redirectUrl;
            return;
        }
    } catch (err) {
        if (axios.isAxiosError(err)) {
            logger.warn('Error while trying to obtain a redirectURL', {
                code: err.code,
                state: err.response?.status,
                data: err.response?.data,
            });
        }

        throw err;
    }
}

const HTTP_STATUS_CREATED = 201;

export async function getVerifications(): Promise<CustomerVerifications | undefined> {
    try {
        const res = await axios.get(`${ClientConfig.CUSTOMER_API_URL}/verifications`, {
            withCredentials: true,
        });
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            return undefined;
        }

        throw error;
    }
}

export type CustomerVerifications = {
    identity?: Verification;
    address?: Verification;
    bank?: Verification;
};

export type Verification = {
    status: VerificationStatus;
    document?: {
        type: string;
        details: string;
    };
    reasons?: VerificationErrorReason[];
};

export enum VerificationStatus {
    Pending = 'Pending',
    Rejected = 'Rejected',
    Failed = 'Failed',
    Verified = 'Verified',
}

export const legacyVerificationMapper: Record<number, VerificationStatus> = {
    [UserVerificationStatus.PENDING]: VerificationStatus.Pending,
    [UserVerificationStatus.ACCEPT]: VerificationStatus.Verified,
    [UserVerificationStatus.VERIFIED]: VerificationStatus.Verified,
};

export type VerificationErrorReason = {
    reason: string;
    detail: string;
    action: VerificationErrorAction;
};

export type VerificationErrorAction = 'tryAgain' | 'contactUs';

export enum CustomerVerificationType {
    IDENTITY = 'identity',
    ADDRESS = 'address',
    BANK = 'bank',
}
