import { AppAction } from '../../app-action';
import { Dispatch } from 'redux';
import { getQueryParameter } from '../../../helpers/url';
import { queueModalFront } from '../../modal/actions';
import { getStoreModalProps } from '../../../components/domain/packages/modals/props-creator';
import { StoreEntryFrom } from 'common/services/analytics/store/store-types';

export const previewStoreHandler = (action: AppAction, dispatch: Dispatch<AppAction>) => {
    const previewCashier = getQueryParameter('previewCashier');
    if (!previewCashier) {
        return;
    }
    dispatch(queueModalFront(getStoreModalProps(StoreEntryFrom.PREVIEW_STORE, previewCashier)));
};
