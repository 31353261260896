import { cookieOverride } from '../cookie-override/cookie-override';
import { isInTestGroupWithCookieOverride } from './is-in-test-group-with-cookie-override';
import {
    IsPlayerInBucket,
    createRandomPlayerDistribution,
} from '../random-player-distribution/create-random-player-distribution';

interface ABCPlayerDistributionParams {
    bucketPercentages: [number, number, number];
    seed: string;
    name: string;
}

export const createABCTest = (
    params: ABCPlayerDistributionParams
): [IsPlayerInBucket, IsPlayerInBucket, IsPlayerInBucket] => {
    const groupACookieOverride = cookieOverride(params.name, 'A');
    const groupBCookieOverride = cookieOverride(params.name, 'B');
    const groupCCookieOverride = cookieOverride(params.name, 'C');

    const [isPlayerInBucketA, isPlayerInBucketB, isPlayerInBucketC] = createRandomPlayerDistribution(params);

    return [
        isInTestGroupWithCookieOverride(groupACookieOverride, isPlayerInBucketA),
        isInTestGroupWithCookieOverride(groupBCookieOverride, isPlayerInBucketB),
        isInTestGroupWithCookieOverride(groupCCookieOverride, isPlayerInBucketC),
    ];
};
