declare global {
    interface Window {
        LOBBY_COMMIT_HASH: string | undefined;
    }
}

// https://create-react-app.dev/docs/adding-custom-environment-variables/
// The env variable must start with REACT_APP_ to be included
window.LOBBY_COMMIT_HASH = process.env.REACT_APP_LOBBY_COMMIT_HASH;
export {};
