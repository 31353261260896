import axios from 'axios';
import store from 'store';
import { logger } from '../log/logger';
import { ClientConfig } from 'config/client-config';

export type TrackedUserAction = {
    actionName: string;
    [key: string]: any;
};

export const sendToClickStream = async (action: TrackedUserAction) => {
    const {
        user: { userId },
        system: { clientSessionID },
    } = store.getState();

    if (!ClientConfig.CLICKSTREAM_URL) {
        logger.warn('attempted to send to clickstream but the clickstream url was not defined', action.actionName);
        return;
    }

    try {
        const clickstreamRecord = {
            userId,
            time: Date.now(),
            ...action,
            sessionId: clientSessionID,
        };

        await axios.get<void>(
            `${ClientConfig.CLICKSTREAM_URL}?payload=${btoa(encodeURI(JSON.stringify(clickstreamRecord)))}`
        );

        setFullStoryEvent(action);
    } catch (e) {
        logger.error(`failed to send action to clickstream [${action.actionName}]`, e);
    }
};

const setFullStoryEvent = (event: TrackedUserAction) => {
    if (!window.FS) {
        return;
    }

    window.FS.event(event.actionName, event);
};
