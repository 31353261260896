import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import { getOpenStandardPlayGameModalProps, OPEN_STANDARD_PLAY_GAME } from './modal-prop-types';
import { GoldCoinsIcon3D } from 'components/elements/currency-icons';
import { ThemeButton } from 'components/elements/button/theme-buttons';
import { useHistory } from 'react-router-dom';
import { attemptSwitchCurrency } from 'chumba/third-party-helpers';

import './open-standard-play-game.scss';

type StandardPlayModalProperties = Omit<ReturnType<typeof getOpenStandardPlayGameModalProps>, 'type'> & {
    slug: string;
};

export const OpenStandardPlayGameModal: React.FC<StandardPlayModalProperties> = ({ slug }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCloseModal = (): void => {
        dispatch(hideModal(OPEN_STANDARD_PLAY_GAME));
    };

    const handleSwitchAndPlay = (): void => {
        dispatch(hideModal(OPEN_STANDARD_PLAY_GAME));
        attemptSwitchCurrency();
        history.push(`/games/${slug}`);
    };

    return (
        <div className={'GENERIC-modal__modal-inner'} id={`dialog-${OPEN_STANDARD_PLAY_GAME}`}>
            <div className='standard-play-container'>
                <h2 className='standard-play-container__header'>Available in Standard Play</h2>
                <GoldCoinsIcon3D />
                <span className='standard-play-container__text'>
                    This game is only available in Standard Play. You will need to switch to Gold Coins to play.
                </span>
            </div>
            <div className='standard-play-container__buttons'>
                <ThemeButton
                    onClick={handleCloseModal}
                    size='md'
                    actionType={'secondary'}
                    text={'NOT NOW'}
                    className='theme-btn__modal standard-play-container__buttons-not-now'
                    id={OPEN_STANDARD_PLAY_GAME}
                />
                <ThemeButton
                    onClick={handleSwitchAndPlay}
                    size='md'
                    actionType={'primary'}
                    text={'SWITCH TO GC'}
                    className='theme-btn__modal standard-play-container__buttons-switch'
                    id={OPEN_STANDARD_PLAY_GAME}
                />
            </div>
        </div>
    );
};
