import { InflightChallenge, CoinType } from './types';
import { History } from 'history';

export type ChallengesAction =
    | RequestCheckUserOptedIn
    | RequestSetUserOptedIn
    | RequestFetchUserChallenges
    | ReceivedIsChallengesEnabled
    | ReceivedUserOptedIn
    | ReceivedUserChallenges
    | ReceivedErrorFetchingChallenges
    | RequestClaimChallengeReward
    | ClaimChallengeRewardSuccess
    | ClaimChallengeRewardFailed
    | ChallengeCardClick
    | ChallengeCardViewed
    | NoChallengesMessageShown
    | AllChallengesCompletedMessageShown
    | ChallengeClaimShown
    | ChallengeDisplayOnboarding
    | SetUserHasUnseenClaims
    | ChallengeStarClicked
    | ShowDailyChallengesCTA;

type RequestCheckUserOptedIn = {
    type: 'REQUEST_USER_OPTED_IN';
};

type RequestSetUserOptedIn = {
    type: 'REQUEST_SET_USER_OPTED_IN';
    optIn: boolean;
};

type ReceivedIsChallengesEnabled = {
    type: 'RECEIVED_IS_CHALLENGES_ENABLED';
    isChallengesEnabled: boolean;
};

type ReceivedUserOptedIn = {
    type: 'RECEIVED_USER_OPTED_IN';
    hasOptedIn: boolean;
};

type RequestFetchUserChallenges = {
    type: 'REQUEST_FETCH_USER_CHALLENGES';
};

type ReceivedUserChallenges = {
    type: 'RECEIVED_USER_CHALLENGES';
    payload: {
        challenges: InflightChallenge[];
        dailyResetTime: Date;
        dailyChallengeSetId: string;
    };
};

type RequestClaimChallengeReward = {
    type: 'REQUEST_CLAIM_CHALLENGE_REWARD';
    inflightChallengeId: string;
};

export type ClaimChallengeRewardSuccess = {
    type: 'CLAIM_CHALLENGE_REWARD_SUCCESS';
    inflightChallengeId: string;
    coinType: CoinType;
    rewardAmount: number;
};

type ClaimChallengeRewardFailed = {
    type: 'CLAIM_CHALLENGE_REWARD_FAILED';
    inflightChallengeId: string;
};

type ChallengeCardClick = {
    type: 'CHALLENGE_CARD_CLICKED';
    inflightChallenge: InflightChallenge;
    history: History;
};

type ChallengeCardViewed = {
    type: 'CHALLENGE_CARD_VIEWED';
    inflightChallengeId: string;
    dailyChallengeSetId: string;
};

type NoChallengesMessageShown = {
    type: 'NO_CHALLENGES_MESSAGE_SHOWN';
};

type AllChallengesCompletedMessageShown = {
    type: 'ALL_CHALLENGES_COMPLETE_MESSAGE_SHOWN';
};

type SetUserHasUnseenClaims = {
    type: 'SET_USER_HAS_UNSEEN_CLAIMS';
    hasUnseenClaims: boolean;
};

type ChallengeClaimShown = {
    type: 'CHALLENGE_CLAIM_SHOWN';
    inflightChallengeId: string;
};

type ChallengeDisplayOnboarding = {
    type: 'CHALLENGE_SET_ONBOARDING';
    visible: boolean;
};

type ReceivedErrorFetchingChallenges = {
    type: 'RECEIVED_ERROR_FETCHING_CHALLENGES';
};

type ChallengeStarAttributes = {
    progress: number;
    callToAction: boolean;
    hasUnseenClaims: boolean;
};

type ChallengeStarClicked = {
    type: 'CHALLENGE_STAR_CLICKED';
    starAttributes: ChallengeStarAttributes;
};

type ShowDailyChallengesCTA = {
    type: 'SHOW_DAILY_CHALLENGES_CTA';
    dailyChallengeSetId: string;
};

export const createCheckUserOptedInAction = (): RequestCheckUserOptedIn => ({
    type: 'REQUEST_USER_OPTED_IN',
});

export const createRequestSetUserOptedInAction = (optIn: boolean): RequestSetUserOptedIn => ({
    type: 'REQUEST_SET_USER_OPTED_IN',
    optIn,
});

export const createReceivedUserOptedInAction = (hasOptedIn: boolean): ReceivedUserOptedIn => ({
    type: 'RECEIVED_USER_OPTED_IN',
    hasOptedIn,
});

export const createReceivedUserFeatureEnabledAction = (isChallengesEnabled: boolean): ReceivedIsChallengesEnabled => ({
    type: 'RECEIVED_IS_CHALLENGES_ENABLED',
    isChallengesEnabled,
});

export const createFetchUserChallengesAction = (): RequestFetchUserChallenges => {
    return {
        type: 'REQUEST_FETCH_USER_CHALLENGES',
    };
};

export const createReceivedUserChallengesAction = (
    challenges: InflightChallenge[],
    dailyResetTime: Date,
    dailyChallengeSetId: string
): ReceivedUserChallenges => {
    return {
        type: 'RECEIVED_USER_CHALLENGES',
        payload: { challenges, dailyResetTime, dailyChallengeSetId },
    };
};

export const createRequestClaimChallengeRewardAction = (inflightChallengeId: string): RequestClaimChallengeReward => {
    return {
        type: 'REQUEST_CLAIM_CHALLENGE_REWARD',
        inflightChallengeId,
    };
};

export const createClaimChallengeRewardSuccessAction = (
    inflightChallengeId: string,
    coinType: CoinType,
    rewardAmount: number
): ClaimChallengeRewardSuccess => {
    return {
        type: 'CLAIM_CHALLENGE_REWARD_SUCCESS',
        inflightChallengeId,
        coinType,
        rewardAmount,
    };
};

export const createClaimChallengeRewardFailedAction = (inflightChallengeId: string): ClaimChallengeRewardFailed => {
    return {
        type: 'CLAIM_CHALLENGE_REWARD_FAILED',
        inflightChallengeId,
    };
};

export const createChallengeCardClickedAction = (
    inflightChallenge: InflightChallenge,
    history: History
): ChallengeCardClick => {
    return {
        type: 'CHALLENGE_CARD_CLICKED',
        inflightChallenge,
        history,
    };
};

export const createChallengeCardViewedAction = (
    inflightChallengeId: string,
    dailyChallengeSetId: string
): ChallengeCardViewed => {
    return {
        type: 'CHALLENGE_CARD_VIEWED',
        inflightChallengeId,
        dailyChallengeSetId,
    };
};

export const createNoChallengesMessageShownAction = (): NoChallengesMessageShown => {
    return {
        type: 'NO_CHALLENGES_MESSAGE_SHOWN',
    };
};

export const createAllChallengesClaimedMessageShownAction = (): AllChallengesCompletedMessageShown => {
    return {
        type: 'ALL_CHALLENGES_COMPLETE_MESSAGE_SHOWN',
    };
};

export const createDisplayOnboardingAction = (visible: boolean): ChallengeDisplayOnboarding => {
    return {
        type: 'CHALLENGE_SET_ONBOARDING',
        visible,
    };
};

export const createChallengeStarClickedAction = (starAttributes: ChallengeStarAttributes): ChallengeStarClicked => {
    return {
        type: 'CHALLENGE_STAR_CLICKED',
        starAttributes,
    };
};

export const createShowDailyChallengesCTA = (dailyChallengeSetId: string): ShowDailyChallengesCTA => {
    return {
        type: 'SHOW_DAILY_CHALLENGES_CTA',
        dailyChallengeSetId,
    };
};
