export const TEMPORARILY_UNAVAILABLE = 'TEMPORARILY_UNAVAILABLE';

type TemporarilyUnavailableModalPros = {
    redirectToLobby: boolean;
    shouldCloseOnOverlayClick: boolean;
    shouldCloseOnEsc: boolean;
};

export const getTemporarilyUnavailableModalProps = ({
    redirectToLobby,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
}: TemporarilyUnavailableModalPros) => {
    return {
        type: TEMPORARILY_UNAVAILABLE,
        redirectToLobby,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const;
};
