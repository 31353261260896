import {
    SET_CURRENCY,
    SET_LAST_ACTIVITY_BY_USER,
    SET_PLAY_ID,
    SET_RETURN_TO_GAME_ID,
    SET_SERVER_OFFSET,
    SET_SHOW_ACTIVITY_REMINDER_MODAL,
    SET_TMX_SESSION_ID,
    SET_USER_MESSAGES,
    SetCurrencyAction,
    SetLastActivityByUserAction,
    SetPlayIdAction,
    SetReturnToGameId,
    SetServerOffsetAction,
    SetShowActivityReminderModal,
    SetTMXSessionIDAction,
    SetUserMessages,
    SWITCH_CURRENCY,
    SwitchCurrencyAction,
    UserInitialisationComplete,
    USER_INITIALISATION_COMPLETE,
    SET_IS_USER_REVERIFY_BLOCKED,
    SetIsUserReverifyBlocked,
    PreloadSegmentedStoreAction,
} from './types';
import { CurrencyType, UserMessageDetails } from '@chu/http/dist';

export const userInitialisationComplete = (): UserInitialisationComplete => ({
    type: USER_INITIALISATION_COMPLETE,
});

export const setTMXSessionID = (tmxSessionId: string): SetTMXSessionIDAction => ({
    type: SET_TMX_SESSION_ID,
    tmxSessionId,
});

export const setCurrency = (currency: CurrencyType): SetCurrencyAction => ({
    type: SET_CURRENCY,
    currency,
});

export const setPlayId = (playId: string): SetPlayIdAction => ({
    type: SET_PLAY_ID,
    playId,
});

export const switchCurrency = (): SwitchCurrencyAction => ({
    type: SWITCH_CURRENCY,
});

export const setServerOffset = (serverOffset: number): SetServerOffsetAction => ({
    type: SET_SERVER_OFFSET,
    serverOffset,
});

export const setLastActivityByUser = (timeEpochMs: number): SetLastActivityByUserAction => ({
    type: SET_LAST_ACTIVITY_BY_USER,
    timeEpochMs,
});

export const setUserMessages = (userMessages: UserMessageDetails[]): SetUserMessages => ({
    type: SET_USER_MESSAGES,
    payload: userMessages,
});

export const setReturnToGameId = (gameId: string | undefined): SetReturnToGameId => ({
    type: SET_RETURN_TO_GAME_ID,
    gameId,
});

export const setShowActivityReminderModal = (showActivityReminderModal: boolean): SetShowActivityReminderModal => ({
    type: SET_SHOW_ACTIVITY_REMINDER_MODAL,
    showActivityReminderModal,
});

export const setIsUserReverifyBlocked = (isUserReverifyBlocked: boolean): SetIsUserReverifyBlocked => ({
    type: SET_IS_USER_REVERIFY_BLOCKED,
    isUserReverifyBlocked,
});

export const preloadSegmentedStore = (): PreloadSegmentedStoreAction => ({
    type: 'PRELOAD_SEGMENTED_STORE',
});
