import { createSaga } from '../create';
import { PURCHASED } from '../user/types';
import { getStoreReceiptModalProps } from 'components/domain/store/modals/store-receipt-modal/prop-types';
import { queueModalFront } from 'store/modal/actions';
import { AppState } from '../app-state';
import { specialOffersHandler } from './handlers/specialOffersHandler';
import { freeGCHandler } from './handlers/freeGCHandler';
import { educateNewUserHandler } from './handlers/educateNewUserHandler';
import { getOffersHandler } from './handlers/getOffersHandler';
import { AppAction } from '../app-action';
import { previewStoreHandler } from './handlers/previewStoreHandler';
import { amoeCreateSessionHandler } from './handlers/amoeCreateSessionHandler';
import { setSegmentedStoreHandler } from './handlers/setSegmentedStoreHandler';

const { saga, handle } = createSaga<AppState, AppAction>();

export { saga as storeSaga };

handle(PURCHASED, async (action, dispatch) => {
    // The Id displayed in the modal is channelIdentification. Not transactionId
    dispatch(queueModalFront(getStoreReceiptModalProps(action.channelIdentification)));
});

handle('EDUCATE_NEW_USER', educateNewUserHandler);
handle('GET_OFFERS', getOffersHandler);
handle('SPECIAL_OFFERS', specialOffersHandler);
handle('FREE_GC', freeGCHandler);
handle('USER_INITIALISATION_COMPLETE', previewStoreHandler);
handle('AMOE_CREATE_SESSION', amoeCreateSessionHandler);
handle('PRELOAD_SEGMENTED_STORE', setSegmentedStoreHandler);
