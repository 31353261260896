import { createSaga } from 'store/create';
import {
    createCheckUserOptedInAction,
    createClaimChallengeRewardFailedAction,
    createClaimChallengeRewardSuccessAction,
    createDisplayOnboardingAction,
    createFetchUserChallengesAction,
    createReceivedUserChallengesAction,
    createReceivedUserFeatureEnabledAction,
    createReceivedUserOptedInAction,
    createShowDailyChallengesCTA,
} from './actions';
import axios from 'axios';
import { InflightChallenge, ChallengeResponse, CoinType } from './types';
import {
    UpdateGoldBalanceAction,
    UpdateSweepsBalanceAction,
    UPDATE_GOLD_BALANCE,
    UPDATE_SWEEPS_BALANCE,
} from 'store/user/types';
import { logger } from 'common/services/log/logger';
import {
    getChallengeClaimSeenKey,
    getChallengesOnboardingShownKey,
    getChallengesPopoutShownKey,
    getLocalStorageValue,
    initialiseLocalStorageValue,
    setLocalStorage,
} from 'helpers/localstorage';
import { toggleChallengesPopout } from 'store/ui/actions';
import { AppState } from '../app-state';
import { AppAction } from '../app-action';
import { getGenericModalProps } from 'components/elements/modal/generic/generic-modal-prop-creators';
import { hideModal, queueModal } from 'store/modal/actions';
import { trackModalShown } from 'common/services/analytics/tracked-actions';
import { getCoinTypeCode } from 'helpers/currency';
import { switchCurrency } from 'store/system/actions';
import { shouldPresentDailyChallengeSet } from './handlers';
import { ClientConfig } from 'config/client-config';

const CHALLENGE_API_RETRY_INTERVAL = 2000;

const getLatestDailyChallengeSetIdViewedKey = (userId: number): string => `latestDailyChallengeSetIdViewed-${userId}`;

const getDailyChallengesSetIdsCTAPresentedKey = (userId: number): string =>
    `dailyChallengeSetIdsCTAPresented-${userId}`;

const getChallengesApiUrl = (state: AppState) => {
    return ClientConfig.CHALLENGE_URL;
};

const getChallenge = (inflightChallengeId: string, challenges: InflightChallenge[]): InflightChallenge => {
    return challenges.filter((challenge) => challenge.inflightChallengeId === inflightChallengeId)[0];
};

const assertNeverCoinType = (x: never): never => {
    throw new Error(`Unexpected coinType: ${x}`);
};

const createUpdateBalanceAction = (
    coinType: CoinType,
    rewardAmount: number,
    sweepsBalance: number,
    goldBalance: number
): UpdateSweepsBalanceAction | UpdateGoldBalanceAction => {
    switch (coinType) {
        case 'GC':
            return {
                type: UPDATE_GOLD_BALANCE,
                balance: goldBalance + rewardAmount,
            };

        case 'SC':
            return {
                type: UPDATE_SWEEPS_BALANCE,
                balance: sweepsBalance + rewardAmount,
            };

        default:
            return assertNeverCoinType(coinType);
    }
};

const checkUserHasUnseenChallenges = (challenges: InflightChallenge[], userId: number) => {
    const completedChallenges = challenges.filter(({ status }) => status === 'GOAL_REACHED');

    return completedChallenges.some(
        ({ inflightChallengeId }) => !getLocalStorageValue(getChallengeClaimSeenKey(userId, inflightChallengeId))
    );
};

const { saga, handle } = createSaga<AppState, AppAction>();

export { saga as challengeSaga };

handle('USER_INITIALISATION_COMPLETE', async (_, dispatch, state) => {
    try {
        const { data } = await axios.get<{ isChallengesEnabled: boolean }>(
            `${getChallengesApiUrl(state)}/api/user/is-challenges-enabled`,
            { withCredentials: true }
        );
        dispatch(createReceivedUserFeatureEnabledAction(data.isChallengesEnabled));
    } catch (error: any) {
        logger.error(`Challenges opt in failed to be retrieved, Reason: ${error.message}`);
    }
});

handle('RECEIVED_IS_CHALLENGES_ENABLED', ({ isChallengesEnabled }, dispatch) => {
    if (isChallengesEnabled) {
        dispatch(createCheckUserOptedInAction());
    }
});

handle('RECEIVED_USER_OPTED_IN', ({ hasOptedIn }, dispatch, state) => {
    const openChallengesForFirstTimeUser = () => {
        const { userId } = state.user;
        const localStorageKey = getChallengesPopoutShownKey(userId);
        const seenChallenges = getLocalStorageValue(localStorageKey);
        if (!seenChallenges) {
            initialiseLocalStorageValue(localStorageKey, true);
            dispatch(toggleChallengesPopout());
        }
    };

    openChallengesForFirstTimeUser();

    if (hasOptedIn) {
        dispatch(createFetchUserChallengesAction());
    }
});

handle('TOGGLE_CHALLENGES_POPOUT', async (_, dispatch, { ui, user }) => {
    const isOpen = ui.activePopout === 'CHALLENGES';
    if (isOpen) {
        const { userId } = user;
        const localStorageKey = getChallengesOnboardingShownKey(userId);
        const hasSeenUserOnBoarding = getLocalStorageValue(localStorageKey);

        if (!hasSeenUserOnBoarding) {
            dispatch(createDisplayOnboardingAction(true));
        }
    }
});

handle('REQUEST_SET_USER_OPTED_IN', async (action, dispatch, state) => {
    try {
        await axios.post(
            `${getChallengesApiUrl(state)}/api/user/opt-in`,
            { optIn: action.optIn },
            { withCredentials: true }
        );
        dispatch(createReceivedUserOptedInAction(action.optIn));
    } catch (error: any) {
        logger.error(`Challenges OPT-IN failed to be set, Reason: ${error.message}`);
    }
});

handle('REQUEST_USER_OPTED_IN', async (_, dispatch, state) => {
    try {
        const { data } = await axios.get<{ hasOptedIn: boolean }>(`${getChallengesApiUrl(state)}/api/user/opt-in`, {
            withCredentials: true,
        });
        dispatch(createReceivedUserOptedInAction(data.hasOptedIn));
    } catch (error: any) {
        logger.error(`Challenges opt in failed to be retrieved, Reason: ${error.message}`);
    }
});

handle('REQUEST_FETCH_USER_CHALLENGES', async (_, dispatch, state) => {
    try {
        // split out into another function that validate Schema!
        const { data } = await axios.get<ChallengeResponse>(`${getChallengesApiUrl(state)}/api/user/challenges`, {
            withCredentials: true,
        });

        const dailyChallengeSetId = `${data.challenges[0].challengeSetId}-${data.dailyResetTime}-${state.user.userId}`;

        dispatch(
            createReceivedUserChallengesAction(data.challenges, new Date(data.dailyResetTime), dailyChallengeSetId)
        );
        setTimeout(() => dispatch({ type: 'REQUEST_FETCH_USER_CHALLENGES' }), CHALLENGE_API_RETRY_INTERVAL);
    } catch (error: any) {
        // The vgwSessionId http cookie that contains auth information, browsers will not allow access to this http cookie.
        // We instead confirm a session timeout by using the 401 (Unauthorised Status) status code returned from the API
        // If its a 401 we show previous state information inside the challenges popout and do not dispatch an error.
        if (error.response !== undefined && error.response.status === 401) {
            logger.error('Challenges returned with a 401, will stop making calls to the challenges api');
            return;
        }
        logger.error(`Challenges failed to be retrieved,  Reason: ${error.message}`);
        dispatch({ type: 'RECEIVED_ERROR_FETCHING_CHALLENGES' });
        setTimeout(() => dispatch({ type: 'REQUEST_FETCH_USER_CHALLENGES' }), CHALLENGE_API_RETRY_INTERVAL);
    }
});

handle('RECEIVED_USER_CHALLENGES', async ({ payload }, dispatch, { user, challenges }) => {
    const hasUnseenClaims = checkUserHasUnseenChallenges(payload.challenges, user.userId);
    dispatch({ type: 'SET_USER_HAS_UNSEEN_CLAIMS', hasUnseenClaims });

    if (!payload.challenges.length) {
        return;
    }

    const { dailyChallengeSetId } = payload;

    // Using window.localStorage as our own local storage getter does not support strings
    const latestDailyChallengeSetIdViewed =
        window.localStorage.getItem(getLatestDailyChallengeSetIdViewedKey(user.userId)) || undefined;

    if (
        !shouldPresentDailyChallengeSet({
            incomingDailyChallengeSet: dailyChallengeSetId,
            latestDailyChallengeSetIdViewed,
        })
    ) {
        return;
    }

    const dailyChallengeSetIdCTAsPresented: string[] =
        getLocalStorageValue(getDailyChallengesSetIdsCTAPresentedKey(user.userId)) || [];

    if (!dailyChallengeSetIdCTAsPresented.includes(dailyChallengeSetId)) {
        // First time a user is being presented this daily challenge set
        dailyChallengeSetIdCTAsPresented.push(dailyChallengeSetId);
        setLocalStorage(
            getDailyChallengesSetIdsCTAPresentedKey(user.userId),
            JSON.stringify(dailyChallengeSetIdCTAsPresented)
        );
        dispatch(createShowDailyChallengesCTA(dailyChallengeSetId));
    } else if (!challenges.showDailyChallengesCTA) {
        // This flow can happen when a user reloads the page
        dispatch(createShowDailyChallengesCTA(dailyChallengeSetId));
    }
});

handle('CHALLENGE_CLAIM_SHOWN', ({ inflightChallengeId }, dispatch, { user, challenges }) => {
    setLocalStorage(getChallengeClaimSeenKey(user.userId, inflightChallengeId), 'true');
    const hasUnseenClaims = checkUserHasUnseenChallenges(challenges.challengeList.challenges, user.userId);
    dispatch({ type: 'SET_USER_HAS_UNSEEN_CLAIMS', hasUnseenClaims });
});

handle('REQUEST_CLAIM_CHALLENGE_REWARD', async (action, dispatch, state) => {
    const { inflightChallengeId } = action;

    try {
        const { status } = await axios.post(
            `${getChallengesApiUrl(state)}/api/user/claim`,
            { inflightChallengeId },
            { withCredentials: true }
        );

        if (status === 200) {
            const { coinType, rewardAmount } = getChallenge(
                inflightChallengeId,
                state.challenges.challengeList.challenges
            );

            dispatch(createClaimChallengeRewardSuccessAction(inflightChallengeId, coinType, rewardAmount));

            const updateBalanceAction = createUpdateBalanceAction(
                coinType,
                rewardAmount,
                state.user.sweepsBalance,
                state.user.goldBalance
            );

            dispatch(updateBalanceAction);
        } else {
            dispatch(createClaimChallengeRewardFailedAction(inflightChallengeId));
        }
    } catch {
        dispatch(createClaimChallengeRewardFailedAction(inflightChallengeId));
    }
});

handle('CHALLENGE_CARD_VIEWED', async (action, _, { user }) => {
    const { dailyChallengeSetId } = action;
    const latestDailyChallengeSetIdViewed = window.localStorage.getItem(
        getLatestDailyChallengeSetIdViewedKey(user.userId)
    );

    if (latestDailyChallengeSetIdViewed === dailyChallengeSetId) {
        return;
    }

    setLocalStorage(getLatestDailyChallengeSetIdViewedKey(user.userId), dailyChallengeSetId);
});

handle('CHALLENGE_CARD_CLICKED', ({ inflightChallenge, history }, dispatch, { system }) => {
    const { gameName, coinType, inflightChallengeId } = inflightChallenge;

    const TIME_DELAY_BEFORE_REDIRECTING_TO_GAME_MS = 2_000;

    const challengePlayModeIsSameAsSelected = getCoinTypeCode(system.currency) === coinType;

    if (system.promotionalPlay === 'DISABLED' || challengePlayModeIsSameAsSelected) {
        history.push(`/games/${gameName}`);
        dispatch(toggleChallengesPopout());
        return;
    }

    if (!challengePlayModeIsSameAsSelected) {
        dispatch(
            queueModal(
                getGenericModalProps({
                    heading: '',
                    body: `Switching to ${coinType === 'GC' ? 'Gold' : 'Sweeps'} Coins`,
                    id: 'SHOW_MESSAGE',
                    xButton: false,
                })
            )
        );

        trackModalShown('SWITCHING_MODE', { inflightChallengeId });

        dispatch(switchCurrency());

        dispatch(toggleChallengesPopout());

        setTimeout(() => {
            dispatch(hideModal('GENERIC_MODAL'));
            history.push(`/games/${gameName}`);
        }, TIME_DELAY_BEFORE_REDIRECTING_TO_GAME_MS);
        return;
    }
});
