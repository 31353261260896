export const getLocalStorageValue = (key: string) => {
    const value = window.localStorage.getItem(key);
    if (!value) {
        return;
    }
    try {
        return JSON.parse(value);
    } catch (error: any) {
        console.log(error);
        return;
    }
};

export const incrementLocalStorageSessionTotal = (key: string, incrementAmount: number) => {
    const value = window.localStorage.getItem(key);
    const amount = !value ? 0 : JSON.parse(value);
    window.localStorage.setItem(key, JSON.stringify(amount + incrementAmount));
};

export const resetLocalStorageToZero = (key: string) => {
    window.localStorage.setItem(key, JSON.stringify(0));
};

export const initialiseLocalStorageValue = (key: string, initialValue: any) => {
    const value = getLocalStorageValue(key);
    if (!value) {
        window.localStorage.setItem(key, initialValue);
    }
};

export const setLocalStorage = (key: string, initialValue: string) => {
    window.localStorage.setItem(key, initialValue);
};

// The keys below are postfixed with the userId so that multiple users can use the same browser
export const getTermsAcceptedKey = (userId: number) => `purchaseSweepsCoinTermsChecked${userId}`;

export const getDontShowAgainKey = (userId: number) => `purchaseDontShowAgainChecked${userId}`;

export const getDismissOfferModalKey = (userId: number, offerId: string) => `offerDismissed${userId}${offerId}`;

export const getChallengeClaimSeenKey = (userId: number, inflightChallengeId: string) =>
    `challengeClaimSeen${userId}${inflightChallengeId}`;

export const getEducationModalShownKey = (userId: number) => `educationModalShown${userId}`;

export const getCustomTermsAcceptedKey = (userId: number) => `purchaseCustomTermsAccepted${userId}`;

export const getPromotionalPlayModalShownKey = (userId: number) => `promotionalPlayRulesModalShown${userId}`;

export const getChallengesPopoutShownKey = (userId: number) => `challengesPopoutShown${userId}`;

export const getQuestsPopoutShownKey = (userId: number) => `questsPopoutShown${userId}`;

export const getChallengesOnboardingShownKey = (userId: number) => `challengesOnboardingShown${userId}`;

export const getRecommendedGamesLastRefreshedAtKey = (userId: number) => `recommendedGamesLastRefreshedAt${userId}`;

export const getRecommendedGamesCacheKey = (userId: number) => `recommendedGamesCache${userId}`;
