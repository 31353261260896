import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { ThemeButton } from '../../../elements/button/theme-buttons';
import { ReactComponent as Unsuccessful } from './exclamation-icon.svg';
import { GenericModalLayout } from './generic-modal';

import './unsucessful-verification-modal.scss';

export const UnsuccessfulVerificationModal = () => {
    const dispatch = useDispatch();

    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Unsuccessful />
                </div>
            }
        >
            <div className='unsucessful-verification-modal__container'>
                <h2>We were unable to verify you</h2>
                <p>
                    Thank you for providing your details. Unfortunately, we have not been able to verify your account.
                    If you need assistance please contact{' '}
                    <a
                        className='customer-service-link'
                        href='https://chumbacasino.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001729833'
                        target='_blank'
                    >
                        customer service
                    </a>
                    .
                </p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='dialog-UNSUCCESSFUL_VERIFICATION__try-again-btn'
                        className='primary-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        to='/account-verification'
                        onClick={() => dispatch(hideModal(UNSUCCESSFUL_VERIFICATION_MODAL))}
                    >
                        TRY AGAIN
                    </ThemeButton>
                    <ThemeButton
                        id='dialog-UNSUCCESSFUL_VERIFICATION__not-now-btn'
                        theme='red-dark'
                        actionType='secondary'
                        size='md'
                        onClick={() => dispatch(hideModal(UNSUCCESSFUL_VERIFICATION_MODAL))}
                    >
                        NOT NOW
                    </ThemeButton>
                </div>
            </div>
        </GenericModalLayout>
    );
};

export const getUnsuccessfulVerificationModalProps = () => ({ type: UNSUCCESSFUL_VERIFICATION_MODAL } as const);

export const UNSUCCESSFUL_VERIFICATION_MODAL = 'UNSUCCESSFUL_VERIFICATION_MODAL';
