import { LobbyConstants } from '../../../chumba/lobby-constants';

const readConsentCookie = (): string | undefined => {
    const cookie = document.cookie
        .split(`; `)
        .find((str) => str.startsWith(`${LobbyConstants.deviceConsentPerformanceCookie}=`));
    return cookie ? cookie.split(`=`)[1] : undefined;
};

export const trackingBlocked = readConsentCookie() === 'block';
