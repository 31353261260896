import { Action } from 'redux';
import { Location } from 'history';

export const TRIGGER_RESIZE_EVENT = 'TRIGGER_RESIZE_EVENT';
const FORCE_RETURN_TO_LOBBY = 'FORCE_RETURN_TO_LOBBY';
export const SHOW_TOPHUD = 'SHOW_TOPHUD';
export const HIDE_TOPHUD = 'HIDE_TOPHUD';
export const SET_DEVICE_ORIENTATION = 'SET_DEVICE_ORIENTATION';
export const TOGGLE_SFX = 'TOGGLE_SFX';
export const SET_SFX = 'SET_SFX';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_DESTINATION_ROUTE = 'SET_DESTINATION_ROUTE';
export const PAGE_LOAD = 'PAGE_LOAD';
export const TOGGLE_NOTIFICATION_POPOUT = 'TOGGLE_NOTIFICATION_POPOUT';
export const TOGGLE_CHALLENGES_POPOUT = 'TOGGLE_CHALLENGES_POPOUT';
export const SET_BINGO_LOBBY_OPENED = 'SET_BINGO_LOBBY_OPENED';

export type DestinationRoute = Location | null;

export const UiActionTypeList = {
    TOGGLE_NOTIFICATION_POPOUT,
    TOGGLE_CHALLENGES_POPOUT,
    TRIGGER_RESIZE_EVENT,
    FORCE_RETURN_TO_LOBBY,
    SHOW_TOPHUD,
    HIDE_TOPHUD,
    SET_DEVICE_ORIENTATION,
    TOGGLE_SFX,
    SET_SFX,
    TOGGLE_SIDEBAR,
    SET_DESTINATION_ROUTE,
    SET_BINGO_LOBBY_OPENED,
};

export const PORTRAIT = 'PORTRAIT';
export const LANDSCAPE = 'LANDSCAPE';
export type DeviceOrientation = typeof PORTRAIT | typeof LANDSCAPE;

export interface UiState {
    activePopout: 'CHALLENGES' | 'NOTIFICATIONS' | 'NONE';
    showTopHud: boolean;
    orientation: DeviceOrientation;
    sfxEnabled: boolean;
    sidebarOpen: boolean;
    destinationRoute: DestinationRoute;
    bingoLobbyOpened: boolean;
}

export interface ShowTopHudAction extends Action {
    type: typeof SHOW_TOPHUD;
}

export interface ToggleNotificationsPopout extends Action {
    type: typeof TOGGLE_NOTIFICATION_POPOUT;
}

export interface ToggleChallengesPopout extends Action {
    type: typeof TOGGLE_CHALLENGES_POPOUT;
}

export interface HideTopHudAction extends Action {
    type: typeof HIDE_TOPHUD;
}

export interface TriggerResizeEventAction extends Action {
    type: typeof TRIGGER_RESIZE_EVENT;
}

export interface SetDeviceOrientationAction extends Action {
    type: typeof SET_DEVICE_ORIENTATION;
    orientation: DeviceOrientation;
}
export interface ToggleSfxAction extends Action {
    type: typeof TOGGLE_SFX;
}

interface SetSfxAction extends Action {
    type: typeof SET_SFX;
    sfxEnabled: boolean;
}

export interface ToggleSidebarAction extends Action {
    type: typeof TOGGLE_SIDEBAR;
}

export interface SetDestinationRouteAction extends Action {
    type: typeof SET_DESTINATION_ROUTE;
    destinationRoute: DestinationRoute;
}

export interface PageLoadAction extends Action {
    type: typeof PAGE_LOAD;
    path: string;
}

export interface SetBingoLobbyAction extends Action {
    type: typeof SET_BINGO_LOBBY_OPENED;
    bingoLobbyOpened: boolean;
}

export type UiAction =
    | ToggleNotificationsPopout
    | ToggleChallengesPopout
    | TriggerResizeEventAction
    | ToggleSfxAction
    | SetSfxAction
    | ShowTopHudAction
    | HideTopHudAction
    | SetDeviceOrientationAction
    | SetDestinationRouteAction
    | ToggleSidebarAction
    | PageLoadAction
    | SetBingoLobbyAction;
