import React from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';
import { LobbyConstants } from 'chumba/lobby-constants';

export const MarketExitInformModalActions: React.FC<CallToActionProps> = ({
    handleOKBtn,
    modalName,
}: CallToActionProps) => (
    <>
        <div className={`${modalName}__button-group`}>
            <ThemeButton
                id={`${modalName}__ok-btn`}
                data-testid={`${modalName}__ok-btn`}
                className={`${modalName}__ok-btn`}
                actionType='primary'
                size='md'
                theme='red-dark'
                onClick={handleOKBtn}
            >
                OK
            </ThemeButton>
        </div>
        <a
            id={`${modalName}__find-out-more-link`}
            data-testid={`${modalName}__find-out-more-link`}
            className={`${modalName}__find-out-more-link`}
            target='_blank'
            href={LobbyConstants.canIPlayFromAnywhereUrl}
        >
            Find out more
        </a>
    </>
);

interface CallToActionProps {
    handleOKBtn: () => void;
    modalName: string;
}
