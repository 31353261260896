//copied from chu-client/packages/casino/src/chumba/helpers/device.ts
let ua: string | undefined = undefined;

const getAgent = () => {
    if (!ua) {
        ua = navigator.userAgent;
    }
    return ua.toLowerCase();
};

const isAndroid = () => /android/i.test(getAgent()) || isAndroidTablet();
export const isiPad = () =>
    /ipad/i.test(getAgent()) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
export const isIOS = () => /iphone|ipad|ipod/i.test(getAgent()) || isiPad();
const isChrome = () => /chrome/i.test(getAgent()) || /crios/i.test(getAgent());
export const isFirefox = () => /firefox/i.test(getAgent()) || /fxios/i.test(getAgent());
export const isSafari = () => /safari/i.test(getAgent()) && !isChrome() && !isFirefox();
export const isMobile = () => isIOS() || isAndroid();
export const isDesktop = () => !isMobile() && !isiPad();

const isKindleFireTablet = () => {
    return (
        /Silk/i.test(getAgent()) &&
        /KFTBW|KFMEWI|KFFOWI|KFSAW|KFASWI|KFARWI|KFAPW|KFTHW|KFSOWI|KFJW|KFTT|KFOT/i.test(getAgent())
    );
};
const isAndroidTablet = () => {
    const ua = getAgent();
    const isTabletInUA = /tablet/i.test(ua);
    const mobileInUA = /mobile/i.test(ua);
    const isAndroid = /android/i.test(ua);

    if (isKindleFireTablet()) {
        return true;
    }
    return (isTabletInUA || !mobileInUA) && isAndroid;
};
