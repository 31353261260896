import React from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';

export const MarketExitPendingModalActions: React.FC<CallToActionProps> = ({
    handleLogOutBtn,
    modalName,
}: CallToActionProps) => (
    <div className={`${modalName}__button-group`}>
        <ThemeButton
            id={`${modalName}__log-out-btn`}
            data-testid={`${modalName}__log-out-btn`}
            className={`${modalName}__log-out-btn`}
            actionType='primary'
            size='md'
            theme='red-dark'
            onClick={handleLogOutBtn}
        >
            LOG OUT
        </ThemeButton>
    </div>
);

interface CallToActionProps {
    handleLogOutBtn: () => void;
    modalName: string;
}
