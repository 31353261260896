export type CookieOverride = 'SET_ENABLE_OVERRIDE' | 'SET_DISABLE_OVERRIDE' | 'OVERRIDE_NOT_SET';

/**
 * Set the cookie override by running `document.cookie = "vgw.chumba.overrides.<COOKIE_NAME>=<VALUE>";` in the console.
 */
export const cookieOverride =
    (name: string, group: string): (() => CookieOverride) =>
    () => {
        const cookieEntry = document.cookie
            .split('; ')
            .find((entry) => entry.startsWith(`vgw.chumba.overrides.${name}=`));

        if (!cookieEntry) return 'OVERRIDE_NOT_SET';

        if (cookieEntry.split('=')[1] === group) {
            return 'SET_ENABLE_OVERRIDE';
        } else {
            return 'SET_DISABLE_OVERRIDE';
        }
    };
