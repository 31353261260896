import { Currency } from '@chu/http';
import axios, { AxiosResponse } from 'axios';
import { logger } from 'common/services/log/logger';
import { OfferErrorType } from 'components/domain/packages/modals/prop-types';
import { getOfferErrorModalProps } from 'components/domain/packages/modals/props-creator';
import { OfferPackage } from 'components/domain/packages/types';
import { getQueryParameter } from 'helpers/url';
import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { AppState } from 'store/app-state';
import { queueModal } from 'store/modal/actions';
import { createReceiveOfferPackages, GetOffersAction } from '../actions';
import { Package } from '../types';
import { ClientConfig } from 'config/client-config';

type GetOffersRequest = {
    offerId?: string;
};

type GetOffersResponse = {
    success: boolean;
    offers: OfferPackage[];
    offerMessage?: OfferErrorType;
};

const getOffers = async (offerId?: string): Promise<{ packages: Package[]; offerMessage?: OfferErrorType }> => {
    const request = offerId ? { offerId } : {};
    const response = await axios.post<GetOffersResponse, AxiosResponse<GetOffersResponse, GetOffersRequest>>(
        `${ClientConfig.OFFERS_URL}/claim-api/cashier`,
        request,
        {
            withCredentials: true,
        }
    );

    const { offers, offerMessage } = response.data;
    return { packages: offers.map(offerToPackage), offerMessage };
};

function offerToPackage(offer: OfferPackage): Package {
    const gold = offer.items.find(({ currency }) => currency.id === Currency.GOLD);
    const goldAmount = gold ? Number(gold.amount) : 0;

    const sweeps = offer.items.find(({ currency }) => currency.id === Currency.SWEEPS);
    const sweepsAmount = sweeps ? Number(sweeps.amount) : 0;
    return {
        cashierId: offer.packageId,
        offerId: offer.packageId,
        description: offer.description,
        goldAmount,
        originalPrice: offer.totalValue,
        packageName: offer.packageName,
        packageType: 'offer',
        packageUuid: offer.packageUuid,
        price: offer.price,
        sweepsAmount,
        validUntil: offer.validUntil,
    };
}

const shouldHandleAction = (appState: AppState): boolean => {
    const { isUserInitialisationComplete, promotionalPlay } = appState.system;
    return isUserInitialisationComplete && promotionalPlay === 'ENABLED';
};

const handleAction = async (dispatch: Dispatch<AppAction>, appState: AppState): Promise<void> => {
    const offerId = getQueryParameter('offer') ?? undefined;
    const { packages, offerMessage } = await getOffers(offerId);

    if (offerMessage) dispatch(queueModal(getOfferErrorModalProps({ errorType: offerMessage })));

    dispatch(createReceiveOfferPackages(packages));
};

export const getOffersHandler = async (action: GetOffersAction, dispatch: Dispatch<AppAction>, appState: AppState) => {
    try {
        if (shouldHandleAction(appState)) {
            await handleAction(dispatch, appState);
        }
    } catch (error) {
        logger.error(error);
    } finally {
        if (action.next) dispatch(action.next);
    }
};
