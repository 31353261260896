import * as braze from '@braze/web-sdk';
import { BlockedGameType } from '@chu/http';

export const openBrazeSession = (
    userId: number,
    sessionToken: string | undefined,
    blockedGameIds: string[],
    blockedGameTypes: BlockedGameType[]
) => {
    braze.subscribeToInAppMessage((inAppMessage) => {
        if (inAppMessage.isControl) {
            return braze.showInAppMessage(inAppMessage);
        }

        if (
            !blockedGameIds.includes(inAppMessage.extras?.game_id) &&
            !blockedGameTypes.includes(inAppMessage.extras?.game_type as BlockedGameType)
        ) {
            braze.showInAppMessage(inAppMessage);
        }
    });
    braze.changeUser(userId.toString(), sessionToken);
    braze.openSession();
};
