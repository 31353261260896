import { RecommendedGamesRefreshHelper } from 'helpers/recommended-games-refresh-helper';
import { RecommendedGamesService } from './recommended-games-interface';
import { DEFAULT_GAMES_LIST } from 'components/domain/lobby/recommended-games';
import axios from 'axios';
import { logger } from '../log/logger';
import { ClientConfig } from '../../../config/client-config';

export const recommendedGamesServiceImplementation = (): RecommendedGamesService => {
    return {
        getRecommendedGames: async (userId: number): Promise<string[]> => {
            const refreshHelper = new RecommendedGamesRefreshHelper(userId);

            if (!refreshHelper.shouldRefreshRecommendedGames()) {
                return refreshHelper.getRecommendedGamesCache();
            }

            const res = await axios.get(ClientConfig.REQUEST_PATH_HOST_SEGMENT + '/api/recommendations', {
                withCredentials: true,
            });

            if (res.status === 200) {
                let gameIds: string[] = res.data.gameIds;
                refreshHelper.recommendedGamesRefreshed(Date.now(), gameIds);
                return gameIds;
            } else {
                logger.error('Error retrieving game recommendations from server.');
                return DEFAULT_GAMES_LIST;
            }
        },
    };
};
