import {
    HIDE_TOPHUD,
    LANDSCAPE,
    SET_BINGO_LOBBY_OPENED,
    SET_DESTINATION_ROUTE,
    SET_DEVICE_ORIENTATION,
    SET_SFX,
    SHOW_TOPHUD,
    TOGGLE_CHALLENGES_POPOUT,
    TOGGLE_NOTIFICATION_POPOUT,
    TOGGLE_SFX,
    TOGGLE_SIDEBAR,
    UiAction,
    UiActionTypeList,
    UiState,
} from './types';
import { assertNeverAction, isActionType } from '../action-assertions';
import { LobbyReducer } from '../reducer';

export const initialUiState: UiState = {
    activePopout: 'NONE',
    showTopHud: true,
    orientation: LANDSCAPE,
    sfxEnabled: true,
    sidebarOpen: false,
    destinationRoute: null,
    bingoLobbyOpened: false,
};

export const uiReducer: LobbyReducer<UiState> = (uiState = initialUiState, action): UiState => {
    if (isActionType<UiAction>(action, UiActionTypeList)) {
        switch (action.type) {
            case TOGGLE_CHALLENGES_POPOUT: {
                const isActive = uiState.activePopout === 'CHALLENGES';
                return {
                    ...uiState,
                    activePopout: isActive ? 'NONE' : 'CHALLENGES',
                };
            }
            case TOGGLE_NOTIFICATION_POPOUT: {
                const isActive = uiState.activePopout === 'NOTIFICATIONS';
                return {
                    ...uiState,
                    activePopout: isActive ? 'NONE' : 'NOTIFICATIONS',
                };
            }
            case SHOW_TOPHUD:
                return { ...uiState, showTopHud: true };
            case HIDE_TOPHUD:
                return { ...uiState, showTopHud: false };
            case SET_DEVICE_ORIENTATION:
                return { ...uiState, orientation: action.orientation };
            case TOGGLE_SFX:
                return { ...uiState, sfxEnabled: !uiState.sfxEnabled };
            case SET_SFX:
                return { ...uiState, sfxEnabled: action.sfxEnabled };
            case TOGGLE_SIDEBAR:
                return { ...uiState, sidebarOpen: !uiState.sidebarOpen };
            case SET_DESTINATION_ROUTE:
                return {
                    ...uiState,
                    destinationRoute: action.destinationRoute,
                };
            case SET_BINGO_LOBBY_OPENED:
                return {
                    ...uiState,
                    bingoLobbyOpened: action.bingoLobbyOpened,
                };

            default:
                assertNeverAction(action as never);
        }
    }
    return uiState;
};
