import { createSaga } from 'store/create';
import store from 'store';
import { trackNotificationTray } from 'common/services/analytics/tracked-actions';
import { TOGGLE_NOTIFICATION_POPOUT, UiAction } from './types';
import { AppState } from '../app-state';

const { saga, handle } = createSaga<AppState, UiAction>();

export { saga as uiSaga };

handle(TOGGLE_NOTIFICATION_POPOUT, () => {
    const { ui, user } = store.getState();
    const { userId } = user;
    const isTrayOpen = ui.activePopout === 'NOTIFICATIONS';

    trackNotificationTray(userId, isTrayOpen);
});
