import {
    HideModalAction,
    HIDE_MODAL,
    MODAL_TYPES,
    MODAL_PROP_TYPES,
    QUEUE_MODAL,
    REPLACE_MODAL,
    QueueModalAction,
    PopModalAction,
    POP_MODAL,
    QueueModalFrontAction,
    QUEUE_MODAL_FRONT,
    ReplaceModalAction,
    DequeueModalAction,
    DEQUEUE_MODAL,
} from './types';

export const dequeueModal = (modalType: MODAL_TYPES): DequeueModalAction => {
    return {
        type: DEQUEUE_MODAL,
        modalType,
    };
};

export const queueModal = (modalProps: MODAL_PROP_TYPES): QueueModalAction => {
    return {
        type: QUEUE_MODAL,
        modalProps: { ...modalProps },
    };
};

export const popModal = (): PopModalAction => {
    return {
        type: POP_MODAL,
    };
};

export const replaceModal = (modalProps: MODAL_PROP_TYPES): ReplaceModalAction => {
    return {
        type: REPLACE_MODAL,
        modalProps: { ...modalProps },
    };
};

export const queueModalFront = (modalProps: MODAL_PROP_TYPES): QueueModalFrontAction => {
    return {
        type: QUEUE_MODAL_FRONT,
        modalProps: { ...modalProps },
    };
};

export function hideModal(modalType: MODAL_TYPES): HideModalAction {
    return {
        type: HIDE_MODAL,
        modalType,
    };
}
