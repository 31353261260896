import { Action } from 'redux';

export const UPDATE_JACKPOTS = 'UPDATE_JACKPOTS';
export const UPDATE_GRAND_JACKPOT = 'UPDATE_GRAND_JACKPOT';
export const UPDATE_MAJOR_JACKPOT = 'UPDATE_MAJOR_JACKPOT';
export const SWITCH_JACKPOT_DISPLAY = 'SWITCH_JACKPOT_DISPLAY';

export const JackpotActionTypeList = {
    UPDATE_JACKPOTS,
    UPDATE_GRAND_JACKPOT,
    UPDATE_MAJOR_JACKPOT,
    SWITCH_JACKPOT_DISPLAY,
} as const;

export type Jackpots = {
    [key: string]: JackpotPool;
};

export type JackpotPool = {
    goldCoinValue: number;
    sweepsValue: number;
    mustHitValueGold: number;
    mustHitValueSweeps: number;
};

/**
 * Type for jackpots that can be shown in the jackpot ticker UI.
 *
 * This is NOT a general type for Jackpots and should NOT be used to
 * interact with other jackpot systems.
 */
export type JackpotDisplayType = 'GRAND' | 'MAJOR';

export interface JackpotState {
    grandJackpots: Jackpots;
    majorJackpots: Jackpots;
    displayJackpot: JackpotDisplayType;
}

export interface UpdateJackpotsAction extends Action {
    type: typeof UPDATE_JACKPOTS;
    grandJackpots: Jackpots;
    majorJackpots: Jackpots;
}

export interface UpdateGrandJackpotAction extends Action {
    type: typeof UPDATE_GRAND_JACKPOT;
    name: string;
    values: { goldCoinValue: number; sweepsValue: number };
}

export interface UpdateMajorJackpotAction extends Action {
    type: typeof UPDATE_MAJOR_JACKPOT;
    name: string;
    values: { goldCoinValue: number; sweepsValue: number };
}

export interface SwitchJackpotDisplayAction extends Action {
    type: typeof SWITCH_JACKPOT_DISPLAY;
}

export type JackpotAction =
    | UpdateJackpotsAction
    | UpdateGrandJackpotAction
    | UpdateMajorJackpotAction
    | SwitchJackpotDisplayAction;
