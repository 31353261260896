import { Package } from 'store/store/types';
import SpecialDiscountImage from '../common/images/special-discount.svg';
import FreeSweepsImage from '../common/images/free-sweeps.svg';
import FreeGoldImage from '../common/images/free-gold.svg';

export const filterExpired = ({ validUntil }: Package) => {
    if (!validUntil) {
        return true;
    }

    return new Date(validUntil) > new Date();
};

const filterGoldCoinOnly = ({ sweepsAmount }: Package) => {
    return Number(sweepsAmount) === 0;
};

export const getOffersToDisplay = (offers: Package[]) => {
    return offers.filter(filterExpired);
};

export const getPackagesToDisplay = (packages: Package[], promotionalPlayDisabled: boolean): Package[] => {
    if (!promotionalPlayDisabled) {
        return packages.filter(filterExpired);
    }
    return packages.filter(filterExpired).filter(filterGoldCoinOnly);
};

type OfferDisplayAttributes = {
    bemModifier: string;
    imageSource: string;
    imageAlt: string;
};

export const getOfferDisplayAttributes = (offer: Package): OfferDisplayAttributes => {
    const isFreeToClaim = offer.price === 0;

    const { imageSource, imageAlt } = isFreeToClaim
        ? offer.sweepsAmount === 0
            ? { imageSource: FreeGoldImage, imageAlt: 'Free Gold' }
            : { imageSource: FreeSweepsImage, imageAlt: 'Free Sweeps' }
        : { imageSource: SpecialDiscountImage, imageAlt: 'Special Discount' };

    const bemModifier = isFreeToClaim ? '--claim' : '--purchase';
    return { bemModifier, imageSource, imageAlt };
};
