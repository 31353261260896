import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideModal } from 'store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { MARKET_EXIT_REJECT } from '../modal-prop-types';
import { MarketExitRejectModalActions } from './market-exit-reject-modal-actions';
import { logout } from 'helpers/logout';
import { LobbyConstants } from 'chumba/lobby-constants';
import { ReactComponent as Unsuccessful } from '../../../2k-verification/exclamation-icon.svg';

import './market-exit-reject-modal.scss';

export const MarketExitRejectModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const id: GenericModalTypes = MARKET_EXIT_REJECT;
    const modalName = 'market-exit-reject-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <Unsuccessful height='20%' width='20%' />
            <h2 className={`${modalName}__header`}>We were unable to verify you</h2>
            <p className={`${modalName}__content`}>
                Thank you for providing your details. Unfortunately, we have not been able to verify your account. If
                you need assistance please contact{' '}
                <a
                    data-testid={`${modalName}__contact-link`}
                    className={`${modalName}__contact-link`}
                    href={LobbyConstants.contactSupportUrl}
                    target='_blank'
                >
                    customer service
                </a>
                .
            </p>
            <MarketExitRejectModalActions
                handleClickTryAgainBtn={handleClickTryAgainBtn}
                handleLogOut={logout}
                modalName={modalName}
            />
        </GenericModalLayout>
    );

    function handleClickTryAgainBtn() {
        dispatch(hideModal(MARKET_EXIT_REJECT));
        history.push('/account-verification');
    }
};

export const getMarketExitRejectModalModalProps = (shouldCloseOnOverlayClick = false, shouldCloseOnEsc = false) =>
    ({ type: MARKET_EXIT_REJECT, shouldCloseOnOverlayClick, shouldCloseOnEsc } as const);
