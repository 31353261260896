import { Package, Store } from './types';
import { SegmentedStore } from './new-store/types';

export type StoreAction =
    | ReceivePromotionPackages
    | SetStoreData
    | ClearStoreData
    | SetNewStoreSessionIdAction
    | FreeGCAction
    | GetOffersAction
    | SpecialOffersAction
    | EducateNewUserAction
    | AmoeCreateSessionAction
    | SetSegmentedStoreAction;

type ChainableAction = {
    next?: StoreAction;
};
type ReceivePromotionPackages = {
    type: 'PACKAGE_RECEIVE_OFFERS';
    packages: Package[];
};

type SetStoreData = {
    type: 'SET_STORE_DATA';
    cashierData: Store;
};

type ClearStoreData = {
    type: 'CLEAR_STORE_DATA';
};

export type AmoeCreateSessionAction = {
    type: 'AMOE_CREATE_SESSION';
    returnUrl: string;
};

type SetNewStoreSessionIdAction = {
    type: 'SET_NEW_STORE_SESSION_ID';
    storeSessionId: string;
};

export type SetSegmentedStoreAction = {
    type: 'SET_SEGMENTED_STORE';
    segmentedStore: SegmentedStore;
};

export type FreeGCAction = ChainableAction & {
    type: 'FREE_GC';
};

export type GetOffersAction = ChainableAction & {
    type: 'GET_OFFERS';
};

export type SpecialOffersAction = ChainableAction & {
    type: 'SPECIAL_OFFERS';
};

export type EducateNewUserAction = ChainableAction & {
    type: 'EDUCATE_NEW_USER';
};

export const createReceiveOfferPackages = (packages: Package[]): ReceivePromotionPackages => ({
    type: 'PACKAGE_RECEIVE_OFFERS',
    packages,
});

export const setStoreData = (cashierData: Store): SetStoreData => ({
    type: 'SET_STORE_DATA',
    cashierData,
});

export const clearStoreData = (): ClearStoreData => ({
    type: 'CLEAR_STORE_DATA',
});

export const setNewStoreSessionId = (storeSessionId: string): SetNewStoreSessionIdAction => ({
    type: 'SET_NEW_STORE_SESSION_ID',
    storeSessionId,
});

export const setSegmentedStore = (segmentedStore: SegmentedStore): SetSegmentedStoreAction => ({
    type: 'SET_SEGMENTED_STORE',
    segmentedStore,
});

// Chained Actions: EducateNewUser > SpecialOffers > FreeGC
export const educateNewUser = (): EducateNewUserAction => ({ type: 'EDUCATE_NEW_USER', next: getOffers() });
export const getOffers = (): GetOffersAction => ({ type: 'GET_OFFERS', next: specialOffers() });
export const specialOffers = (): SpecialOffersAction => ({ type: 'SPECIAL_OFFERS', next: freeGC() });
export const freeGC = (): FreeGCAction => ({ type: 'FREE_GC' });
