import { TwoKVerificationCompliance } from './types';

export const mapToTwoKVerificationCompliance = (
    twoKVerificationComplianceResponse: TwoKVerificationCompliance
): TwoKVerificationCompliance => {
    switch (twoKVerificationComplianceResponse.message) {
        case 'NO_ACTION_REQUIRED':
        case 'TWO_K_VERIFICATION_BLOCKED':
        case 'TWO_K_VERIFICATION_BLOCKED_PENDING_CDD':
            return { message: twoKVerificationComplianceResponse.message };
        case 'TWO_K_VERIFICATION_FLAGGED_REJECTED_CDD':
        case 'TWO_K_VERIFICATION_FLAGGED_NOT_BLOCKED':
            return {
                message: twoKVerificationComplianceResponse.message,
                dateTwoKThresholdWasFlagged: new Date(twoKVerificationComplianceResponse.dateTwoKThresholdWasFlagged),
            };
    }
};
