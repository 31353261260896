import * as helpers from './third-party-helpers';
import { isGAPProvider, isRGSGame } from './third-party-helpers';
import {
    DEV_MESSAGE,
    GAME_HAS_INIT,
    ICasinoMessage,
    IFrameMessageEvent,
    IFrameMessageHandler,
} from './third-party-types';
import { GameIframeDataType } from '../components/elements/game-iframe/game-iframe-data-type';
import { generateQueryParameters, getChumbaMonorepoServerApiUrl } from '../helpers/url';
import store from '../store';
import { Currency } from '@chu/http/dist';
import {
    checkingCanOpenCashier,
    checkingCanOpenCashout,
    checkingGameCurrency,
    checkingPlayId,
    checkingShowLobby,
    initGame,
    setLoadingState,
    unloadGame,
} from '../store/game/actions';
import { setPlayId } from '../store/system/actions';
import { History } from 'history';
import {
    getChumbaGameServerClientUrl,
    getClientUrl,
    isChumbaGameServerGame,
} from '../components/domain/iframe/game-server';
import { Game } from 'store/layout/types';
import { isMobile } from 'helpers/device';
import sassVariables from '../main.scss';
import { GAME_LOADED } from 'store/game/types';
import { AppState } from '../store/app-state';
import { ClientConfig } from 'config/client-config';

export const registerWindowListener = (iframeMesssageHandler: IFrameMessageHandler): void => {
    window.addEventListener('message', iframeMesssageHandler);
};
export const deregisterWindowListener = (iframeMessageHandler: IFrameMessageHandler): void => {
    window.removeEventListener('message', iframeMessageHandler);
};

export const loadGame = (obj: GameIframeDataType, iframe: HTMLIFrameElement): void => {
    if (iframe instanceof HTMLIFrameElement) {
        iframe.src = getIframeLoaderUrl(obj);
    }
};

export const mapParentAppStateToIframe = (state: AppState): ICasinoMessage => ({
    currency: state.system.currency,
    chumbaEnv: ClientConfig.CHUMBA_GAME_ENVIRONMENT,
    isFacebookLogin: false,
    baseUrl: ClientConfig.LEGACY_GAMES_CLIENTS_URL,
    baseApiUrl: ClientConfig.CASINO_URL,
    language: 'en',
    isMobile: false,
    isTablet: false,
    openedWindows: false,
    reconnectingViaFB: false,
    cashoutBeingOpen: false,
    buyMoreBeingOpen: false,
    sfxEnabled: state.ui.sfxEnabled,
    user: {
        userId: state.user.userId.toString(),
        language: 'en',
        balanceWasUpdatedOutsideGame: false,
        gold: {
            id: Currency.GOLD,
            balance: state.user.goldBalance,
            props: {
                prefix: '',
                sufix: '',
                showDecimals: false,
                trailingZeros: 0,
            },
        },
        sweeps: {
            id: Currency.SWEEPS,
            balance: state.user.sweepsBalance,
            sweepsToUSD: 100,
            props: {
                prefixDollar: 'SC',
                prefixCent: '¢',
                sufix: '',
                showDecimals: true,
                trailingZeros: 2,
            },
        },
        properties: state.user.properties,
    },
    ui: {
        tophudHeight: -1,
    },
});

export const sendMessageToIframe = (eventName = '', message = {}): void => {
    if (!store.getState().game.hasGameInit) {
        console.log('message sent before initial RESULTS_RECEIVED message');
        return;
    }

    console.log(`Sending message to iframe: ${eventName}`);
    postMessageToIframe(formatMessage(eventName, message));
};

const formatMessage = (
    eventName = '',
    message = {}
): {
    type: string;
} => {
    return Object.assign({}, message, { type: eventName });
};

const getThirdPartyIframe = (): HTMLIFrameElement | undefined => {
    const frame: unknown = window.document.getElementById('thirdPartyIframe');
    if (frame instanceof HTMLIFrameElement) {
        return frame;
    }
};

const getThirdPartyParent = (): HTMLDivElement | undefined => {
    const frame: unknown = window.document.getElementById('thirdPartyParent');
    if (frame instanceof HTMLDivElement) {
        return frame;
    }
};

export const postMessageToIframe = (message: any): void => {
    const frame = getThirdPartyIframe();
    if (frame) {
        if (frame.contentWindow) {
            frame.contentWindow.postMessage(message, getIframeTargetOrigin());
        }
    }
};

const getIframeTargetOriginUrl = (gameId: string, games: Game[]): string => {
    if (isChumbaGameServerGame(gameId, games)) {
        return getClientUrl(gameId);
    }

    if (isRGSGame(gameId, games)) {
        return '*';
    }

    return ClientConfig.LEGACY_GAMES_CLIENTS_URL.split('/legacy-clients')[0];
};

const getIframeTargetOrigin = (): string => {
    const {
        game: { gameId },
        layout: { games, inactiveGames },
        user: { isStaff },
    } = store.getState();

    const availableGames = isStaff ? [...games, ...inactiveGames] : games;

    return getIframeTargetOriginUrl(gameId, availableGames);
};

export const sendResizeMessage = (): void => {
    const iframe = getThirdPartyParent();
    const mobile = isMobile();
    if (iframe) {
        setTimeout(() => {
            const scaleFactor = mobile ? sassVariables.mobilegamescalefactor : 1;
            const resizeObj = {
                width: Number(window.innerWidth) * scaleFactor,
                height: Number(window.innerHeight) * scaleFactor,
                iFrameTop: 0,
                iFrameWidth: Number(iframe.clientWidth) * scaleFactor,
                iFrameHeight: Number(iframe.clientHeight) * scaleFactor,
            };
            sendMessageToIframe('RESIZE', resizeObj);
        }, 1);
    }
};

const isIFrameMessageEvent = (event: any): event is IFrameMessageEvent => {
    return event && typeof event.origin === 'string' && event.data && typeof event.data.type === 'string';
};

const isDevMessage = (event: any): event is DEV_MESSAGE => {
    const isReduxDevToolsMessage =
        event &&
        typeof event.origin === 'string' &&
        event.data &&
        (event.data.source === 'react-devtools-bridge' ||
            event.data.source === 'react-devtools-content-script' ||
            event.data.source === 'react-devtools-inject-backend' ||
            event.data.source === '@devtools-page');

    return isReduxDevToolsMessage;
};

const unBlockNavigationAndLeaveGame = (history: History): void => {
    store.dispatch(unloadGame()); //will unblock by game setting it into unloading state and un-initializing the game
    helpers.attemptLeaveGame(history);
};

export const createIFrameMessageHandler = (history: History): IFrameMessageHandler => {
    const iframeEventHandler: IFrameMessageHandler = (iFrameEvent) => {
        if (isDevMessage(iFrameEvent)) {
            // Silently discard messages
            return;
        }

        if (!isIFrameMessageEvent(iFrameEvent)) {
            console.error('unexpected iframe message type', JSON.stringify(iFrameEvent));
            return;
        }

        const {
            layout: { games, inactiveGames },
            game: { gameId, company },
        } = store.getState();

        if (!isGAPProvider(company)) {
            const cdn = ClientConfig.LEGACY_GAMES_CLIENTS_URL.split('/legacy-clients')[0].trim(); // cdn path
            const origin = iFrameEvent.origin.trim();
            // IMPORTANT: Check the origin of the data!
            // if (origin !== cdn) {  // this is what we must use on prod (when game is released)

            if (
                window.location.hostname === 'lobby.chumbacasino.com' &&
                origin !== cdn &&
                isChumbaGameServerGame(gameId, [...games, ...inactiveGames]) &&
                origin !== ClientConfig.GAME_SERVER_BASE_URL
            ) {
                // temporary fix to allow local development from a stack
                console.log(`ERROR receiving message from: origin: ${iFrameEvent.origin} expected ${cdn}`);
                return;
            }
        }

        const message = iFrameEvent.data;

        switch (message.type) {
            case 'THIRD_PARTY_MESSAGE_RECEIVED':
                break;
            case 'ACTION':
                break;
            case 'GAME_HAS_INIT':
                return gameHasInit(message, history);
            case 'CHANGE_GOLD_BALANCE':
                return helpers.changeGoldBalance(message);
            case 'CHANGE_SWEEPS_BALANCE':
                return helpers.changeSweepsBalance(message);
            case 'UPDATE_TOPHUD':
                return helpers.updateTopHud(message);
            case 'BALANCE_UPDATED_OUTSIDE':
                return helpers.balanceUpdatedOutsideGame(message);
            case 'TIME_WARNING_WINDOW':
                return helpers.timeWarningWindow(message);
            case 'ANALYTICS':
                return helpers.doAnalytics(message);
            case 'CURRENCY_BUTTON_STATE':
                return helpers.currencyButtonState(message.currencyButton);
            case 'INPUT_BY_USER':
                return helpers.inputByUser();
            case 'SHARE_ON_FACEBOOK':
                return helpers.shareOnFacebook(message);
            case 'SHOW_TOPHUD':
                return helpers.showTopHud();
            case 'HIDE_TOPHUD':
                return helpers.hideTopHud();
            case 'TRIGGER_RESIZE_EVENT':
                return helpers.triggerResize();
            case 'FORCE_IFRAME_RESIZE':
                return helpers.forceIframeResize(message);
            case 'FORCE_IFRAME_RESIZE_CLEAR':
                return helpers.clearForcedIframeSize();
            case 'FORCE_RETURN_TO_LOBBY':
                store.dispatch(unloadGame());
                history.push('/');
                break;
            case 'SWITCH_CURRENCY':
                return helpers.attemptSwitchCurrency();
            case 'SHOW_INSUFFICIENT_FUNDS':
                return helpers.showInsufficientFunds(message);
            case 'SHOW_SERVER_CANNOT_BE_REACHED':
                return helpers.showServerCannotBeReached();
            case 'SHOW_INFORMATION_WINDOW':
                return helpers.showInformationWindow(message);
            case 'HIDE_INFORMATION_WINDOW':
                return helpers.hideInformationWindow();
            case 'PUSH_TO_OPENED_WINDOWS':
                return helpers.pushToOpenedWindows(message);
            case 'REMOVE_FROM_OPENED_WINDOWS':
                return helpers.removeFromOpenedWindows(message);
            case 'CHECK_FOR_MESSAGES_DURING_INIT':
                // this appears to not be needed, its used for startup lobby flow in old client. will depend on our implementation
                return helpers.checkForMessages();
            case 'PARSE_API_RESPONSE':
                return helpers.parseApiResponse(message);
            case 'ORGANIZE_TOPHUD_FOR':
                return helpers.organizeTophudFor(message);
            case 'ORGANIZE_TOPHUD_FOR_SLOTS':
                return helpers.organizeTophudForSlots(message);
            case 'GET_RESULTS_FROM_SERVER':
                return helpers.post(message.url, message.params);
            case 'SHOW_RECENT_WINNERS':
                return helpers.showRecentWinners();
            case 'HIDE_RECENT_WINNERS':
                return helpers.hideRecentWinners();
            case 'CHANGE_TO_FULL_WINDOW_SCREEN':
                return helpers.changeToFullWindowScreen();
            case 'CHANGE_TO_GAME_WINDOW_SCREEN':
                return helpers.changeToGameWindowScreen();
            case 'GET_SIZE':
                return sendResizeMessage();
            case 'SAVE_USER_PROPERTIES':
                return helpers.saveUserProperties(message.properties);
            case 'CASINO_ARE_YOU_BUSY':
                return helpers.checkIfCasinoisBusy();
            // // These are callbacks from questions asked to the iframe
            case 'RESPONSE_CAN_CHANGE_CURRENCY':
                if (store.getState().game.isCheckingCurrency) {
                    store.dispatch(checkingGameCurrency(false));
                    const iframeAllowCurrencyChange = message.value as boolean;
                    if (iframeAllowCurrencyChange) {
                        helpers.doSwitchCurrency();
                    }
                }
                break;
            case 'RESPONSE_CAN_OPEN_CASHIER':
                if (store.getState().game.isCheckingCanOpenCashier) {
                    store.dispatch(checkingCanOpenCashier(false));
                    if (message.value) {
                        helpers.openCashier();
                    }
                }
                break;
            case 'RESPONSE_CAN_OPEN_CASHOUT':
                if (store.getState().game.isCheckingCanOpenCashout) {
                    store.dispatch(checkingCanOpenCashout(false));
                    if (message.value) {
                        // Don't change the sidebar when the user in a game
                        helpers.sendToRedeem();
                    }
                }
                break;
            case 'RESPONSE_CAN_SHOW_LOBBY': {
                const {
                    game: { isCheckingShowLobby },
                } = store.getState();
                if (isCheckingShowLobby) {
                    store.dispatch(checkingShowLobby(false));
                    message.value
                        ? unBlockNavigationAndLeaveGame(history)
                        : store.dispatch(setLoadingState(GAME_LOADED));
                }
                break;
            }
            case 'RESPONSE_GET_PLAY_ID':
                if (store.getState().game.isCheckingPlayId) {
                    store.dispatch(checkingPlayId(false));
                    store.dispatch(setPlayId(message.value));
                }
                break;

            default:
                ((a: never): never => {
                    throw new Error('unhandled case : ' + a);
                })(message);
        }
    };

    return iframeEventHandler;
};

// Helpers
const gameHasInit = (message: GAME_HAS_INIT, history: History): void => {
    let b = true;
    b = b && typeof message.company === 'string';
    b = b && typeof message.myName === 'string';
    b = b && typeof message.gameId === 'string';
    b = b && typeof message.gold === 'boolean';
    b = b && typeof message.sweeps === 'boolean';
    b = b && typeof message.currency === 'number';
    b = b && typeof message.comingSoon === 'boolean';
    b = b && typeof message.isPhone === 'boolean';
    if (!b) {
        console.log('UNEXPECTED VALUE - gameHasInit.');
        history.push('/');
        return;
    }
    helpers.showTopHud(); // We should also have a HUD reducer listening to gameHasInit and update some showHud state to true (⌐■_■)
    helpers.hideInformationWindow();
    helpers.currencyButtonState(true);
    helpers.checkForMessages();
    helpers.changeToGameWindowScreen();
    helpers.triggerResize();

    const frame = getThirdPartyIframe();
    if (frame && frame.contentWindow) {
        frame.contentWindow.focus();
    }

    if (!store.getState().game.hasGameInit) {
        store.dispatch(initGame(message));
    }
};

function getIframeLoaderUrl(gameIframeData: GameIframeDataType): string {
    switch (gameIframeData.company) {
        case 'two_fish_iframe':
            return (
                ClientConfig.LEGACY_GAMES_CLIENTS_URL +
                '/tfloader/index.html?' +
                generateQueryParameters(gameIframeData)
            );
        case 'pearfiction':
            gameIframeData.baseUrl = ClientConfig.LEGACY_GAMES_CLIENTS_URL;
            return (
                ClientConfig.LEGACY_GAMES_CLIENTS_URL +
                '/pfloader/index.html?' +
                generateQueryParameters(gameIframeData)
            );
        case 'chumba_game':
            return (
                ClientConfig.LEGACY_GAMES_CLIENTS_URL + '/loader/index.html?' + generateQueryParameters(gameIframeData)
            );
        case 'VGW_OWN_SERVER':
            gameIframeData.useIframeStateManager = true;
            gameIframeData.baseUrl = getChumbaMonorepoServerApiUrl(gameIframeData);
            return (
                getChumbaGameServerClientUrl(gameIframeData) + '/index.html?' + generateQueryParameters(gameIframeData)
            );
        default:
            return '';
    }
}
