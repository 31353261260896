import store from '../store';

import { httpClient } from '../common/services/net/http-client';
import { LogoutResponse } from '@chu/http/dist';
import {
    LOGGED_IN_SESSION_STORAGE_KEY,
    SHOWN_MARKET_EXIT_INFORM_MODAL_SESSION_STORAGE_KEY,
    SHOWN_TWOK_MODAL_SESSION_STORAGE_KEY,
} from 'store/user/saga';
import { logoutUser } from '../store/user/actions';
import { ClientConfig } from 'config/client-config';

type LogoutOptions = {
    noRedirect: boolean;
};

export const logout = async (options?: LogoutOptions) => {
    try {
        const loggedoutResponse = await callLogout();
        window.sessionStorage.setItem(LOGGED_IN_SESSION_STORAGE_KEY, 'false');
        window.sessionStorage.setItem(SHOWN_TWOK_MODAL_SESSION_STORAGE_KEY, 'false');
        window.sessionStorage.setItem(SHOWN_MARKET_EXIT_INFORM_MODAL_SESSION_STORAGE_KEY, 'false');

        if (loggedoutResponse.success) {
            console.log('logout success redirecting...');
            store.dispatch(logoutUser());
        } else {
            console.log('logout not a success when trying to log out user');
        }
    } catch (error) {
        console.log('logout unsuccessful, redirecting to login', { error });
    } finally {
        const noRedirect = options && options.noRedirect;
        if (!noRedirect) {
            redirectToLogin();
        }
    }
};

async function callLogout(): Promise<LogoutResponse> {
    return await httpClient.get('/api/logout');
}

function redirectToLogin() {
    window.location.href = ClientConfig.LOGIN_URL;
}
