import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { ThemeButton } from '../../../elements/button/theme-buttons';
import { GenericModalLayout } from './generic-modal';
import { ReactComponent as Pending } from './pending-state.svg';

export const PendingVerificationModal = () => {
    const dispatch = useDispatch();

    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Pending />
                </div>
            }
        >
            <div className='pending-modal__container'>
                <h2>Verification in progress</h2>
                <p>Sit back and relax, you’ll be notified by email once the review is done.</p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='dialog-PENDING_VERIFICATION__ok-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        onClick={() => dispatch(hideModal(PENDING_VERIFICATION_MODAL))}
                    >
                        OK
                    </ThemeButton>
                </div>
            </div>
        </GenericModalLayout>
    );
};

export const getPendingVerificationModalProps = () => ({ type: PENDING_VERIFICATION_MODAL } as const);

export const PENDING_VERIFICATION_MODAL = 'PENDING_VERIFICATION_MODAL';
