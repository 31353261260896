import axios from 'axios';
import { ClientConfig } from 'config/client-config';

export type PlayerTokenAmounts = {
    goldCoins: number;
    sweepsCoins: number;
    redeemableSweepsCoins: number;
};

export const fetchPlayerTokenAmounts = async (userId: number): Promise<PlayerTokenAmounts> => {
    const { data } = await axios.get(`${ClientConfig.PLAYER_TOKEN_URL}/amounts/${userId}`, {
        withCredentials: true,
    });

    const goldCoins = data?.goldCoins?.amount;
    const sweepsCoins = data?.sweepsCoins?.amount;
    const redeemableSweepsCoins = data?.redeemableSweepsCoins?.amount;

    if (typeof goldCoins !== 'number') {
        throw new Error(`Expected goldCoins amount to be a number, got: ${goldCoins}`);
    }
    if (typeof sweepsCoins !== 'number') {
        throw new Error(`Expected sweepsCoins amount to be a number, got: ${sweepsCoins}`);
    }
    if (typeof redeemableSweepsCoins !== 'number') {
        throw new Error(`Expected redeemableSweepsCoins amount to be a number, got: ${redeemableSweepsCoins}`);
    }

    return { goldCoins, sweepsCoins, redeemableSweepsCoins };
};
