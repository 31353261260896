import {
    UserState,
    UserAction,
    UPDATE_USER,
    UPDATE_GOLD_BALANCE,
    UPDATE_SWEEPS_BALANCE,
    SAVE_USER_PROPERTIES,
    UPDATE_USER_VERIFICATION_STATUS,
    UserVerificationStatus,
    LOGIN_USER,
} from './types';
import { createReducer } from 'store/create';

export const initialState: UserState = {
    goldBalance: 0,
    sweepsBalance: 0,
    userId: 0,
    username: '',
    firstname: '',
    lastname: '',
    isStaff: false,
    properties: {},
    verificationStatus: UserVerificationStatus.NOT_YET_VERIFIED,
    isFirstSession: false,
    channel: undefined,
    registrationTimestamp: undefined,
};

export { reducer as userReducer };

const { reducer, handle } = createReducer<UserAction, UserState>(initialState);

handle(UPDATE_USER, (_, action) => ({ ...action.payload }));

handle(UPDATE_GOLD_BALANCE, (_, action) => ({
    goldBalance: action.balance,
}));

handle(UPDATE_SWEEPS_BALANCE, (_, action) => ({
    sweepsBalance: action.balance,
}));

handle(LOGIN_USER, (_, action) => ({
    ...action.payload,
}));

handle(SAVE_USER_PROPERTIES, (_, action) => {
    const {
        payload: { properties },
    } = action;
    return {
        properties: { ...properties },
    };
});

handle(UPDATE_USER_VERIFICATION_STATUS, (_, action) => ({
    verificationStatus: action.status,
}));
