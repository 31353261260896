import { LogMessage } from './message';
import { Appender } from './appender';

export const createConsoleAppender = (): Appender => {
    return {
        flush: () => Promise.resolve(),
        append: (message: LogMessage) => {
            switch (message.level) {
                case 'ERROR':
                    console.error(message.body);
                    break;
                case 'WARN':
                    console.warn(message.body);
                    break;
                case 'INFO':
                    console.log(message.body);
                    break;
            }
        },
    };
};
