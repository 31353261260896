import { getGenericModalProps } from '../generic/generic-modal-prop-creators';

export const getDefaultErrorModalProps = () => {
    const heading = 'An error has occurred';
    const body = 'Sorry, we’re currently having technical issues, please try again later';

    return getGenericModalProps({
        heading,
        body,
        refreshButton: false,
        xButton: true,
        id: 'ERROR',
    });
};
