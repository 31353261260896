import {
    getRecommendedGamesLastRefreshedAtKey,
    getLocalStorageValue,
    setLocalStorage,
    getRecommendedGamesCacheKey,
} from 'helpers/localstorage';

export class RecommendedGamesRefreshHelper {
    private recommendedGamesLastRefreshedAtKey: string;
    private recommendedGamesCacheKey: string;

    constructor(private readonly userId: number) {
        this.recommendedGamesLastRefreshedAtKey = getRecommendedGamesLastRefreshedAtKey(this.userId);
        this.recommendedGamesCacheKey = getRecommendedGamesCacheKey(this.userId);
    }

    recommendedGamesRefreshed(refreshedAt: number, recommendedGamesCache: string[]): void {
        setLocalStorage(this.recommendedGamesLastRefreshedAtKey, JSON.stringify(refreshedAt));
        setLocalStorage(this.recommendedGamesCacheKey, JSON.stringify(recommendedGamesCache));
    }

    shouldRefreshRecommendedGames(): boolean {
        const recommendedGamesLastRefreshedAt = getLocalStorageValue(this.recommendedGamesLastRefreshedAtKey);
        return recommendedGamesLastRefreshedAt ? this.aDayHasPassed(recommendedGamesLastRefreshedAt) : true;
    }

    getRecommendedGamesCache(): string[] {
        const recommendedGamesCache = getLocalStorageValue(this.recommendedGamesCacheKey);
        return recommendedGamesCache ? recommendedGamesCache : [];
    }

    private aDayHasPassed(recommendedGamesLastRefreshedAtInMs: string): boolean {
        const todayInMilliseconds = Date.now();
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const dayHasPassed = todayInMilliseconds - Number(recommendedGamesLastRefreshedAtInMs) > millisecondsPerDay;
        return dayHasPassed;
    }
}
