import React, { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { hideModal, queueModal } from 'store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { LINK_YOUR_BANK_ACCOUNT } from '../modal-props-types';
import { LinkYourBankAccountModalActions } from './link-your-bank-account-modal-actions';
import { getRedemptionErrorModalProps } from 'components/domain/packages/modals/redeem/redemption-error-prop-creators';
import { getRedeemHubHref } from 'common/services/purchase';
import BankLinkImg from './bank-link.png';

import './link-your-bank-account-modal.scss';

export const LinkYourBankAccountModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const id: GenericModalTypes = LINK_YOUR_BANK_ACCOUNT;
    const modalName = 'link-your-bank-account-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <img className={`${modalName}__image`} src={BankLinkImg} alt='Link your bank account' loading='lazy' />
            <div className={`${modalName}__body`}>
                <h2 className={`${modalName}__header`}>Link your bank account</h2>
                <p className={`${modalName}__content`}>To start redeeming, you will need to link your bank account.</p>
                <LinkYourBankAccountModalActions
                    modalName={modalName}
                    loadingBtn={loadingBtn}
                    handleLinkBankAccount={handleLinkBankAccount}
                    handleRedirectToRedeemHub={handleRedirectToRedeemHub}
                />
            </div>
        </GenericModalLayout>
    );

    async function handleLinkBankAccount(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        history.push('/bank-verification');
        dispatch(hideModal(LINK_YOUR_BANK_ACCOUNT));
    }

    async function handleRedirectToRedeemHub() {
        try {
            setLoadingBtn(true);
            const href = await getRedeemHubHref();
            window.location.href = href;
            setLoadingBtn(false);
            dispatch(hideModal(LINK_YOUR_BANK_ACCOUNT));
        } catch (err) {
            console.error('Failed to redirect to redemption-hub', err);
            setLoadingBtn(false);
            dispatch(hideModal(LINK_YOUR_BANK_ACCOUNT));
            dispatch(queueModal(getRedemptionErrorModalProps()));
        }
    }
};

export const getLinkYourBankAccountModalProps = () => ({ type: LINK_YOUR_BANK_ACCOUNT } as const);
