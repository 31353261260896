import {
    CLEAR_GAME,
    FORCE_IFRAME_RESIZE,
    FORCE_IFRAME_RESIZE_CLEAR,
    ForceIframeResizeAction,
    ForceIframeResizeClearAction,
    GAME_CHECKING_CURRENCY,
    GAME_CHECKING_OPEN_CASHIER,
    GAME_CHECKING_OPEN_CASHOUT,
    GAME_CHECKING_PLAY_ID,
    GAME_CHECKING_SHOW_LOBBY,
    GAME_INIT,
    GAME_LOADING_STATES,
    GameClearAction,
    GameInitAction,
    GameSetCheckCurrencyAction,
    GameSetCheckOpenCashierAction,
    GameSetCheckOpenCashoutAction,
    GameSetCheckPlayIdAction,
    GameSetCheckShowLobbyAction,
    SET_CURRENCY_DISPLAY_ANIMATE_ABLE,
    SET_GAME_ID,
    SET_GAME_LOAD_START_DATE,
    SET_IS_CLOCK_DISABLED,
    SET_IS_COUNTING_UP_CURRENCY,
    SET_IS_FULLSCREEN,
    SET_IS_LOCKED_CURRENCY_MODE,
    SET_IS_RGS_GAME_LOADING,
    SET_LOADING,
    SET_RGS_GAME_TOKEN,
    SetCurrencyDisplayAnimateAbleAction,
    SetGameIdAction,
    SetGameLoadStartDateAction,
    SetIsClockDisabled,
    SetIsCountingUpCurrencyAction,
    SetIsFullscreen,
    SetIsLockedCurrencyModeAction,
    SetIsRGSGameLoading,
    SetLoadingStateAction,
    SetRGSGameToken,
    UNLOAD_GAME,
    UnLoadGameAction,
} from './types';
import { GAME_HAS_INIT } from '../../chumba/third-party-types';

export const initGame = (message: GAME_HAS_INIT): GameInitAction => {
    const { type, ...payload } = message;
    return {
        type: GAME_INIT,
        payload,
    };
};

export const clearGame = (): GameClearAction => {
    return {
        type: CLEAR_GAME,
    };
};

export const unloadGame = (): UnLoadGameAction => {
    return {
        type: UNLOAD_GAME,
    };
};

export function forceIframeResizeAction(): ForceIframeResizeAction {
    return {
        type: FORCE_IFRAME_RESIZE,
    };
}

export function forceIframeResizeClearAction(): ForceIframeResizeClearAction {
    return {
        type: FORCE_IFRAME_RESIZE_CLEAR,
    };
}

export function checkingShowLobby(checkingShowLobby: boolean): GameSetCheckShowLobbyAction {
    return {
        type: GAME_CHECKING_SHOW_LOBBY,
        checkingShowLobby,
    };
}

export function checkingGameCurrency(checkingCurrency: boolean): GameSetCheckCurrencyAction {
    return {
        type: GAME_CHECKING_CURRENCY,
        checkingCurrency,
    };
}

export function checkingCanOpenCashout(checkingCanOpenCashout: boolean): GameSetCheckOpenCashoutAction {
    return {
        type: GAME_CHECKING_OPEN_CASHOUT,
        checkingCanOpenCashout,
    };
}

export function checkingCanOpenCashier(checkingCanOpenCashier: boolean): GameSetCheckOpenCashierAction {
    return {
        type: GAME_CHECKING_OPEN_CASHIER,
        checkingCanOpenCashier,
    };
}

export function checkingPlayId(checkingPlayId: boolean): GameSetCheckPlayIdAction {
    return {
        type: GAME_CHECKING_PLAY_ID,
        checkingPlayId,
    };
}

export const setIsFullscreen = (isFullscreen: boolean): SetIsFullscreen => ({
    type: SET_IS_FULLSCREEN,
    isFullscreen,
});

// set currency lock mode will block the buy, redeem, switch, and back to lobby buttons from working
export function setCurrencyLockMode(isLockedCurrencyMode: boolean): SetIsLockedCurrencyModeAction {
    return {
        type: SET_IS_LOCKED_CURRENCY_MODE,
        isLockedCurrencyMode,
    };
}

export function setCurrencyDisplayAnimateAble(
    isCurrencyDisplayAnimateAble: boolean
): SetCurrencyDisplayAnimateAbleAction {
    return {
        type: SET_CURRENCY_DISPLAY_ANIMATE_ABLE,
        isCurrencyDisplayAnimateAble: isCurrencyDisplayAnimateAble,
    };
}

export function setLoadingState(loadingStatus: GAME_LOADING_STATES): SetLoadingStateAction {
    return {
        type: SET_LOADING,
        loadingStatus,
    };
}

export function setGameId(gameId: string): SetGameIdAction {
    return {
        type: SET_GAME_ID,
        gameId,
    };
}

export function setIsCountingUpCurrency(isCountingUpCurrency: boolean): SetIsCountingUpCurrencyAction {
    return {
        type: SET_IS_COUNTING_UP_CURRENCY,
        isCountingUpCurrency,
    };
}

export function setRGSGameToken(gameToken?: string): SetRGSGameToken {
    return {
        type: SET_RGS_GAME_TOKEN,
        gameToken,
    };
}

export function setIsRGSGameLoading(isLoading: boolean): SetIsRGSGameLoading {
    return {
        type: SET_IS_RGS_GAME_LOADING,
        isLoading,
    };
}

export function setGameLoadStartDate(timestamp: number): SetGameLoadStartDateAction {
    return {
        type: SET_GAME_LOAD_START_DATE,
        timestamp,
    };
}

export function setIsClockDisabled(isClockDisabled: boolean): SetIsClockDisabled {
    return {
        type: SET_IS_CLOCK_DISABLED,
        isClockDisabled,
    };
}
