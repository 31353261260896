import * as React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

export type ButtonProps = {
    hide?: boolean;
    disabled?: boolean;
    to?: string;
    text?: string;
    className?: string;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function buildClassName(values: (string | undefined)[]) {
    return values.filter((name) => name !== undefined).join(' ');
}

/**
 * @deprecated Use ThemeButton or build a custom component with a <button> element instead.
 */
export const Button = ({ hide, disabled, to, text, children, className, ...rest }: ButtonProps) => {
    const content = text || children;

    const customClassName = buildClassName(['base-button', className]);

    if (hide) {
        return null;
    }

    if (!to) {
        return (
            <button disabled={disabled} className={customClassName} {...rest}>
                {content}
            </button>
        );
    }

    return (
        <Wrap linkTo={to}>
            <button className={customClassName} {...rest}>
                {content}
            </button>
        </Wrap>
    );
};

type WrapProps = { children: any; linkTo?: string };

function Wrap({ linkTo, children }: WrapProps): JSX.Element {
    if (linkTo) {
        if (linkTo.includes('://')) {
            return (
                <a href={linkTo} rel='noopener noreferrer' target='_blank' className='button__link'>
                    {children}
                </a>
            );
        }
        return (
            <Link to={linkTo} className='button__link'>
                {children}
            </Link>
        );
    }

    return <>{children}</>;
}
