import React, { ReactChild } from 'react';
import dompurify from 'dompurify';
import { UserMessageDetails } from '@chu/http/dist';

export const GENERIC_MODAL = 'GENERIC_MODAL';

export type GenericModalProperties = Omit<ReturnType<typeof getGenericModalProps>, 'type'>;

export type GenericModalTypes =
    | 'CONNECTION_ERROR'
    | 'SERVER_CONNECT'
    | 'DISCONNECTED'
    | 'IFRAME_MSG'
    | 'VERIFICATION'
    | 'ERROR'
    | 'TWO_K_VERIFICATION'
    | 'TWO_K_PENDING'
    | 'TWO_K_PENDING_REMINDER'
    | 'TWO_K_BLOCKED'
    | 'INSUFFICIENT_FUNDS'
    | 'GC_TOPUP'
    | 'LOGOUT'
    | 'TERMS_OF_USE'
    | 'SOURCE_OF_WEALTH'
    | 'TIME_WARNING'
    | 'INACTIVITY'
    | 'SWIPE_TO_FULL_SCREEN'
    | 'REGULATORY_LIMIT'
    | 'TEMPORARILY_UNAVAILABLE'
    | 'OPEN_STANDARD_PLAY_GAME'
    | 'MARKET_EXIT_NOT_YET_VERIFIED'
    | 'MARKET_EXIT_PENDING'
    | 'MARKET_EXIT_REJECT'
    | 'MARKET_EXIT_REVERIFY'
    | 'MARKET_EXIT_INFORM'
    | 'UPDATE_BANK_ACCOUNT'
    | 'LINK_YOUR_BANK_ACCOUNT'
    | 'STORE_CHECKOUT_RECEIPT_MODAL'
    | ''
    | UserMessageDetails['action'];

type GetInformationModalPropsProps = {
    heading: string;
    body: ReactChild;
    xButton?: boolean;
    refreshButton?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    xIcon?: boolean;
    id: GenericModalTypes;
};

export const getGenericModalProps = ({
    heading,
    body,
    xButton = true,
    refreshButton = false,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false,
    xIcon = false,
    id = '',
}: GetInformationModalPropsProps) =>
    ({
        type: GENERIC_MODAL,
        children: createGenericChild(heading, body, id),
        xButton,
        refreshButton,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
        xIcon,
        id,
    } as const);

const createGenericChild = (heading: string, body: ReactChild, id: string): ReactChild => {
    const sanitizer = dompurify.sanitize;
    if (typeof body === 'string') {
        return (
            <div id={`dialog--content__${id}`}>
                {heading !== '' && <h2>{heading}</h2>}
                <div dangerouslySetInnerHTML={{ __html: sanitizer(body) }} />
            </div>
        );
    } else {
        return (
            <div id={`dialog--content__${id}`}>
                {heading !== '' && <h2>{heading}</h2>}
                <div>{body}</div>
            </div>
        );
    }
};
