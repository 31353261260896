import { GameProvider } from '@chu/http/dist';
import { Game } from '../store/layout/types';
import { notUndefined } from './types';

export const getGamesFromGameIds = (gameIds: string[]) => (games: Game[]) => {
    return (gameIds || []).map((g) => games.find((gm) => gm.gameId === g)).filter(notUndefined);
};

export const gameCompanyMapper = (metaCompany: string): GameProvider | undefined => {
    switch (metaCompany.toUpperCase()) {
        case 'CHUMBA_GAME':
            return 'chumba_game';
        case 'CHUMBA':
            return 'chumba';
        case 'TWO_FISH':
            return 'two_fish_iframe';
        case 'TWO_FISH_IFRAME':
            return 'two_fish_iframe';
        case 'PEARFICTION':
            return 'pearfiction';
        case 'PEAR_FICTION':
            return 'pearfiction';
        case 'PLAYTECH':
            return 'PLAYTECH';
        case 'NETENT':
            return 'NETENT';
        case 'SWINTT':
            return 'SWINTT';
        case 'RELAX':
            return 'RELAX';
        case 'SLOTMILL':
            return 'SLOTMILL';
        case 'PRAGMATIC_SLOTS':
            return 'PRAGMATIC_SLOTS';
        case 'PRAGMATIC_BINGO':
            return 'PRAGMATIC_BINGO';
        case 'GAMING_REALMS':
            return 'GAMING_REALMS';
        case 'G_GAMES':
            return 'G_GAMES';
        case 'ONEXTWO':
            return 'ONEXTWO';
        case 'EVERI':
            return 'EVERI';
        case 'GAP':
            return 'GAP';
        case 'VGW':
            return 'chumba_game';
        // because i'm a bad muthaflippa
        // (Nah, because gem huntress is coded as VGW in db,
        // but iframe version exists now, VGW games will always bork anyways)
        case 'VGW_OWN_SERVER':
            return 'VGW_OWN_SERVER';
    }
};
