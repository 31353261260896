import { createHttpRoute, createHttpSchema, t, TypeFromTypeInfo } from 'http-schemas';

export type LobbyLayoutResponse = TypeFromTypeInfo<typeof LobbyLayoutResponse>;
export type MiniGamesLayoutResponse = TypeFromTypeInfo<typeof MiniGamesLayoutResponse>;
export type LogoutResponse = TypeFromTypeInfo<typeof LogoutResponse>;
export type UserResponse = TypeFromTypeInfo<typeof UserResponse>;
export type UserMetaData = TypeFromTypeInfo<typeof UserMetaData>;
export type UserTermsOfUseAcceptanceState = TypeFromTypeInfo<typeof UserTermsOfUseAcceptanceState>;

export type GameMetadata = TypeFromTypeInfo<typeof GameMetadata>;
export type GameLayoutInformation = TypeFromTypeInfo<typeof GameLayoutInformation>;
export type GameProvider = TypeFromTypeInfo<typeof Company>;
export type PlayableTokens = TypeFromTypeInfo<typeof PlayableTokens>;
export type GameType = TypeFromTypeInfo<typeof GameType>;
export type BlockedGameType = TypeFromTypeInfo<typeof BlockedGameType>;
export type JackpotGame = TypeFromTypeInfo<typeof JackpotGame>;
export type TrackedUserAction = TypeFromTypeInfo<typeof UserAction>;
export type UserMessageDetails = TypeFromTypeInfo<typeof UserMessageDetails>;
export type CurrencyType = TypeFromTypeInfo<typeof CurrencyType>;
export type Channel = TypeFromTypeInfo<typeof Channel>;
export type CurrencyTypeText = TypeFromTypeInfo<typeof CurrencyTypeText>;
export type GameRecommendations = TypeFromTypeInfo<typeof GameRecommendations>;
export type BalanceResponse = TypeFromTypeInfo<typeof BalanceResponse>;
export type PlayResult = TypeFromTypeInfo<typeof PlayResult>;
export type GamingHistoryResponse = TypeFromTypeInfo<typeof GamingHistoryResponse>;
export type UserPreferences = TypeFromTypeInfo<typeof UserPreferences>;

export type ErrorResponse = TypeFromTypeInfo<typeof ErrorResponse>;
export type PromotionalPlay = TypeFromTypeInfo<typeof PromotionalPlay>;
export type FeatureBlock = TypeFromTypeInfo<typeof FeatureBlock>;

export type CategoryIconOptions = TypeFromTypeInfo<typeof CategoryIconReference>;
export type DynamicCategoryOptions = TypeFromTypeInfo<typeof DynamicCategoryReference>;

export const Currency = {
    GOLD: 1,
    SWEEPS: 4
} as const;

export const CurrencyText = {
    GOLD: 'GOLD',
    SWEEPS: 'SWEEPS'
} as const;

export const GoldCoinTopup = {
    AMOUNT: 12_500,
    THRESHOLD: 12_500
}

const CurrencyType = t.union(t.unit(Currency.GOLD), t.unit(Currency.SWEEPS));
const CurrencyTypeText = t.union(t.unit('GOLD'), t.unit('SWEEPS'));

const PlayableTokens = t.union(t.unit('all'), t.unit('gold'));
const GameType = t.union(t.unit('slot'), t.unit('table'), t.unit('live_casino'), t.unit('live_dealer'), t.unit('RGS'), t.unit('slingo'), t.unit('bingo'), t.unit('scratchcard'), t.unit('instantwin'));
const BlockedGameType = t.union(GameType, t.unit('jackpot'));
const Company = t.union(
    t.unit('chumba_game'),
    t.unit('CHUMBA_GAME'),
    t.unit('chumba'),
    t.unit('CHUMBA'),
    t.unit('two_fish'),
    t.unit('TWO_FISH'),
    t.unit('two_fish_iframe'),
    t.unit('TWO_FISH_IFRAME'),
    t.unit('pearfiction'),
    t.unit('PEAR_FICTION'),
    t.unit('VGW'),
    t.unit('VGW_OWN_SERVER'),
    t.unit('BETSOFT'),
    t.unit('PLAYTECH'),
    t.unit('NETENT'),
    t.unit('SWINTT'),
    t.unit('RELAX'),
    t.unit('SLOTMILL'),
    t.unit('PRAGMATIC'),
    t.unit('PRAGMATIC_SLOTS'),
    t.unit('PRAGMATIC_BINGO'),
    t.unit('GAMING_REALMS'),
    t.unit('G_GAMES'),
    t.unit('ONEXTWO'),
    t.unit('EVERI'),
    t.unit('GAP')
);

const CategoryIconReference = t.union(
    t.unit('NO_ICON'),
    t.unit('GREEN_PLAY'),
    t.unit('STARS'),
    t.unit('FIRE'),
    t.unit('THUMBS_UP'),
    t.unit('DIAMOND'),
    t.unit('CHUMBA_C'),
    t.unit('FAVORITE'),
    t.unit('TAG'),
    t.unit('CARDS'),
    t.unit('SLINGO'),
    t.unit('GOLD_COIN'),
    t.unit('FIRESHOT'),
    t.unit('HOLD_AND_SPIN'),
    t.unit('CLASSIC_SLOTS'),
    t.unit('COWBOY_HAT'),
    t.unit('TRIDENT'),
    t.unit('PLAYTECH'),
    t.unit('REELPLAY'),
    t.unit('SLOTS'),
    t.unit('BATTLE_ROYALE'),
);

const DynamicCategoryReference = t.union(
    t.unit('JACKPOTS'),
    t.unit('TABLE_GAMES'),
    t.unit('SLINGO'),
    t.unit('GOLD_SERIES_GAMES'),
    t.unit('MY_FAVOURITES'),
    t.unit('PLAY_AGAIN'),
    t.unit('NEW_RELEASES'),
    t.unit('TOP_10'),
    t.unit('RECOMMENDED_FOR_YOU'),
    t.unit('BATTLE_ROYALE'),
);

const CategoryTileSize = t.union(
    t.unit('sml'),
    t.unit('lg'),
);

const Channel = t.union(
    t.unit('GOOGLE'),
    t.unit('FACEBOOK'),
    t.unit('STANDALONE'),
);

const UserAction = t.object({
    actionName: t.string,
    time: t.number,
    parameter0: t.union(t.string, t.number, t.null),
    parameter1: t.union(t.string, t.number, t.null),
});

const GameMetadata = t.object({
    gameId: t.string,
    gameName: t.union(t.string, t.null),
    company: Company,
    playableTokens: PlayableTokens,
    gameType: GameType,
    mathModel: t.union(t.string, t.null),
    active: t.number,
    grandJackpotName: t.union(t.string, t.null),
    majorJackpotName: t.union(t.string, t.null),
    mustHitValueGold: t.union(t.number, t.string, t.null),
    mustHitValueSweeps: t.union(t.number, t.string, t.null),
    clientUrl: t.optional(t.string),
    responsive: t.boolean,
    portrait: t.boolean,
});

const LobbyCategory = t.object({
    categoryId: t.string,
    title: t.string,
    iconRef: t.optional(CategoryIconReference),
    infoBlob: t.optional(t.string),
    tileSize: CategoryTileSize,
    games: t.array(t.string),
    dynamicRef: t.optional(DynamicCategoryReference),
    enabled: t.boolean,
    linkTo: t.optional(t.union(t.string, t.undefined)),
})

const LobbyManagementCategory = t.object({
    categoryId: t.string,
    title: t.string,
    iconRef: t.optional(t.union(CategoryIconReference, t.undefined)),
    infoBlob: t.optional(t.union(t.string, t.undefined)),
    tileSize: CategoryTileSize,
    games: t.array(t.string),
    dynamicRef: t.optional(t.union(DynamicCategoryReference, t.undefined)),
    enabled: t.boolean,
    updatedAt: t.date,
    position: t.number,
    linkTo: t.optional(t.union(t.string, t.undefined)),
})

const LobbyManagementGame = t.object({
    gameId: t.string,
    gameName: t.union(t.string, t.null),
    gameTileImageUrl: t.string,
    provider: Company,
    gameType: GameType,
    playableTokens: PlayableTokens,
})

const LobbyCategoriesResponse =  t.object({
    categories: t.array(LobbyCategory),
});

const LobbyManagementCategoriesResponse =  t.object({
    categories: t.array(LobbyManagementCategory),
});

const LobbyManagementGamesResponse =  t.object({
    games: t.array(LobbyManagementGame),
});

const GameLayoutInformation = t.object({
    gameId: t.string,
    gameType: GameType,
});

const LobbyLayoutResponse = t.object({
    metadata: t.array(GameMetadata),
    games: t.array(GameLayoutInformation),
    inactiveGames: t.array(GameLayoutInformation),
    id: t.number,
    recentlyPlayed: t.array(t.string),
    justDropped: t.array(t.string),
    blockedGameIds: t.array(t.string),
    blockedGameTypes: t.array(BlockedGameType)
});
const MiniGamesLayoutResponse = t.object({
  games: t.array(t.object({
    id: t.string,
    provider: Company,
  })),
});

const UserMetaData = t.object({
    userId: t.number,
    username: t.string,
    firstname: t.string,
    lastname: t.string,
    isStaff: t.boolean,
    isFirstSession: t.boolean,
    properties: t.any,
    channel: t.union(Channel, t.undefined),
    registrationTimestamp: t.union(t.string, t.undefined),
    brazeToken: t.string,
});

const UserMessageDetails = t.object({
    action: t.union(t.unit('NEW_WINNER'), t.unit('SHOW_MESSAGE'), t.unit('UPDATE_BALANCE')),
    comment: t.string,
    reason:  t.optional(t.string),
    transactionId: t.optional(t.string),
    channelIdentification: t.optional(t.string),
    usdAmount: t.optional(t.number),
    packageId: t.optional(t.string),
    packageName: t.optional(t.string),
    depositTypeEvent: t.optional(t.union(t.string, t.null)),
});

const Toggles = t.any; // This should be {[key: string]: boolean}

const UserTermsOfUseAcceptanceState = t.object({
    currentActiveTermsOfUseId: t.string,
    accepted: t.boolean,
    userLatestAcceptedTermsOfUseId: t.string,
    acceptedPrivacyPolicy: t.boolean,
    acceptedTerms: t.boolean,
});

const AcceptedTermsOfUse = t.object({
    termsOfUseVersionId: t.string,
});

const AddLobbyManagementCategory = t.object({
    enabled: t.boolean,
    title: t.string,
    tileSize: CategoryTileSize,
    iconRef: t.optional(CategoryIconReference),
    infoBlob: t.optional(t.string),
    dynamicRef: t.optional(DynamicCategoryReference),
    linkTo: t.optional(t.string),
});

const UpdateLobbyManagementCategory = t.object({
    categoryId: t.string,
    enabled: t.boolean,
    title: t.string,
    tileSize: CategoryTileSize,
    iconRef: t.optional(CategoryIconReference),
    infoBlob: t.optional(t.string),
    dynamicRef: t.optional(DynamicCategoryReference),
    linkTo: t.optional(t.string),
});

const AddGameToLobbyManagementCategory = t.object({
    gameId: t.string,
    categoryId: t.string
});


const AddGamesToLobbyManagementCategory = t.object({
    games: t.array(t.string),
    categoryId: t.string
});

const SetLobbyManagementCategoryGamePosition = t.object({
    gameId: t.string,
    categoryId: t.string,
    position: t.number,
});

const SetLobbyManagementCategoryPosition = t.object({
    categoryId: t.string,
    position: t.number
})

const SetLobbyManagementCategoryEnabled = t.object({
    categoryId: t.string,
    enabled: t.boolean
})

const RemoveLobbyManagementCategory = t.object({
    categoryId: t.string,
});

const RemoveGameFromLobbyManagementCategory = t.object({
    gameId: t.string,
    categoryId: t.string
});

const MustKycState = t.union(
    t.unit('MUST_KYC_FLAGGED'),
    t.unit('NOT_MUST_KYC_FLAGGED')
);

const UserVerificationStatus = t.union(
    t.unit('REJECT'),
    t.unit('PENDING'),
    t.unit('REVERIFY'),
    t.unit('NOT_YET_VERIFIED'),
    t.unit('VERIFIED'),
    t.unit('ACCEPT'),
    t.unit('SDD_ACCEPT')
);
  
const PromotionalPlay = t.union(
    t.unit('ENABLED'),
    t.unit('DISABLED')
);

const FeatureBlock = t.union(
    t.unit('PURCHASE'),
    t.unit('OFFERS'),
    t.unit('DAILY_BONUS'),
    t.unit('AMOE'),
    t.unit('CHALLENGES'),
    t.unit('QUESTS'),
    t.unit('GAMES'),
    t.unit('REDEMPTION'),
    t.unit('FREE_GC'),
    t.unit('ACCESS'),
    t.unit('SWEEPS_COINS_PLAY'),
    t.unit('GOLD_COINS_PLAY'),
);

const UserPreferences = t.object({
    version: t.number,
    preferencesVersion: t.number,
    warningExpiryTimeoutMs: t.number,
});

const UserResponse = t.object({
    success: t.boolean,
    user: UserMetaData,
    toggles: Toggles,
    termsOfUse: UserTermsOfUseAcceptanceState,
    sourceOfWealthBlocked: t.boolean,
    mustKycStatus: MustKycState,
    messages: t.array(UserMessageDetails),
    promotionalPlay: PromotionalPlay,
    userPreferences: UserPreferences,
    stateLocation: t.union(t.string, t.undefined),
    blockedFeatures: t.array(FeatureBlock),
    showMarketExitModal: t.boolean,
    userVerificationStatus: t.union(UserVerificationStatus, t.null),
});

const UserPropertiesResponse = t.object({
    properties: t.any,
    createdAt: t.union(t.date, t.null),
    updatedAt: t.union(t.date, t.null),
});

const LogoutResponse = t.object({
    success: t.boolean
});

const JackpotGame = t.object({
    gameName: t.string,
    grandJackpotName: t.string,
    majorJackpotName: t.union(t.string, t.null),
    mustHitValueGold: t.union(t.number, t.null),
    mustHitValueSweeps: t.union(t.number, t.null),
});

const GameRecommendations = t.object({
    gameIds: t.array(t.string),
});

const TopGamesResponse = t.object({
    gameIds: t.array(t.string),
});

const ErrorResponse = t.object({
    success: t.boolean,
    message: t.optional(t.string),
});

const PlayResult = t.object({
    date: t.string,
    gameName: t.string,
    coinType: CurrencyType,
    playAmount: t.number,
    winAmount: t.number,
    winType: t.union(t.string, t.undefined),
    freeSpinCount: t.optional(t.number),
});

const GamingHistoryResponse = t.object({
    playHistoryCount: t.number,
    playHistory: t.array(PlayResult),
});

const BalanceResponse = t.object({
    goldBalance: t.number,
    sweepsBalance: t.number,
    sweepsAvailableBalance: t.number,
});

export const lobbySchema = createHttpSchema([
    createHttpRoute({
        method: 'POST',
        path: '/api/messages/delete-expired',
        paramNames: [],
        requestBody: t.object({}),
        responseBody: t.object({}),
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/jackpot-games',
        paramNames: [],
        requestBody: t.object({
            jackpotGames: t.array(JackpotGame),
        }),
        responseBody: t.object({
            jackpotGames: t.array(JackpotGame),
        }),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/jackpot-games',
        paramNames: [],
        responseBody: t.object({
            jackpotGames: t.array(JackpotGame),
        }),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/lobby/layout',
        paramNames: [],
        responseBody: t.union(LobbyLayoutResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/mini-games/layout',
        paramNames: [],
        responseBody: t.union(MiniGamesLayoutResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/healthcheck',
        paramNames: [],
        responseBody: t.object({
            status: t.union(t.unit('pass'), t.unit('fail')),
            releaseId: t.string,
            serviceId: t.string,
            description: t.string,
        }),
    }),
    createHttpRoute({
        method: 'GET',
        path: '*',
        paramNames: ['0'],
        responseBody: t.any,
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/logout',
        paramNames: [],
        responseBody: LogoutResponse,
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/me',
        paramNames: [],
        responseBody: t.union(UserResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/session',
        paramNames: [],
        responseBody: t.string,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/user-properties',
        paramNames: [],
        requestBody: t.object({
            properties: t.any,
        }),
        responseBody: t.union(UserPropertiesResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/recently-played',
        paramNames: [],
        requestBody: t.object({
            gameName: t.string,
        }),
        responseBody: t.object({
            success: t.boolean,
        }),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/recommendations',
        paramNames: [],
        responseBody: t.union(GameRecommendations, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/top-games',
        paramNames: [],
        responseBody: t.union(TopGamesResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/gaming-history',
        paramNames: [],
        responseBody: t.union(GamingHistoryResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/events/login-succeeded',
        paramNames: [],
        requestBody: t.object({
            userId: t.string,
        }),
    }),
    createHttpRoute({
        method: 'POST',
        path:'/api/save-user-preferences',
        paramNames: [],
        requestBody: UserPreferences,
        responseBody: t.union(UserPreferences, ErrorResponse),
    }),
    createHttpRoute({
        method: 'POST',
        path:'/api/terms-of-use/accept',
        paramNames: [],
        requestBody: AcceptedTermsOfUse,
        responseBody: t.object({
            success: t.boolean,
        }),
    }),
    createHttpRoute({
        method: 'GET',
        path:'/api/lobby-management/categories',
        paramNames: [],
        responseBody: t.union(LobbyCategoriesResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path:'/api/lobby-management/admin/categories',
        paramNames: [],
        responseBody: t.union(LobbyManagementCategoriesResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'GET',
        path: '/api/lobby-management/admin/games',
        paramNames: [],
        responseBody: t.union(LobbyManagementGamesResponse, ErrorResponse),
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/set-position',
        requestBody: SetLobbyManagementCategoryPosition,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/toggle-enabled',
        requestBody: SetLobbyManagementCategoryEnabled,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/add',
        requestBody: AddLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/update',
        requestBody: UpdateLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/game/add',
        requestBody: AddGameToLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/games/add',
        requestBody: AddGamesToLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/game/set-position',
        requestBody: SetLobbyManagementCategoryGamePosition,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/remove',
        requestBody: RemoveLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
    createHttpRoute({
        method: 'POST',
        path: '/api/lobby-management/admin/category/game/remove',
        requestBody: RemoveGameFromLobbyManagementCategory,
        paramNames: [],
        responseBody: ErrorResponse,
    }),
]);


