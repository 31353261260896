export const OPEN_STANDARD_PLAY_GAME = 'OPEN_STANDARD_PLAY_GAME';

type OpenStandardPlayGameModalProps = {
    shouldCloseOnOverlayClick: boolean;
    shouldCloseOnEsc: boolean;
    slug: string;
};

export const getOpenStandardPlayGameModalProps = ({
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    slug,
}: OpenStandardPlayGameModalProps) => {
    return {
        type: OPEN_STANDARD_PLAY_GAME,
        slug,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const;
};
