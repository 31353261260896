import { v4 as uuidv4 } from 'uuid';
import {
    LOAD_USER_CONFIG,
    LOAD_USER_PREFERENCES,
    SET_CURRENCY,
    SET_IS_USER_REVERIFY_BLOCKED,
    SET_LAST_ACTIVITY_BY_USER,
    SET_PLAY_ID,
    SET_RETURN_TO_GAME_ID,
    SET_SERVER_OFFSET,
    SET_SHOW_ACTIVITY_REMINDER_MODAL,
    SET_TMX_SESSION_ID,
    SET_USER_MESSAGES,
    SWITCH_CURRENCY,
    SystemState,
    UPDATE_USER_PREFERENCES,
    USER_INITIALISATION_COMPLETE,
} from './types';
import { Currency } from '@chu/http';
import { createReducer } from 'store/create';
import { CLEAR_GAME } from 'store/game/types';
import { AppAction } from '../app-action';
import { LOGIN_USER, LOGOUT_USER } from '../user/types';

export const initialState: SystemState = {
    userIsLoggedIn: false,
    currency: Currency.GOLD,
    clientSessionID: uuidv4(),
    serverOffset: new Date().getTimezoneOffset() * 60,
    playId: '',
    toggles: {},
    termsOfUse: undefined,
    lastActivityByUser: 0,
    messages: [],
    tmxSessionId: '',
    promotionalPlay: 'ENABLED',
    userPreferences: {
        version: 0,
        preferencesVersion: 0,
        warningExpiryTimeoutMs: 0,
    },
    returnToGameId: undefined,
    showActivityReminderModal: false,
    isUserInitialisationComplete: false,
    stateLocation: undefined,
    isUserTwoKBlocked: false,
    isUserReverifyBlocked: false,
    togglesLoaded: false,
    blockedFeatures: [],
    mustKyc: false,
};

export { reducer as systemReducer };

const { reducer, handle } = createReducer<AppAction, SystemState>(initialState);

handle(USER_INITIALISATION_COMPLETE, (state) => ({
    ...state,
    isUserInitialisationComplete: true,
}));

handle(LOGOUT_USER, (state) => ({ ...state, userIsLoggedIn: false }));

handle(LOGIN_USER, (state) => ({ ...state, userIsLoggedIn: true }));

handle(SET_CURRENCY, (state, action) => ({
    ...state,
    currency: state.promotionalPlay === 'DISABLED' ? Currency.GOLD : action.currency,
}));

handle(SWITCH_CURRENCY, (state) => ({
    ...state,
    currency:
        state.currency === Currency.SWEEPS || state.promotionalPlay === 'DISABLED' ? Currency.GOLD : Currency.SWEEPS,
}));

handle(SET_PLAY_ID, (state, action) => ({ ...state, playId: action.playId }));

handle(CLEAR_GAME, (state) => ({ ...state, playId: '' }));

handle(SET_SERVER_OFFSET, (state, action) => ({
    ...state,
    serverOffset: action.serverOffset,
}));

handle(LOAD_USER_CONFIG, (state, action) => {
    const {
        payload: {
            toggles,
            togglesLoaded,
            termsOfUse,
            promotionalPlay,
            isUserTwoKBlocked,
            stateLocation,
            blockedFeatures,
            mustKyc,
        },
    } = action;
    return {
        ...state,
        toggles,
        togglesLoaded,
        termsOfUse,
        promotionalPlay,
        isUserTwoKBlocked,
        stateLocation,
        blockedFeatures,
        mustKyc,
    };
});

handle(LOAD_USER_PREFERENCES, (state, action) => {
    return { ...state, userPreferences: action.userPreferences };
});

handle(SET_LAST_ACTIVITY_BY_USER, (state, action) => ({
    ...state,
    lastActivityByUser: action.timeEpochMs,
}));

handle(SET_USER_MESSAGES, (state, action) => ({
    ...state,
    messages: [...state.messages, ...action.payload],
}));

handle(SET_TMX_SESSION_ID, (state, action) => ({
    ...state,
    tmxSessionId: action.tmxSessionId,
}));

handle(UPDATE_USER_PREFERENCES, (state, action) => ({
    ...state,
    userPreferences: action.userPreferences,
}));

handle(SET_RETURN_TO_GAME_ID, (state, action) => ({
    ...state,
    returnToGameId: action.gameId,
}));

handle(SET_SHOW_ACTIVITY_REMINDER_MODAL, (state, action) => ({
    ...state,
    showActivityReminderModal: action.showActivityReminderModal,
}));

handle(SET_IS_USER_REVERIFY_BLOCKED, (state, action) => ({
    ...state,
    isUserReverifyBlocked: action.isUserReverifyBlocked,
}));
