import axios from 'axios';
import { logger } from '../log/logger';
import { PlayerFavoritesService } from './favorites-service-interface';
import { ClientConfig } from 'config/client-config';

export const playerFavoritesServiceImplementation = (): PlayerFavoritesService => {
    return {
        addFavorites: async (userId: number, gameId: string): Promise<void> => {
            try {
                await axios.post(
                    `${ClientConfig.PLAYER_ACCOUNT_URL}/favorites/${userId}/add`,
                    { gameId },
                    {
                        withCredentials: true,
                    }
                );
            } catch (error) {
                logger.error('Failed to add favorite', {
                    userId,
                    gameId,
                    error,
                });
            }
        },

        removeFavorites: async (userId: number, gameId: string): Promise<void> => {
            try {
                await axios.post(
                    `${ClientConfig.PLAYER_ACCOUNT_URL}/favorites/${userId}/remove`,
                    { gameId },
                    {
                        withCredentials: true,
                    }
                );
            } catch (error) {
                logger.error('Failed to remove favorite', {
                    userId,
                    gameId,
                    error,
                });
            }
        },

        getFavorites: async (userId: number): Promise<string[]> => {
            try {
                const { data } = await axios.get(`${ClientConfig.PLAYER_ACCOUNT_URL}/favorites/${userId}`, {
                    withCredentials: true,
                });
                return data.gameIds;
            } catch (error) {
                logger.error('Failed to get favorites', {
                    userId,
                    error,
                });
                return [];
            }
        },
    };
};
