import { MD5 } from 'crypto-js';
import { calculateMinAndMaxBuckets } from './calculate-min-and-max-buckets';
import { isPlayerIdInBucket } from './is-player-id-in-bucket';

// What is this?
// Random player distribution allows you to test a code change to a small audience, measure the impact, and then (if you wish to) progressively roll it out to all players.
// This is a useful tool for A/B testing (or any controlled experiment).

// ### Design Requirements ###

// 1) Must be able to select to an arbitrary percentage (or at least in divisions of 1%)
// 2) Must be able to increase the random distribution percentage without removing previous players from the rollout group
// 3) Randomised across groups to prevent contamination of measurements (if the same 5% of players was used across two groups, we wouldn't be able to tell which was the cause in change of player behaviour)
// 4) Downstream teams must be able to easily query the users in the group (i.e. via a query in Snowflake, it doesn't have to be a fast query!)
// 5) Easily overridable for testing purposes

// Bucket distribution example
// bucketPercentages: [10, 30, 60]
//      10%                  30%                                            60%                             <-- percentages
//       A                    B                                              C                              <-- example groups
// |----------|------------------------------|------------------------------------------------------------|

export interface RandomPlayerDistributionParams {
    bucketPercentages: number[];
    seed: string;
}

export interface IsPlayerInBucket {
    (playerId: number): boolean;
}

export const createRandomPlayerDistribution = (params: RandomPlayerDistributionParams): IsPlayerInBucket[] => {
    const { bucketPercentages, seed } = params;

    const percentageSum = bucketPercentages.reduce((sum, percentage) => sum + percentage, 0);

    if (percentageSum != 100) {
        throw Error('Bucket percentages must sum up to 100');
    }

    if (seed.length !== 36) {
        throw Error('seed must be a UUID');
    }

    return calculateMinAndMaxBuckets(bucketPercentages).map(({ min, max }) => {
        return (playerId: number) => {
            return isPlayerIdInBucket({
                playerId,
                seed,
                bucketPercentageMin: min,
                bucketPercentageMax: max,
                hashFunction: (message) => MD5(message).toString(),
            });
        };
    });
};

// Example Postgres Query
// We are querying with a seed of 'b5d0103f-df94-4629-bd46-bb10c0659570' and a single bucket of 10%
/*
WITH RolloutCalculation AS (
    SELECT
        user_id,
        ('x' || LEFT(MD5(user_id || '-' || 'b5d0103f-df94-4629-bd46-bb10c0659570'), 8))::bit(32)::bigint / 4294967295.0 AS numberBetweenZeroAndOne
    FROM
        chumba_casino.user
)
SELECT user_id FROM RolloutCalculation WHERE numberBetweenZeroAndOne < 0.1;
*/
