import { FeatureBlock } from '@chu/http';

export type PlayerAccountBlockedFeatures = {
    restrictions: FeatureBlock[];
};

export type PlayerAccountRestrictionsResponse = {
    restrictions: PlayerAccountRestriction[];
};

export enum PlayerAccountRestriction {
    /** Player cannot purchase anything */
    PURCHASES = `PURCHASES`,
    /** Player can no longer access promotional offers */
    PROMOTIONAL_OFFERS = `PROMOTIONAL_OFFERS`,
    /** Player can not claim the daily bonus */
    DAILY_BONUS = `DAILY_BONUS`,
    /** Player can no longer enter alternate methods of entry to sweepstakes */
    ALTERNATE_METHODS_OF_ENTRY = `ALTERNATE_METHODS_OF_ENTRY`,
    /** Player can no longer use meta features such as Challenges, Quests etc. */
    META_FEATURES = `META_FEATURES`,
    /** Player can not play any games */
    GAMES = `GAMES`,
    /** Player can not initiate redemptions */
    REDEMPTIONS = `REDEMPTIONS`,
    /** Player can not participate in sweeps coin play */
    SWEEPS_COINS_PLAY = `SWEEPS_COINS_PLAY`,
    /** Player can not participate in gold coin play */
    GOLD_COINS_PLAY = `GOLD_COINS_PLAY`,
    /** Player can no longer access the lobby */
    LOBBY_ACCESS = `LOBBY_ACCESS`,
}
