import { v4 as uuidv4 } from 'uuid';
import { StoreAction } from './actions';
import { StoreState } from './types';

export const initState: StoreState = {
    cashierId: 0,
    cashierType: 'REGULAR',
    numberOfPurchases: 0,
    purchaseCountLimit: 0,
    fetchingOfferPackages: true,
    fetchingStorePackages: true,
    specialOffers: [],
    packages: [],
    userId: 0,
    // clickStream
    storeSessionId: `store-${uuidv4()}`,
};

export function storeReducer(state: StoreState = initState, action: StoreAction): StoreState {
    switch (action.type) {
        case 'PACKAGE_RECEIVE_OFFERS':
            return {
                ...state,
                specialOffers: action.packages,
                fetchingOfferPackages: false,
            };

        case 'SET_STORE_DATA': {
            return {
                ...state,
                ...action.cashierData,
            };
        }

        case 'SET_SEGMENTED_STORE': {
            return {
                ...state,
                segmentedStore: action.segmentedStore,
            };
        }

        case 'CLEAR_STORE_DATA': {
            return {
                ...initState,
                storeSessionId: state.storeSessionId,
                specialOffers: state.specialOffers,
                segmentedStore: state.segmentedStore,
            };
        }

        case 'SET_NEW_STORE_SESSION_ID': {
            return {
                ...state,
                storeSessionId: action.storeSessionId,
            };
        }

        default: {
            return state;
        }
    }
}
