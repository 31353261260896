import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'helpers/logout';
import { hideModal } from '../../../../../store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { MARKET_EXIT_REVERIFY } from '../modal-prop-types';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { MarketExitReverifyModalActions } from './market-exit-reverify-modal-actions';
import { ReactComponent as ExclamationIcon } from '../../../2k-verification/exclamation-icon.svg';

import './market-exit-reverify-modal.scss';

export const MarketExitReverifyModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const id: GenericModalTypes = MARKET_EXIT_REVERIFY;
    const modalName = 'market-exit-reverify-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <ExclamationIcon height='20%' width='20%' />
            <h2 className={`${modalName}__header`}>Reverify your account</h2>
            <p className={`${modalName}__content`}>
                As part of our terms and conditions, we occasionally require customers to reverify their account for
                security purposes. You will not be able to redeem until your account is verified.
            </p>
            <MarketExitReverifyModalActions handleLogOut={logout} handleVerify={handleVerify} modalName={modalName} />
        </GenericModalLayout>
    );

    function handleVerify() {
        dispatch(hideModal(MARKET_EXIT_REVERIFY));
        history.push('/account-verification');
    }
};

export const getMarketExitReverifyModalProps = () =>
    ({ type: MARKET_EXIT_REVERIFY, shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false } as const);
