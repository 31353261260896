import { Action } from 'redux';
import { Channel } from '@chu/http/src';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_GOLD_BALANCE = 'UPDATE_GOLD_BALANCE';
export const UPDATE_SWEEPS_BALANCE = 'UPDATE_SWEEPS_BALANCE';
export const SYNC_USER_TOKEN_BALANCE = 'SYNC_USER_TOKEN_BALANCE';
export const SAVE_USER_PROPERTIES = 'SAVE_USER_PROPERTIES';
export const UPDATE_USER_VERIFICATION_STATUS = 'UPDATE_USER_VERIFICATION_STATUS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_FOR_GTM_EVENT = 'LOGIN_USER_FOR_GTM_EVENT';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGGED_IN = 'LOGGED_IN';
export const REGISTERED = 'REGISTERED';
export const PURCHASED = 'PURCHASED';

export enum UserVerificationStatus {
    PENDING = 0,
    ACCEPT = 1,
    REJECT = 2,
    VERIFIED = 3,
    NOT_YET_VERIFIED = 4,
    REVERIFY = 5,
}
export type VerificationStatusTypes = 'PENDING' | 'ACCEPT' | 'REJECT' | 'VERIFIED' | 'NOT_YET_VERIFIED' | 'REVERIFY';
export type DueDiligenceStatus = VerificationStatusTypes | 'SDD_ACCEPT';
export interface User {
    userId: number;
    username: string;
    firstname: string;
    lastname: string;
    isStaff: boolean;
    isFirstSession: boolean;
    goldBalance: number;
    sweepsBalance: number;
    properties: any;
    channel: 'FACEBOOK' | 'STANDALONE' | 'GOOGLE' | undefined;
    registrationTimestamp: string | undefined;
    brazeToken?: string;
}

// Describing the shape of the users slice of state
export interface UserState extends User {
    verificationStatus: UserVerificationStatus;
}

export interface UpdateUserAction extends Action {
    type: typeof UPDATE_USER;
    payload: UserState;
}

export interface UpdateGoldBalanceAction extends Action {
    type: typeof UPDATE_GOLD_BALANCE;
    balance: number;
}
export interface UpdateSweepsBalanceAction extends Action {
    type: typeof UPDATE_SWEEPS_BALANCE;
    balance: number;
}

export interface SaveUserProperties extends Action {
    type: typeof SAVE_USER_PROPERTIES;
    payload: {
        properties: any;
    };
}

export interface UpdateUserVerificationStatus extends Action {
    type: typeof UPDATE_USER_VERIFICATION_STATUS;
    status: UserVerificationStatus;
}

export interface LoginUserAction extends Action {
    type: typeof LOGIN_USER;
    payload: User;
}

export interface LoginUserForGTM extends Action {
    type: typeof LOGIN_USER_FOR_GTM_EVENT;
    payload: {
        email: string;
        accountId: number;
    };
}

export interface LogoutUserAction extends Action {
    type: typeof LOGOUT_USER;
}

export interface SyncUserTokenBalanceAction extends Action {
    type: typeof SYNC_USER_TOKEN_BALANCE;
}

interface Registered {
    type: typeof REGISTERED;
    channel: Channel;
}

interface LoggedIn {
    type: typeof LOGGED_IN;
    channel: Channel | undefined;
}

export interface Purchased {
    type: typeof PURCHASED;
    packageName: string;
    usdAmount: number;
    isFirstPurchase: boolean;
    packageId: string;
    transactionId: string;
    channelIdentification: string;
}

export type UserAction =
    | UpdateUserAction
    | UpdateGoldBalanceAction
    | UpdateSweepsBalanceAction
    | LoginUserAction
    | SaveUserProperties
    | UpdateUserVerificationStatus
    | Registered
    | Purchased
    | LoggedIn
    | LoginUserAction
    | LoginUserForGTM
    | LogoutUserAction
    | SyncUserTokenBalanceAction;
