import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { GENERIC_MODAL, GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { MarketExitNotYetVerifiedModalActions } from './market-exit-not-yet-verified-modal-actions';
import { MARKET_EXIT_NOT_YET_VERIFIED } from '../modal-prop-types';
import { logout } from 'helpers/logout';
import { ReactComponent as ExclamationIcon } from '../../../2k-verification/exclamation-icon.svg';

import './market-exit-not-yet-verified-modal.scss';

export const MarketExitNotYetVerifiedModal = () => {
    const dispatch = useDispatch();
    const id: GenericModalTypes = MARKET_EXIT_NOT_YET_VERIFIED;
    const modalName = 'market-exit-not-yet-verified-modal';

    return (
        <GenericModalLayout id={id} data-testid={modalName} classExtra={modalName} xIcon={false}>
            <ExclamationIcon height='20%' width='20%' />
            <h2 className={`${modalName}__header`}>Verification required</h2>
            <p className={`${modalName}__content`}>
                We need to confirm your residential address by verifying your account. You won’t be able to access
                Chumba until your account is verified.
            </p>
            <MarketExitNotYetVerifiedModalActions
                handleLogOut={logout}
                handleVerify={handleVerify}
                modalName={modalName}
            />
        </GenericModalLayout>
    );

    function handleVerify() {
        dispatch(hideModal(MARKET_EXIT_NOT_YET_VERIFIED));
        dispatch(hideModal(GENERIC_MODAL));
        window.location.href = '/account-verification';
    }
};

export const getMarketExitNotYetVerifiedModalProps = (shouldCloseOnOverlayClick = false, shouldCloseOnEsc = false) =>
    ({ type: MARKET_EXIT_NOT_YET_VERIFIED, shouldCloseOnOverlayClick, shouldCloseOnEsc } as const);
