import { TrackedUserAction, sendToClickStream } from './user-actions';
import { isMobile } from 'helpers/device';
import {
    D__LOBBY__SELECT_MACHINE,
    D__LOBBY_LOADED,
    D__TWO_K_VERIFY__CONTINUE_PLAYING,
    D__TWO_K_VERIFY__CROSS,
    D__TWO_K_VERIFY__VERIFY_NOW,
    D__TWO_K_VERIFY__VIEWED,
    D__TWO_K_VERIFY__ZENDESK_LINK,
    DESKTOP,
    DeviceType,
    M__LOBBY__SELECT_MACHINE,
    M__LOBBY_LOADED,
    M__ROTATE_DEVICE__CLOSE,
    M__ROTATE_DEVICE__OPEN,
    M__TWO_K_VERIFY__CONTINUE_PLAYING,
    M__TWO_K_VERIFY__CROSS,
    M__TWO_K_VERIFY__VERIFY_NOW,
    M__TWO_K_VERIFY__VIEWED,
    M__TWO_K_VERIFY__ZENDESK_LINK,
    MOBILE,
    SWIPE_UP_DIALOG_OFF,
    SWIPE_UP_DIALOG_ON,
    HUD_CURRENCY_SWITCH,
    HUD_CURRENCY_INFO,
    HUD_HOME_LOGO_CLICKED,
    HUD_SIDE_BAR_TOGGLED,
    HUD_BUY_CLICKED,
    LOGIN_SUCCESS,
    NOTIFICATION_TRAY,
    OPEN,
    CLOSED,
    MODAL_SHOWN,
    MODAL_SELECTED,
    MODAL_CLOSED,
    CATEGORY_SELECTED,
    REDEEM_SELECTED,
    STORE_LOADED,
    ACTIVITY_REMINDER_MODAL_INFO_SELECTED,
    ACTIVITY_REMINDER_MODAL_GC_SELECTED,
    ACTIVITY_REMINDER_MODAL_SC_SELECTED,
    ACTIVITY_REMINDER_MODAL_CONTINUE_SELECTED,
    ACTIVITY_REMINDER_MODAL_LOGOUT_SELECTED,
    ACTIVITY_REMINDER_MODAL_ADJUST_REMINDERS_SELECTED,
    ACTIVITY_REMINDER_MODAL_GAMEPLAY_HISTORY_SELECTED,
    ACTIVITY_REMINDER_PAGE_SELECTED,
    ACTIVITY_REMINDER_CHANGE_SELECTED,
    ACTIVITY_REMINDER_INTERVAL_SELECTED,
    ACTIVITY_REMINDER_PAGE_CLOSE_SELECTED,
    ACTIVITY_REMINDER_PAGE_CANCEL_SELECTED,
    ACTIVITY_REMINDER_SET_INTERVAL_SELECTED,
    ACTIVITY_REMINDER_UNSAVED_CHANGES_LEAVE_SELECTED,
    ACTIVITY_REMINDER_UNSAVED_CHANGES_CANCEL_SELECTED,
    ACTIVITY_REMINDER_ERROR_LEAVE_SELECTED,
    ACTIVITY_REMINDER_ERROR_CANCEL_SELECTED,
    TAP_TO_FULLSCREEN_MODAL_SHOWN,
    TAP_TO_FULLSCREEN_MODAL_CLOSED,
    GC_TOP_UP_CLAIM,
    GC_TOP_UP_CLAIM_IN_GAME,
    GAME_FAVORITE_EVENT,
    STORE_PLAYFORFREE_DAILY_BONUS_MOREINFO,
    STORE_PLAYFORFREE_FACEBOOK_FOLLOW,
    STORE_PLAYFORFREE_FREE_GC_MOREINFO,
    STORE_PLAYFORFREE_POSTAL_REQUEST_MOREINFO,
    SEARCH_OPENED,
    SEARCH_CANCELLED,
    SEARCH_NO_RESULT_FOUND,
    TERM_SEARCHED,
    SEARCH_RESULT_GAME_CLICKED,
    DYNAMIC_CATEGORY_GAME_LAUNCHED,
    DYNAMIC_CATEGORY_TOOLTIP_CLICKED,
    QUEST_GAME_LAUNCHED,
} from './types';
import { ANALYTICS } from 'chumba/third-party-types';
import { mapMachineActionNames } from './machine-actions';
import { CurrencyTypeText } from '@chu/http';

type ActionTrackingEvent = (deviceType?: typeof MOBILE | typeof DESKTOP) => Promise<void>;

type SimpleActionCreator = (mobileAction: string, desktopAction: string) => ActionTrackingEvent;

const createSimpleAction: SimpleActionCreator = (mobileAction, desktopAction) => (deviceType) =>
    sendToClickStream({
        actionName: (deviceType || getDeviceType()) === MOBILE ? mobileAction : desktopAction,
        parameter0: null,
        parameter1: null,
        time: Date.now(),
    });

export const trackSOWBlockedFindOutMoreAction = createSimpleAction(M__LOBBY_LOADED, D__LOBBY_LOADED);
export const trackTwoKContinuePlayingAction = createSimpleAction(
    M__TWO_K_VERIFY__CONTINUE_PLAYING,
    D__TWO_K_VERIFY__CONTINUE_PLAYING
);
export const trackTwoKVerifyNowAction = createSimpleAction(M__TWO_K_VERIFY__VERIFY_NOW, D__TWO_K_VERIFY__VERIFY_NOW);
export const trackTwoKZendeskLinkAction = createSimpleAction(
    M__TWO_K_VERIFY__ZENDESK_LINK,
    D__TWO_K_VERIFY__ZENDESK_LINK
);
export const trackTwoKViewedAction = createSimpleAction(M__TWO_K_VERIFY__VIEWED, D__TWO_K_VERIFY__VIEWED);
export const trackTwoKCrossAction = createSimpleAction(M__TWO_K_VERIFY__CROSS, D__TWO_K_VERIFY__CROSS);

export function trackLobbyViewedAction(loadTime: number) {
    sendToClickStream({
        actionName: isMobile() ? M__LOBBY_LOADED : D__LOBBY_LOADED,
        loadTime,
    });
}

export function trackMachineSelectAction(deviceType: DeviceType, gameName: string, listName?: string) {
    const machineSelectAction: TrackedUserAction = {
        actionName: deviceType === MOBILE ? M__LOBBY__SELECT_MACHINE : D__LOBBY__SELECT_MACHINE,
        parameter0: gameName,
        parameter1: listName || null,
    };
    sendToClickStream(machineSelectAction);
}

export function trackGameFavoriteEvent(isFavorite: boolean, gameName: string, listName?: string) {
    sendToClickStream({
        actionName: GAME_FAVORITE_EVENT,
        event: {
            isFavorite,
            gameName,
            listName,
        },
    });
}

export function trackShowSwitchToLandscape(gameName: string) {
    sendToClickStream({
        actionName: M__ROTATE_DEVICE__OPEN,
        parameter0: gameName,
        parameter1: null,
    });
}

export function trackMachineAction(message: ANALYTICS) {
    const actionName = mapMachineActionNames(message.fcn, getDeviceType());
    if (actionName === undefined) {
        return;
    }
    sendToClickStream({
        actionName,
        parameter0: message.p0 ? message.p0.toString() : null,
        parameter1: message.p1 ? message.p1.toString() : null,
    });
}

export function trackCloseSwitchToLandscape(gameName: string) {
    sendToClickStream({
        actionName: M__ROTATE_DEVICE__CLOSE,
        parameter0: gameName,
        parameter1: null,
    });
}

export function trackSwipeToFullscreenModalShown() {
    sendToClickStream({
        actionName: SWIPE_UP_DIALOG_ON,
    });
}

export function trackSwipeToFullscreenModalClosed() {
    sendToClickStream({
        actionName: SWIPE_UP_DIALOG_OFF,
    });
}

export function trackTapToFullscreenModalShown() {
    sendToClickStream({
        actionName: TAP_TO_FULLSCREEN_MODAL_SHOWN,
    });
}

export function trackTapToFullscreenModalClosed() {
    sendToClickStream({
        actionName: TAP_TO_FULLSCREEN_MODAL_CLOSED,
    });
}

export function trackHudCurrencySwitch(currency: CurrencyTypeText, source: string) {
    sendToClickStream({
        actionName: HUD_CURRENCY_SWITCH,
        currency,
        source,
    });
}

export function trackHudCurrencyInfo() {
    sendToClickStream({
        actionName: HUD_CURRENCY_INFO,
    });
}

export function trackHudHomeLogoClicked() {
    sendToClickStream({
        actionName: HUD_HOME_LOGO_CLICKED,
    });
}

export function trackHudSideBarToggled() {
    sendToClickStream({
        actionName: HUD_SIDE_BAR_TOGGLED,
    });
}

export function trackHudBuyButtonClicked(source: string) {
    sendToClickStream({
        actionName: HUD_BUY_CLICKED,
        source,
    });
}

export function trackUserLoggedIn() {
    sendToClickStream({
        actionName: LOGIN_SUCCESS,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        windowDevicePixelRatio: window.devicePixelRatio,
    });
}

export function trackNotificationTray(userId: number, isTrayOpen: boolean) {
    const state = isTrayOpen ? OPEN : CLOSED;

    sendToClickStream({
        actionName: NOTIFICATION_TRAY,
        state,
        userId,
    });
}

export function trackModalShown<T>(modalName: string, payload?: T) {
    sendToClickStream({
        actionName: MODAL_SHOWN,
        modalName,
        urlPath: window.location.pathname,
        ...payload,
    });
}

export function trackModalSelected<T>(modalName: string, payload?: T) {
    sendToClickStream({
        actionName: MODAL_SELECTED,
        modalName,
        urlPath: window.location.pathname,
        ...payload,
    });
}

export function trackModalClosed<T>(modalName: string, payload?: T) {
    sendToClickStream({
        actionName: MODAL_CLOSED,
        modalName,
        urlPath: window.location.pathname,
        ...payload,
    });
}

export function trackCategorySelected(categoryName: string, source: string) {
    sendToClickStream({
        actionName: CATEGORY_SELECTED,
        categoryName,
        source,
    });
}

export function trackRedeemSelected() {
    sendToClickStream({
        actionName: REDEEM_SELECTED,
    });
}

export function getDeviceType(): DeviceType {
    // TODO memoize this function?
    return isMobile() ? MOBILE : DESKTOP;
}

export function trackStoreLoaded(userId: number, time: number) {
    sendToClickStream({
        actionName: STORE_LOADED,
        userId,
        parameter0: time,
    });
}

export function trackActivityReminderModalInfoSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_INFO_SELECTED,
    });
}

export function trackActivityReminderModalGCSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_GC_SELECTED,
    });
}

export function trackActivityReminderModalSCSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_SC_SELECTED,
    });
}

export function trackActivityReminderModalContinueSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_CONTINUE_SELECTED,
    });
}

export function trackActivityReminderModalLogoutSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_LOGOUT_SELECTED,
    });
}

export function trackActivityReminderModalAdjustRemindersSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_ADJUST_REMINDERS_SELECTED,
    });
}

export function trackActivityReminderModalGameplayHistorySelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_MODAL_GAMEPLAY_HISTORY_SELECTED,
    });
}

export function trackActivityReminderPageSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_PAGE_SELECTED,
    });
}

export function trackActivityReminderChangeSelected(currentValue: number) {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_CHANGE_SELECTED,
        currentValue,
    });
}

export function trackActivityReminderNewIntervalSelected(value: number) {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_INTERVAL_SELECTED,
        value,
    });
}

export function trackActivityReminderPageCloseSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_PAGE_CLOSE_SELECTED,
    });
}

export function trackActivityReminderPageCancelSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_PAGE_CANCEL_SELECTED,
    });
}

export function trackActivityReminderSetIntervalSelected(value: number) {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_SET_INTERVAL_SELECTED,
        value,
    });
}

export function trackActivityReminderUnsavedChangesLeaveSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_UNSAVED_CHANGES_LEAVE_SELECTED,
    });
}

export function trackActivityReminderUnsavedChangesCancelSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_UNSAVED_CHANGES_CANCEL_SELECTED,
    });
}

export function trackActivityReminderErrorLeaveSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_ERROR_LEAVE_SELECTED,
    });
}

export function trackActivityReminderErrorCancelSelected() {
    sendToClickStream({
        actionName: ACTIVITY_REMINDER_ERROR_CANCEL_SELECTED,
    });
}

export function trackGcTopupClaim(amount: number, error?: string) {
    sendToClickStream({
        actionName: GC_TOP_UP_CLAIM,
        urlPath: window.location.pathname,
        amount,
        isSuccessful: !error,
        error,
    });
}

export function trackGcTopupClaimInGame(amount: number, error?: string) {
    sendToClickStream({
        actionName: GC_TOP_UP_CLAIM_IN_GAME,
        urlPath: window.location.pathname,
        amount,
        isSuccessful: !error,
        error,
    });
}

export function trackFreePlayActionLogin() {
    sendToClickStream({
        actionName: STORE_PLAYFORFREE_DAILY_BONUS_MOREINFO,
    });
}

export function trackFreePlayActionFacebookGiveAway() {
    sendToClickStream({
        actionName: STORE_PLAYFORFREE_FACEBOOK_FOLLOW,
    });
}

export function trackFreePlayActionRequestByPost() {
    sendToClickStream({
        actionName: STORE_PLAYFORFREE_POSTAL_REQUEST_MOREINFO,
    });
}

export function trackFreePlayActionGCTopUp() {
    sendToClickStream({
        actionName: STORE_PLAYFORFREE_FREE_GC_MOREINFO,
    });
}

//Search events for click stream

export type SearchInputFromType = 'searchbar' | 'recent';
export type SearchClickFromType = 'search_recommended' | 'search_list';

export function trackSearchOpened(stateLocation: string | undefined, searchSessionId: string | null) {
    sendToClickStream({
        actionName: SEARCH_OPENED,
        searchSessionId,
        stateLocation,
    });
}

export function trackSearchTermSearched(
    stateLocation: string | undefined,
    searchSessionId: string | null,
    input: string,
    from: SearchInputFromType
) {
    sendToClickStream({
        actionName: TERM_SEARCHED,
        input,
        from,
        searchSessionId,
        stateLocation,
    });
}

export function trackSearchGameClicked(
    stateLocation: string | undefined,
    searchSessionId: string | null,
    gameId: string,
    from: SearchClickFromType
) {
    sendToClickStream({
        actionName: SEARCH_RESULT_GAME_CLICKED,
        gameId,
        from,
        searchSessionId,
        stateLocation,
    });
}

export function trackSearchNotResultFound(
    stateLocation: string | undefined,
    searchSessionId: string | null,
    input: string
) {
    sendToClickStream({
        actionName: SEARCH_NO_RESULT_FOUND,
        input,
        searchSessionId,
        stateLocation,
    });
}

export function trackSearchCancelled(stateLocation: string | undefined, searchSessionId: string | null, input: string) {
    sendToClickStream({
        actionName: SEARCH_CANCELLED,
        input,
        searchSessionId,
        stateLocation,
    });
}

//Dynamic category events for click stream
export function trackGameInCategoryLaunched(
    categoryId: string | undefined,
    categoryTitle: string,
    iconRef: string | undefined,
    gameId: string
) {
    sendToClickStream({
        actionName: DYNAMIC_CATEGORY_GAME_LAUNCHED,
        categoryId,
        categoryTitle,
        iconRef,
        gameId,
    });
}

export function trackTooltipInGameCategoryClicked(
    categoryId: string | undefined,
    categoryTitle: string,
    iconRef: string | undefined,
    linkTo: string | undefined
) {
    sendToClickStream({
        actionName: DYNAMIC_CATEGORY_TOOLTIP_CLICKED,
        categoryId,
        categoryTitle,
        iconRef,
        linkTo,
    });
}

export function trackGameInQuestsLaunched(questId: string, gameId: string) {
    sendToClickStream({
        actionName: QUEST_GAME_LAUNCHED,
        questId: questId,
        gameId: gameId,
    });
}
