interface Config {
    VERIFICATION_COOKIE_NAME: string;
    REQUEST_PATH_HOST_SEGMENT: string;
    CHUMBA_GAME_ENVIRONMENT: 'prod' | 'dev';
    USE_CONSOLE_APPENDER: boolean;

    // chu-store
    BASE_STORE_API_URL: string;

    // chu-server
    CASINO_URL: string;
    WS_URL: string;

    // chu-purchase
    PURCHASE_URL: string;

    // chu-offers
    OFFERS_URL: string;

    // chu-auth
    LOGIN_URL: string;

    // chu-games
    GAME_SERVER_BASE_URL: string;
    GAMES_MANAGEMENT_URL: string;
    GAME_SERVER_CDN_URL: string;
    LEGACY_GAMES_CLIENTS_URL: string;
    GOLDEN_FEATHER_CDN_URL: string;

    // gas
    GAS_JACKPOT_BALANCE_URL: string;

    // chu-redeem
    REDEEM_URL: string;

    // chu-challenges
    CHALLENGE_URL: string;

    // chu-ris
    CHU_RIS_URL: string;
    CHU_RIS_CDN_URL: string;
    PLAYTECH_URL: string;

    // chu-player-account
    PLAYER_ACCOUNT_URL: string;

    // chu-player-token
    PLAYER_TOKEN_URL: string;

    // chu-customer
    CUSTOMER_API_URL: string;

    // chu-rsg
    RSG_URL: string;
    GAP_IFRAME_URL: string;

    // braze
    BRAZE_SDK_URL: string;
    BRAZE_CLIENT_ID: string;

    // clickstream
    CLICKSTREAM_URL: string;

    // Threatmetrix
    TMX_URL: string;
    TMX_ORG_ID: string;

    // Zendesk
    ZENDESK_SUBDOMAIN: string;

    // Quests
    BASE_QUESTS_API_URL: string;

    // Lobby Constants
    CONSENT_URL: string;
}

const configs: { local: Config; dev: Config; test: Config; prod: Config } = {
    local: {
        VERIFICATION_COOKIE_NAME: 'VGWverification-dev',
        REQUEST_PATH_HOST_SEGMENT: 'http://localhost:8806',
        CHUMBA_GAME_ENVIRONMENT: 'dev',
        USE_CONSOLE_APPENDER: true,
        BASE_STORE_API_URL: 'http://localhost:11006/store',
        CASINO_URL: 'http://localhost:8080/chumba',
        PURCHASE_URL: 'http://localhost:8803',
        WS_URL: 'ws://localhost:8080',
        OFFERS_URL: 'http://localhost:8801',
        LOGIN_URL: 'http://localhost:8804',
        GAME_SERVER_BASE_URL: 'http://localhost:8800',
        GAMES_MANAGEMENT_URL: 'http://localhost:9176/jackpots',
        GAS_JACKPOT_BALANCE_URL: 'https://api.gap-dev.vgwgames.com/gas/jackpots',
        GAME_SERVER_CDN_URL: 'https://gameserver.chumbacasino.com/clients',
        LEGACY_GAMES_CLIENTS_URL: 'https://gameserver.chumbacasino.com/legacy-clients',
        GOLDEN_FEATHER_CDN_URL: 'https://gameserver.dev.chumbacasino.com/clients',
        REDEEM_URL: 'http://localhost:8802',
        CHALLENGE_URL: 'http://localhost:8807',
        CHU_RIS_URL: 'http://localhost:8080',
        CHU_RIS_CDN_URL: 'https://gameserver.chumbacasino.com/clients/chu-ris',
        PLAYTECH_URL: 'https://extstg3-login01.ptstaging.eu',
        PLAYER_ACCOUNT_URL: 'http://localhost:11005/player-account',
        PLAYER_TOKEN_URL: 'http://localhost:11002/player-token/v1',
        CUSTOMER_API_URL: 'http://localhost:8808/customer',
        RSG_URL: 'http://localhost:11003/responsible-social-gameplay',
        GAP_IFRAME_URL: 'http://localhost:5173/gap-iframe',
        BRAZE_SDK_URL: 'sdk.iad-01.braze.com',
        BRAZE_CLIENT_ID: 'b24d9681-bad9-4b6a-8571-8b8c03484bdd',
        CLICKSTREAM_URL: 'https://clickstream.dev.chumbacasino.com',
        TMX_URL: 'https://cdn-s.dev.chumbacasino.com',
        TMX_ORG_ID: '6ydwjlgq',
        ZENDESK_SUBDOMAIN: 'chumbacasino1625100997',
        BASE_QUESTS_API_URL: 'http://localhost:8820/chu-quests',
        CONSENT_URL: '',
    },
    dev: {
        VERIFICATION_COOKIE_NAME: 'VGWverification-dev',
        REQUEST_PATH_HOST_SEGMENT: '',
        CHUMBA_GAME_ENVIRONMENT: 'dev',
        USE_CONSOLE_APPENDER: true,
        BASE_STORE_API_URL: 'https://api.dev.chumbacasino.com/store',
        CASINO_URL: 'https://casino-devb.dev.chumbacasino.com/chumba',
        PURCHASE_URL: 'https://purchase-test.dev.chumbacasino.com',
        WS_URL: 'wss://casino-devb.dev.chumbacasino.com',
        OFFERS_URL: 'https://offers-dev.dev.chumbacasino.com',
        LOGIN_URL: 'https://auth-dev.dev.chumbacasino.com',
        GAME_SERVER_BASE_URL: 'https://gameserver.dev.chumbacasino.com',
        GAMES_MANAGEMENT_URL: 'https://gameserver.dev.chumbacasino.com/services/games-management/jackpots',
        GAS_JACKPOT_BALANCE_URL: 'https://api.gap-dev.vgwgames.com/gas/jackpots',
        GAME_SERVER_CDN_URL: 'https://gameserver.dev.chumbacasino.com/clients',
        LEGACY_GAMES_CLIENTS_URL: 'https://gameserver.dev.chumbacasino.com/legacy-clients',
        GOLDEN_FEATHER_CDN_URL: 'https://gameserver.dev.chumbacasino.com/clients',
        REDEEM_URL: 'https://redeem-dev.dev.chumbacasino.com',
        CHALLENGE_URL: 'https://challenge-dev.dev.chumbacasino.com',
        CHU_RIS_URL: 'https://external-games-test.dev.chumbacasino.com',
        CHU_RIS_CDN_URL: 'https://gameserver.dev.chumbacasino.com/clients/chu-ris',
        PLAYTECH_URL: 'https://extstg3-login01.ptstaging.eu',
        PLAYER_ACCOUNT_URL: 'https://api.dev.chumbacasino.com/player-account',
        PLAYER_TOKEN_URL: 'https://api.dev.chumbacasino.com/player-token/v1',
        CUSTOMER_API_URL: 'https://api.dev.chumbacasino.com/customer',
        RSG_URL: 'https://api.dev.chumbacasino.com/responsible-social-gameplay',
        GAP_IFRAME_URL: 'https://clients.gap-dev.vgwgames.com/gap-iframe',
        BRAZE_SDK_URL: 'sdk.iad-01.braze.com',
        BRAZE_CLIENT_ID: 'b24d9681-bad9-4b6a-8571-8b8c03484bdd',
        CLICKSTREAM_URL: 'https://clickstream.dev.chumbacasino.com',
        TMX_URL: 'https://cdn-s.dev.chumbacasino.com',
        TMX_ORG_ID: '6ydwjlgq',
        ZENDESK_SUBDOMAIN: 'chumbacasino1625100997',
        BASE_QUESTS_API_URL: 'https://api.dev.chumbacasino.com/chu-quests',
        CONSENT_URL: 'https://pipelines.dev.vgw.marketing/device/set-consent',
    },
    test: {
        VERIFICATION_COOKIE_NAME: 'VGWverification-dev',
        REQUEST_PATH_HOST_SEGMENT: '',
        CHUMBA_GAME_ENVIRONMENT: 'dev',
        USE_CONSOLE_APPENDER: true,
        BASE_STORE_API_URL: 'https://api-test.dev.chumbacasino.com/store',
        CASINO_URL: 'https://casino-test.dev.chumbacasino.com/chumba',
        PURCHASE_URL: 'https://purchase-test.dev.chumbacasino.com',
        WS_URL: 'wss://casino-test.dev.chumbacasino.com',
        OFFERS_URL: 'https://offers-test.dev.chumbacasino.com',
        LOGIN_URL: 'https://auth-test.dev.chumbacasino.com',
        GAME_SERVER_BASE_URL: 'https://gameserver-test.dev.chumbacasino.com',
        GAMES_MANAGEMENT_URL: 'https://gameserver-test.dev.chumbacasino.com/services/games-management/jackpots',
        GAS_JACKPOT_BALANCE_URL: 'https://api.gap-dev.vgwgames.com/gas/jackpots',
        GAME_SERVER_CDN_URL: 'https://gameserver-test.dev.chumbacasino.com/clients',
        LEGACY_GAMES_CLIENTS_URL: 'https://gameserver-test.dev.chumbacasino.com/legacy-clients',
        GOLDEN_FEATHER_CDN_URL: 'https://gameserver-test.dev.chumbacasino.com/clients',
        REDEEM_URL: 'https://redeem-test.dev.chumbacasino.com',
        CHALLENGE_URL: 'https://challenge-test.dev.chumbacasino.com',
        CHU_RIS_URL: 'https://external-games-test.dev.chumbacasino.com',
        CHU_RIS_CDN_URL: 'https://gameserver.chumbacasino.com/clients/chu-ris',
        PLAYTECH_URL: 'https://extstg3-login01.ptstaging.eu',
        PLAYER_ACCOUNT_URL: 'https://api-test.dev.chumbacasino.com/player-account',
        PLAYER_TOKEN_URL: 'https://api-test.dev.chumbacasino.com/player-token/v1',
        CUSTOMER_API_URL: 'https://api-test.dev.chumbacasino.com/customer',
        RSG_URL: 'https://api-test.dev.chumbacasino.com/responsible-social-gameplay',
        GAP_IFRAME_URL: 'https://clients.gap-dev.vgwgames.com/gap-iframe',
        BRAZE_SDK_URL: 'sdk.iad-01.braze.com',
        BRAZE_CLIENT_ID: 'b24d9681-bad9-4b6a-8571-8b8c03484bdd',
        CLICKSTREAM_URL: 'https://clickstream.test.chumbacasino.com',
        TMX_URL: 'https://cdn-s.dev.chumbacasino.com',
        TMX_ORG_ID: '6ydwjlgq',
        ZENDESK_SUBDOMAIN: 'chumbacasino1625100997',
        BASE_QUESTS_API_URL: 'https://api-test.dev.chumbacasino.com/chu-quests',
        CONSENT_URL: 'https://pipelines.dev.vgw.marketing/device/set-consent',
    },
    prod: {
        VERIFICATION_COOKIE_NAME: 'VGWverification',
        REQUEST_PATH_HOST_SEGMENT: '',
        CHUMBA_GAME_ENVIRONMENT: 'prod',
        USE_CONSOLE_APPENDER: false,
        BASE_STORE_API_URL: 'https://api.prod.chumbacasino.com/store',
        CASINO_URL: 'https://play.chumbacasino.com/chumba',
        PURCHASE_URL: 'https://purchase.chumbacasino.com',
        WS_URL: 'wss://play.chumbacasino.com',
        OFFERS_URL: 'https://offers.chumbacasino.com',
        LOGIN_URL: 'https://login.chumbacasino.com',
        GAME_SERVER_BASE_URL: 'https://gameserver.chumbacasino.com',
        GAMES_MANAGEMENT_URL: 'https://gameserver.chumbacasino.com/services/games-management/jackpots',
        GAS_JACKPOT_BALANCE_URL: 'https://api.gap.vgwgames.com/gas/jackpots',
        GAME_SERVER_CDN_URL: 'https://gameserver.chumbacasino.com/clients',
        LEGACY_GAMES_CLIENTS_URL: 'https://gameserver.chumbacasino.com/legacy-clients',
        GOLDEN_FEATHER_CDN_URL: 'https://gameserver.chumbacasino.com/clients',
        REDEEM_URL: 'https://redeem.chumbacasino.com',
        CHALLENGE_URL: 'https://challenge.chumbacasino.com',
        CHU_RIS_URL: 'https://external-games.chumbacasino.com',
        CHU_RIS_CDN_URL: 'https://gameserver.chumbacasino.com/clients/chu-ris',
        PLAYTECH_URL: 'https://login-mt.onegameslink.com',
        PLAYER_ACCOUNT_URL: 'https://api.prod.chumbacasino.com/player-account',
        PLAYER_TOKEN_URL: 'https://api.prod.chumbacasino.com/player-token/v1',
        CUSTOMER_API_URL: 'https://api.prod.chumbacasino.com/customer',
        RSG_URL: 'https://api.prod.chumbacasino.com/responsible-social-gameplay',
        GAP_IFRAME_URL: 'https://clients.gap.vgwgames.com/gap-iframe',
        BRAZE_SDK_URL: 'sdk.iad-01.braze.com',
        BRAZE_CLIENT_ID: '23a0b894-46f9-4372-93bc-d6e7c0736950',
        CLICKSTREAM_URL: 'https://clickstream.chumbacasino.com',
        TMX_URL: 'https://cdn-s.chumbacasino.com',
        TMX_ORG_ID: 'ddoxrvbl',
        ZENDESK_SUBDOMAIN: 'chumbacasino',
        BASE_QUESTS_API_URL: 'https://api.prod.chumbacasino.com/chu-quests',
        CONSENT_URL: 'https://pipelines.vgw.marketing/device/set-consent',
    },
};

export const ClientConfig = ((): Config => {
    switch (window.location.hostname) {
        case 'localhost':
        case '127.0.0.1':
            return configs.local;
        case 'lobby-dev.dev.chumbacasino.com':
            return configs.dev;
        case 'lobby-dev-dr.dev.chumbacasino.com':
            return {
                ...configs.dev,
                PLAYER_ACCOUNT_URL: 'https://api-dr.dev.chumbacasino.com/player-account',
                PLAYER_TOKEN_URL: 'https://api-dr.dev.chumbacasino.com/player-token/v1',
                GAME_SERVER_BASE_URL: 'https://gameserver-dr.dev.chumbacasino.com',
                GAMES_MANAGEMENT_URL: 'https://gameserver-dr.dev.chumbacasino.com/services/games-management/jackpots',
                GAME_SERVER_CDN_URL: 'https://gameserver-dr.dev.chumbacasino.com/clients',
                LEGACY_GAMES_CLIENTS_URL: 'https://gameserver-dr.dev.chumbacasino.com/legacy-clients',
                GOLDEN_FEATHER_CDN_URL: 'https://gameserver-dr.dev.chumbacasino.com/clients',
                REDEEM_URL: 'https://redeem-dev-dr.dev.chumbacasino.com',
                CHU_RIS_URL: 'https://external-games-test.dev.chumbacasino.com',
                CHU_RIS_CDN_URL: 'https://gameserver-dr.dev.chumbacasino.com/clients/chu-ris',
                CUSTOMER_API_URL: 'https://api-dr.dev.chumbacasino.com/customer',
                RSG_URL: 'https://api-dr.dev.chumbacasino.com/responsible-social-gameplay',
            };
        case 'lobby-test.dev.chumbacasino.com':
            return configs.test;
        case 'lobby.chumbacasino.com':
            return configs.prod;
    }
    throw new Error('Invalid hostname');
})();
