import { PlayerAccountBlockedFeatures, PlayerAccountRestriction, PlayerAccountRestrictionsResponse } from './types';

export const mapToBlockedFeatures = (
    playerAccountRestrictionsResponse: PlayerAccountRestrictionsResponse
): PlayerAccountBlockedFeatures => {
    let playerAccountBlockedFeatures: PlayerAccountBlockedFeatures = { restrictions: [] };
    playerAccountRestrictionsResponse.restrictions.forEach((restriction) => {
        switch (restriction) {
            case PlayerAccountRestriction.PURCHASES:
                playerAccountBlockedFeatures.restrictions.push('PURCHASE');
                break;
            case PlayerAccountRestriction.PROMOTIONAL_OFFERS:
                playerAccountBlockedFeatures.restrictions.push('OFFERS');
                break;
            case PlayerAccountRestriction.DAILY_BONUS:
                playerAccountBlockedFeatures.restrictions.push('DAILY_BONUS');
                break;
            case PlayerAccountRestriction.ALTERNATE_METHODS_OF_ENTRY:
                playerAccountBlockedFeatures.restrictions.push('AMOE');
                break;
            case PlayerAccountRestriction.META_FEATURES:
                playerAccountBlockedFeatures.restrictions.push('CHALLENGES');
                playerAccountBlockedFeatures.restrictions.push('QUESTS');
                break;
            case PlayerAccountRestriction.GAMES:
                playerAccountBlockedFeatures.restrictions.push('GAMES');
                break;
            case PlayerAccountRestriction.REDEMPTIONS:
                playerAccountBlockedFeatures.restrictions.push('REDEMPTION');
                break;
            case PlayerAccountRestriction.SWEEPS_COINS_PLAY:
                playerAccountBlockedFeatures.restrictions.push('SWEEPS_COINS_PLAY');
                break;
            case PlayerAccountRestriction.GOLD_COINS_PLAY:
                playerAccountBlockedFeatures.restrictions.push('GOLD_COINS_PLAY');
                playerAccountBlockedFeatures.restrictions.push('FREE_GC');
                break;
            case PlayerAccountRestriction.LOBBY_ACCESS:
                playerAccountBlockedFeatures.restrictions.push('ACCESS');
        }
    });
    return playerAccountBlockedFeatures;
};
