import moment from 'moment';
import { createSaga } from 'store/create';
import {
    LOGGED_IN,
    LOGIN_USER,
    LOGIN_USER_FOR_GTM_EVENT,
    REGISTERED,
    SYNC_USER_TOKEN_BALANCE,
    UPDATE_GOLD_BALANCE,
    UPDATE_SWEEPS_BALANCE,
    UserAction,
} from './types';
import { httpClient } from 'common/services/net/http-client';
import { INIT_APP, LOAD_USER_CONFIG, SystemAction, LOAD_USER_PREFERENCES } from 'store/system/types';
import { UserResponse, ErrorResponse } from '@chu/http/dist';
import { setUserMessages, userInitialisationComplete, preloadSegmentedStore } from 'store/system/actions';
import { getSourceOfWealthModalProps } from 'components/elements/source-of-wealth/modal-prop-types';
import { queueModal, queueModalFront } from 'store/modal/actions';
import { ModalEngineAction } from 'store/modal/types';
import {
    get2kBlockedModalProps,
    get2kPendingModalProps,
    get2kPendingReminderModalProps,
    get2kVerfiModalProps,
} from 'components/elements/2k-verification/twok-props-creators';
import { trackUserLoggedIn } from 'common/services/analytics/tracked-actions';
import { AppState } from '../app-state';
import { educateNewUser } from 'store/store/actions';
import { fetchPlayerTokenAmounts } from '../../common/services/player-token';
import { loginUser, setPlayerVerificationStatus } from './actions';
import { LobbyConstants } from 'chumba/lobby-constants';
import { getMarketExitNotYetVerifiedModalProps } from 'components/elements/modal/market-exit/not-yet-verified/market-exit-not-yet-verified-modal';
import { getMarketExitPendingModalProps } from 'components/elements/modal/market-exit/pending/market-exit-pending-modal';
import { getMarketExitRejectModalModalProps } from 'components/elements/modal/market-exit/reject/market-exit-reject-modal';
import { getMarketExitReverifyModalProps } from 'components/elements/modal/market-exit/reverify/market-exit-reverify-modal';
import { getMarketExitInformModalProps } from 'components/elements/modal/market-exit/inform/market-exit-inform-modal';
import { Dispatch } from '@reduxjs/toolkit';
import { AppAction } from 'store/app-action';
import { logger } from 'common/services/log/logger';
import * as braze from '@braze/web-sdk';
import { ClientConfig } from 'config/client-config';
import { getMustKYCVerificationModalProps } from '../../components/elements/modal/must-kyc/must-kyc-modal';
import { getLobbyLayout } from '../../layout/lobby-layout';
import { openBrazeSession } from '../../helpers/braze';
import { modifyLayout } from '../layout/actions';
import { getMustKYCUnsuccessfulVerificationModalProps } from 'components/elements/modal/must-kyc/must-kyc-rejected-verification-modal';
import { getMustKYCAccountPendingVerificationModalProps } from 'components/elements/modal/must-kyc/must-kyc-account-pending-verification-modal';
import { customerHttpService } from 'common/services/customer-service';
import { TwoKVerificationCompliance } from 'common/services/customer-service/types';
import { playerAccountHttpService } from '../../common/services/player-account-service';
import { CLIENT_TOGGLE_SERVICE } from '../../config/client-toggle/client-toggle-service';

const { saga, handle } = createSaga<AppState, UserAction | SystemAction | ModalEngineAction>();

export { saga as userSaga };

handle('USER_INITIALISATION_COMPLETE', (_action, dispatch) => {
    dispatch(educateNewUser());
});

handle(LOGIN_USER, (action, dispatch) => {
    const { channel, isFirstSession, username, userId } = action.payload;
    const wasBrowserLoggedIn = window.sessionStorage.getItem(LOGGED_IN_SESSION_STORAGE_KEY) === 'true';
    if (!wasBrowserLoggedIn) {
        if (isFirstSession && channel) {
            dispatch({ type: REGISTERED, channel });
        } else {
            dispatch({ type: LOGGED_IN, channel });
            dispatch({
                type: LOGIN_USER_FOR_GTM_EVENT,
                payload: {
                    email: username,
                    accountId: userId,
                },
            });
        }

        trackUserLoggedIn();
    }
    window.sessionStorage.setItem(LOGGED_IN_SESSION_STORAGE_KEY, 'true');
});

handle(INIT_APP, async (_, dispatch, state) => {
    braze.initialize(ClientConfig.BRAZE_CLIENT_ID, {
        baseUrl: ClientConfig.BRAZE_SDK_URL,
        sessionTimeoutInSeconds: 20,
        enableLogging: false,
        enableSdkAuthentication: true,
        minimumIntervalBetweenTriggerActionsInSeconds: 10,
        inAppMessageZIndex: 999999, // Modal engine sets it as 99999, and Braze popups need to appear in front
    });

    try {
        const userResponse = await httpClient.get('/api/me');
        if (isErrorResponse(userResponse) || !userResponse.success) {
            redirectUser(ClientConfig.LOGIN_URL);
            return;
        }
        const {
            user,
            termsOfUse,
            toggles,
            messages,
            sourceOfWealthBlocked,
            mustKycStatus,
            promotionalPlay,
            userPreferences,
            stateLocation,
            blockedFeatures,
            showMarketExitModal,
            userVerificationStatus,
        } = userResponse;
        const { userId, brazeToken } = user;
        const layout = await getLobbyLayout();
        const { blockedGameIds, blockedGameTypes } = layout;

        // Retrieving the layout blocks the lobby being shown so we dispatch it before retrieving other values
        dispatch(modifyLayout(layout));

        //Set player's verification status in the lobby state
        dispatch(setPlayerVerificationStatus(userVerificationStatus));

        // Open braze session as soon as we have the user id, as there is a delay between creating a new user
        // and when content is ready to be fetched from braze.
        openBrazeSession(userId, brazeToken, blockedGameIds, blockedGameTypes);

        const { goldBalance, sweepsBalance } = await fetchAndParsePlayerTokens(userId);
        const mustKyc = mustKycStatus === 'MUST_KYC_FLAGGED';

        if (blockedFeatures.includes('ACCESS')) {
            redirectUser(LobbyConstants.geoBlockPageUrl);
            return;
        }

        const twoKVerificationComplianceStatus = await customerHttpService.getTwoKVerificationCompliance(userId);

        const isUserTwoKBlocked =
            twoKVerificationComplianceStatus.message === 'TWO_K_VERIFICATION_BLOCKED' ||
            twoKVerificationComplianceStatus.message === 'TWO_K_VERIFICATION_BLOCKED_PENDING_CDD';

        dispatch(loginUser({ ...user, goldBalance, sweepsBalance }));

        if (CLIENT_TOGGLE_SERVICE.featureRestrictionsEnabled()) {
            const featureRestrictions = await playerAccountHttpService.getPlayerRestrictions(userId);
            featureRestrictions.restrictions.forEach((restriction) => {
                blockedFeatures.push(restriction);
            });
        }

        dispatch({
            type: LOAD_USER_CONFIG,
            payload: {
                toggles,
                togglesLoaded: true,
                termsOfUse,
                promotionalPlay,
                stateLocation,
                isUserTwoKBlocked,
                blockedFeatures,
                mustKyc,
            },
        });
        dispatch(setUserMessages(messages));

        dispatch({
            type: LOAD_USER_PREFERENCES,
            userPreferences,
        });

        if (sourceOfWealthBlocked) {
            dispatch(queueModal(getSourceOfWealthModalProps()));
        }

        if (showMarketExitModal) {
            handleDisplayMarketExitModal(dispatch, userVerificationStatus);
        }

        await handleDisplayMustKycModal(dispatch, mustKyc, userVerificationStatus);

        await handleDisplayTwoKModal(dispatch, twoKVerificationComplianceStatus, userId);

        //preload segmented store in here
        dispatch(preloadSegmentedStore());
        dispatch(userInitialisationComplete());
    } catch (e) {
        console.error(e);
        redirectUser(ClientConfig.LOGIN_URL);
        return;
    }
});

handle(SYNC_USER_TOKEN_BALANCE, async (_action, dispatch, state) => {
    const playerId = state.user.userId;
    const { goldBalance, sweepsBalance } = await fetchAndParsePlayerTokens(playerId);
    dispatch({
        type: UPDATE_GOLD_BALANCE,
        balance: goldBalance,
    });
    dispatch({
        type: UPDATE_SWEEPS_BALANCE,
        balance: sweepsBalance,
    });
});

const TWO_K_VERIFICATION_MAX_WARNING_DAYS = 30;
export const SHOWN_TWOK_MODAL_SESSION_STORAGE_KEY = 'shownTwoKModal';
export const LOGGED_IN_SESSION_STORAGE_KEY = 'loggedIn';
export const SHOWN_MARKET_EXIT_INFORM_MODAL_SESSION_STORAGE_KEY = 'shownMarketExitInformModal';

// This function exists to conform with the old namings for coin amounts. Once player tokens is no longer
// dev flagged, this function can be removed and the saga can call `fetchPlayerTokenAmounts` directly.
const fetchAndParsePlayerTokens = async (userId: number): Promise<{ goldBalance: number; sweepsBalance: number }> => {
    const { goldCoins, sweepsCoins, redeemableSweepsCoins } = await fetchPlayerTokenAmounts(userId);
    return { goldBalance: goldCoins, sweepsBalance: sweepsCoins + redeemableSweepsCoins };
};

const isErrorResponse = (response: UserResponse | ErrorResponse): response is ErrorResponse => 'message' in response;

const redirectUser = (url: string) => {
    window.location.href = url;
};

const calculateDaysRemaining = (dateFrom: Date): number => {
    const dateFromMoment = moment.utc(dateFrom);
    const daysPassed = moment.utc().diff(dateFromMoment, 'days');
    return TWO_K_VERIFICATION_MAX_WARNING_DAYS - daysPassed;
};

const handleDisplayMarketExitModal = (dispatch: Dispatch<AppAction>, verificationStatus: string | null): void => {
    switch (verificationStatus) {
        case 'NOT_YET_VERIFIED':
            dispatch(queueModal(getMarketExitNotYetVerifiedModalProps()));
            break;
        case 'PENDING':
            dispatch(queueModal(getMarketExitPendingModalProps()));
            break;
        case 'REJECT':
            dispatch(queueModal(getMarketExitRejectModalModalProps()));
            break;
        case 'REVERIFY':
            dispatch(queueModal(getMarketExitReverifyModalProps()));
            break;
        case 'SDD_ACCEPT':
        case 'ACCEPT':
        case 'VERIFIED':
            const wasMarketExitInformModalShown =
                window.sessionStorage.getItem(SHOWN_MARKET_EXIT_INFORM_MODAL_SESSION_STORAGE_KEY) === 'true';

            if (!wasMarketExitInformModalShown) {
                dispatch(queueModal(getMarketExitInformModalProps()));
                window.sessionStorage.setItem(SHOWN_MARKET_EXIT_INFORM_MODAL_SESSION_STORAGE_KEY, 'true');
            }

            break;
        default:
            logger.warn(
                `An error occurred while attempting to display the market exit modal. The status: ${verificationStatus} has been invalidated.`
            );
            dispatch(queueModal(getMarketExitNotYetVerifiedModalProps()));
            break;
    }
};

const handleDisplayMustKycModal = async (
    dispatch: Dispatch<AppAction>,
    mustKyc: boolean,
    userVerificationStatus: string | null
): Promise<void> => {
    if (mustKyc) {
        if (!location.pathname.includes('/account-verification')) {
            if (userVerificationStatus === 'NOT_YET_VERIFIED' || userVerificationStatus === null) {
                dispatch(queueModalFront(getMustKYCVerificationModalProps()));
            }
            if (userVerificationStatus === 'PENDING') {
                dispatch(queueModalFront(getMustKYCAccountPendingVerificationModalProps()));
            }
            if (userVerificationStatus === 'REJECT' || userVerificationStatus === 'REVERIFY') {
                dispatch(queueModalFront(getMustKYCUnsuccessfulVerificationModalProps()));
            }
        }
    }
};

const handleDisplayTwoKModal = async (
    dispatch: Dispatch<AppAction>,
    twoKVerificationComplianceStatus: TwoKVerificationCompliance,
    userId: number
): Promise<void> => {
    switch (twoKVerificationComplianceStatus.message) {
        case 'TWO_K_VERIFICATION_BLOCKED':
            dispatch(queueModal(get2kBlockedModalProps(userId)));
            break;
        case 'TWO_K_VERIFICATION_BLOCKED_PENDING_CDD':
            dispatch(queueModal(get2kPendingModalProps()));
            break;
        case 'TWO_K_VERIFICATION_FLAGGED_REJECTED_CDD':
            dispatch(
                queueModal(
                    get2kPendingReminderModalProps(
                        calculateDaysRemaining(twoKVerificationComplianceStatus.dateTwoKThresholdWasFlagged)
                    )
                )
            );
            break;
        case 'TWO_K_VERIFICATION_FLAGGED_NOT_BLOCKED':
            const was2kVerificationModalShown =
                window.sessionStorage.getItem(SHOWN_TWOK_MODAL_SESSION_STORAGE_KEY) === 'true';

            if (!was2kVerificationModalShown) {
                dispatch(
                    queueModal(
                        get2kVerfiModalProps(
                            calculateDaysRemaining(twoKVerificationComplianceStatus.dateTwoKThresholdWasFlagged)
                        )
                    )
                );

                window.sessionStorage.setItem(SHOWN_TWOK_MODAL_SESSION_STORAGE_KEY, 'true');
            }
            break;
        case 'NO_ACTION_REQUIRED':
            break;
    }
};
