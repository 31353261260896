import numeral from 'numeral';
import { Currency } from '@chu/http';
import { getCurrencyDisplayValue, getCurrencyText } from './currency';

const DECIMAL_FORMAT = '0,0.00';
const WHOLE_NUMBER_FORMAT = '0,0';

export const formatPrice = (price: number): string => price.toFixed(2);

const sweepsWithPrefix = (sweepsRaw: number, includeDecimal = true): string =>
    `SC ${sweepsToDisplay(sweepsRaw, includeDecimal)}`;

const sweepsWithPostfix = (sweepsRaw: number, includeDecimal = true): string => {
    const value = sweepsToDisplay(sweepsRaw, includeDecimal);
    return `${value} ${getCurrencyText(Currency.SWEEPS)}${sweepsRaw > 100 ? 's' : ''}`; // FYI sweepsRaw 100 = 1SC
};

const sweepsToDisplay = (sweepsRaw: number, includeDecimal = true): string =>
    `${numeral(getCurrencyDisplayValue(Currency.SWEEPS, sweepsRaw)).format(
        includeDecimal ? DECIMAL_FORMAT : WHOLE_NUMBER_FORMAT
    )}`;

const goldWithPrefix = (goldRaw: number): string => `GC ${goldToDisplay(goldRaw)}`;

const goldWithPostfix = (goldRaw: number): string => {
    const value = goldToDisplay(goldRaw);
    return `${value} ${getCurrencyText(Currency.GOLD)}${goldRaw > 1 ? 's' : ''}`;
};

const goldToDisplay = (goldRaw: number): string =>
    `${numeral(getCurrencyDisplayValue(Currency.GOLD, goldRaw)).format(WHOLE_NUMBER_FORMAT)}`;

// copied from client for time being, probably want to move this into momentjs at some point - 15/10/19
export const parseDateString = (dateString: string) => {
    if (dateString.length !== 10 && dateString.length !== 19) {
        throw new Error('Invalid DateString Format');
    }

    dateString = dateString.replace('-', '/');
    dateString = dateString.replace('-', '/');

    return new Date(Date.parse(dateString));
};

export function pixelSassVariableToNumber(variable: string) {
    return Number(variable.split('px')[0]);
}

export const Sweeps = {
    toDisplay: sweepsToDisplay,
    toDisplayWithPrefix: sweepsWithPrefix,
    toDisplayWithPostfix: sweepsWithPostfix,
};

export const Gold = {
    toDisplay: goldToDisplay,
    toDisplayWithPrefix: goldWithPrefix,
    toDisplayWithPostfix: goldWithPostfix,
};
