import {
    DestinationRoute,
    DeviceOrientation,
    HIDE_TOPHUD,
    HideTopHudAction,
    PAGE_LOAD,
    PageLoadAction,
    SET_BINGO_LOBBY_OPENED,
    SET_DESTINATION_ROUTE,
    SET_DEVICE_ORIENTATION,
    SetBingoLobbyAction,
    SetDestinationRouteAction,
    SetDeviceOrientationAction,
    SHOW_TOPHUD,
    ShowTopHudAction,
    TOGGLE_CHALLENGES_POPOUT,
    TOGGLE_NOTIFICATION_POPOUT,
    TOGGLE_SFX,
    TOGGLE_SIDEBAR,
    ToggleChallengesPopout,
    ToggleNotificationsPopout,
    ToggleSfxAction,
    ToggleSidebarAction,
    TRIGGER_RESIZE_EVENT,
    TriggerResizeEventAction,
} from './types';

export function toggleChallengesPopout(): ToggleChallengesPopout {
    return {
        type: TOGGLE_CHALLENGES_POPOUT,
    };
}

export function toggleNotificationsPopout(): ToggleNotificationsPopout {
    return {
        type: TOGGLE_NOTIFICATION_POPOUT,
    };
}

export function triggerResizeEventAction(): TriggerResizeEventAction {
    return {
        type: TRIGGER_RESIZE_EVENT,
    };
}

export const showTopHudAction = (): ShowTopHudAction => ({
    type: SHOW_TOPHUD,
});

export const hideTopHudAction = (): HideTopHudAction => ({
    type: HIDE_TOPHUD,
});

export const toggleSfxAction = (): ToggleSfxAction => ({
    type: TOGGLE_SFX,
});

export const toggleSidebar = (): ToggleSidebarAction => ({
    type: TOGGLE_SIDEBAR,
});

export const setDestinationRoute = (destinationRoute: DestinationRoute): SetDestinationRouteAction => ({
    type: SET_DESTINATION_ROUTE,
    destinationRoute,
});

export const trackPageLoad = (path: string): PageLoadAction => ({
    type: PAGE_LOAD,
    path,
});

export function setDeviceOrientationAction(orientation: DeviceOrientation): SetDeviceOrientationAction {
    return {
        type: SET_DEVICE_ORIENTATION,
        orientation,
    };
}

export function setBingoLobbyAction(bingoLobbyOpened: boolean): SetBingoLobbyAction {
    return {
        type: SET_BINGO_LOBBY_OPENED,
        bingoLobbyOpened,
    };
}
