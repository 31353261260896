import { Category } from 'layout/lobby-categories';
import {
    AddRecentlyPlayedAction,
    LAYOUT_ACTION_TYPES,
    LayoutState,
    ModifyLayoutAction,
    SetRecommendedGamesAction,
    SetTopGamesAction,
    AddFavoriteGamesAction,
    RemoveFavoriteGamesAction,
    SetFavoriteGamesAction,
    SetCategoriesAction,
} from './types';

export function modifyLayout(layout: LayoutState): ModifyLayoutAction {
    return {
        type: LAYOUT_ACTION_TYPES.MODIFY_LAYOUT,
        payload: layout,
    };
}

export function addRecentlyPlayed(gameId: string): AddRecentlyPlayedAction {
    return {
        type: LAYOUT_ACTION_TYPES.ADD_RECENTLY_PLAYED,
        payload: gameId,
    };
}

export function setRecommendedGames(gameIds: string[]): SetRecommendedGamesAction {
    return {
        type: LAYOUT_ACTION_TYPES.SET_RECOMMENDED_GAMES,
        payload: gameIds,
    };
}

export function setTopGames(gameIds: string[]): SetTopGamesAction {
    return {
        type: LAYOUT_ACTION_TYPES.SET_TOP_GAMES,
        payload: gameIds,
    };
}

/** Add Game to Favorites */
export function addFavoriteGames(gameId: string): AddFavoriteGamesAction {
    return {
        type: LAYOUT_ACTION_TYPES.ADD_FAVORITE_GAMES,
        payload: gameId,
    };
}

/** Remove Game from Favorites */
export function removeFavoriteGames(gameId: string): RemoveFavoriteGamesAction {
    return {
        type: LAYOUT_ACTION_TYPES.REMOVE_FAVORITE_GAMES,
        payload: gameId,
    };
}

/** Load Games into Favorites (typically used to initialize state from persistence store) */
export function setFavoriteGames(gameIds: string[]): SetFavoriteGamesAction {
    return {
        type: LAYOUT_ACTION_TYPES.SET_FAVORITE_GAMES,
        payload: gameIds,
    };
}

/** Sets the Categories which are returned from the lobby management tooling */
export function setCategories(categories: Category[]): SetCategoriesAction {
    return {
        type: LAYOUT_ACTION_TYPES.SET_CATEGORIES,
        payload: categories,
    };
}
