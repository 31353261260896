import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { ThemeButton } from '../../button/theme-buttons';
import { ReactComponent as Exclamation } from './exclamation-icon.svg';
import { GenericModalLayout } from '../../../domain/account-verification/modals/generic-modal';
import { logout } from '../../../../helpers/logout';

export const MustKYCVerificationModal = () => {
    const dispatch = useDispatch();

    const handleOnGetVerifiedClick = () => {
        window.location.href = '/account-verification';
        dispatch(hideModal(MUST_KYC_VERIFICATION_MODAL));
    };

    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Exclamation />
                </div>
            }
        >
            <div className='must-kyc-modal__container'>
                <h2>Verification required</h2>
                <p>
                    A verified account helps us keep Chumba safe and allows you to redeem prizes. As part of our terms
                    and conditions, we require customers to verify their account for security purposes.
                </p>
                <p>You will not be able to access Chumba until your account is verified.</p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='must-kyc-modal__get-verified-btn'
                        className='primary-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        onClick={() => handleOnGetVerifiedClick()}
                    >
                        GET VERIFIED
                    </ThemeButton>
                    <ThemeButton
                        id='must-kyc-modal__log-out-btn'
                        theme='red-dark'
                        actionType='secondary'
                        size='md'
                        onClick={() => logout()}
                    >
                        LOG OUT
                    </ThemeButton>
                </div>
                <a
                    id='must-kyc-modal__why-is-this-needed-btn'
                    className='zendesk-link'
                    href='https://chumbacasino.zendesk.com/hc/en-us/articles/360052176273-Why-do-I-need-to-verify-my-account'
                    target='_blank'
                >
                    Why is this needed?
                </a>
            </div>
        </GenericModalLayout>
    );
};

export const getMustKYCVerificationModalProps = () =>
    ({ type: MUST_KYC_VERIFICATION_MODAL, shouldCloseOnEsc: false, shouldCloseOnOverlayClick: false } as const);

export const MUST_KYC_VERIFICATION_MODAL = 'MUST_KYC_VERIFICATION_MODAL';
