import { TWO_K_BLOCKED, TWO_K_PENDING, TWO_K_PENDING_REMINDER, TWO_K_VERIFICATION } from './modal-prop-types';

export type TwoKBlockedProperties = Omit<ReturnType<typeof get2kBlockedModalProps>, 'type'>;
export type TwoKVerificationProperties = Omit<ReturnType<typeof get2kVerfiModalProps>, 'type'>;

export const get2kBlockedModalProps = (userId: number, shouldCloseOnOverlayClick = false, shouldCloseOnEsc = false) =>
    ({
        type: TWO_K_BLOCKED,
        userId,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const);

export const get2kPendingModalProps = (shouldCloseOnOverlayClick = false, shouldCloseOnEsc = false) =>
    ({
        type: TWO_K_PENDING,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const);

export const get2kPendingReminderModalProps = (
    daysRemaining: number,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false
) =>
    ({
        type: TWO_K_PENDING_REMINDER,
        daysRemaining,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const);

export const get2kVerfiModalProps = (
    daysRemaining: number,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false
) =>
    ({
        type: TWO_K_VERIFICATION,
        daysRemaining,
        shouldCloseOnOverlayClick,
        shouldCloseOnEsc,
    } as const);
