import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../store/modal/actions';
import { GenericModalLayout } from '../../generic/generic-modal-layout';
import { MARKET_EXIT_INFORM } from '../modal-prop-types';
import { GenericModalTypes } from '../../generic/generic-modal-prop-creators';
import { MarketExitInformModalActions } from './market-exit-inform-modal-actions';
import { LobbyConstants } from 'chumba/lobby-constants';
import { ReactComponent as ExclamationIcon } from '../../../2k-verification/exclamation-icon.svg';

import './market-exit-inform-modal.scss';

export const MarketExitInformModal = () => {
    const dispatch = useDispatch();
    const id: GenericModalTypes = MARKET_EXIT_INFORM;
    const modalName = 'market-exit-inform-modal';

    return (
        <GenericModalLayout id={id} classExtra={modalName} xIcon={false}>
            <ExclamationIcon height='20%' width='20%' />
            <h2 className={`${modalName}__header`}>Soon Chumba Casino will not be available in this region</h2>
            <p className={`${modalName}__content`}>
                A recent change to our{' '}
                <a
                    id={`${modalName}__terms-and-conditions-link`}
                    data-testid={`${modalName}__terms-and-conditions-link`}
                    className={`${modalName}__terms-and-conditions-link`}
                    target='_blank'
                    href={LobbyConstants.termsAndConditionsUrl}
                >
                    Terms and Conditions
                </a>{' '}
                means that players who reside in this region will soon no longer be able to access or play Chumba
                Casino.
            </p>
            <MarketExitInformModalActions modalName={modalName} handleOKBtn={handleClickOkBtn} />
        </GenericModalLayout>
    );

    function handleClickOkBtn() {
        dispatch(hideModal(MARKET_EXIT_INFORM));
    }
};

export const getMarketExitInformModalProps = () => ({ type: MARKET_EXIT_INFORM } as const);
