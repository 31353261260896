import axios from 'axios';
import { ClientConfig } from 'config/client-config';

export const getRedeemHubHref = async () => {
    const result = await axios.post(
        `${ClientConfig.PURCHASE_URL}/redemption-hub`,
        {},
        { withCredentials: true, timeout: 10000 }
    );

    return result.headers.location;
};
