export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';

export const PlayTypes = {
    MAX_PLAY: 'maxbet',
    PLAY: 'bet',
    PLAY_BJK: 'bet_bjk',
} as const;

export type PlayType = (typeof PlayTypes)[keyof typeof PlayTypes];

export const getInsufficientFundsModalProps = (playType: PlayType) => {
    return {
        type: INSUFFICIENT_FUNDS,
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false,
        playType,
    } as const;
};
