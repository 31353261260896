import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPlayerTokenAmounts } from '../../common/services/player-token';

export interface PlayerTokenState {
    redeemableSweepsCoins: number;
    sweepsCoins: number;
    goldCoins: number;
    fetchStatus: 'IDLE' | 'FETCHING' | 'SUCCEEDED' | 'FAILED';
}

const initialState: PlayerTokenState = {
    fetchStatus: 'IDLE',
    redeemableSweepsCoins: 0,
    sweepsCoins: 0,
    goldCoins: 0,
};

export const fetchPlayerTokenAmount = createAsyncThunk('playerToken/fetchPlayerTokenAmount', async (userId: number) => {
    return fetchPlayerTokenAmounts(userId);
});

const playerTokenSlice = createSlice({
    name: 'playerToken',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlayerTokenAmount.fulfilled, (state, action) => {
            // Add user to the state array
            state.fetchStatus = 'SUCCEEDED';
            state.goldCoins = action.payload.goldCoins;
            state.sweepsCoins = action.payload.sweepsCoins;
            state.redeemableSweepsCoins = action.payload.redeemableSweepsCoins;
        });
        builder.addCase(fetchPlayerTokenAmount.pending, (state) => {
            state.fetchStatus = 'FETCHING';
        });
        builder.addCase(fetchPlayerTokenAmount.rejected, (state) => {
            // Should look into a better way to handle errors
            console.error('Error fetching player token amount');
            state.fetchStatus = 'FAILED';
        });
    },
});

export const playerTokenSliceReducer = playerTokenSlice.reducer;
