import { Action } from 'redux';
import { CurrencyType, UserMessageDetails, PromotionalPlay, UserTermsOfUseAcceptanceState } from '@chu/http/dist';
import { UserPreferences, FeatureBlock } from '@chu/http';

export const SET_CURRENCY = 'SET_CURRENCY';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SET_SERVER_OFFSET = 'SET_SERVER_OFFSET';
export const SET_LAST_ACTIVITY_BY_USER = 'SET_LAST_ACTIVITY_BY_USER';
export const SET_PLAY_ID = 'SET_PLAY_ID';
export const LOAD_USER_CONFIG = 'LOAD_USER_CONFIG';
export const LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES';
export const SET_USER_MESSAGES = 'SET_USER_MESSAGES';
export const SET_TMX_SESSION_ID = 'SET_TMX_SESSION_ID';
export const INIT_APP = 'INIT_APP';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const SET_RETURN_TO_GAME_ID = 'SET_RETURN_TO_GAME_ID';
export const SET_SHOW_ACTIVITY_REMINDER_MODAL = 'SET_SHOW_ACTIVITY_REMINDER_MODAL';
export const SET_IS_USER_REVERIFY_BLOCKED = 'SET_IS_USER_REVERIFY_BLOCKED';
export const USER_INITIALISATION_COMPLETE = 'USER_INITIALISATION_COMPLETE';

export type Toggles = {
    [key: string]: boolean;
};

// Describing the shape of the systems slice of state
export interface SystemState {
    clientSessionID: string;
    userIsLoggedIn: boolean;
    currency: CurrencyType;
    serverOffset: number;
    playId: string;
    toggles: Toggles;
    promotionalPlay: PromotionalPlay;
    termsOfUse: UserTermsOfUseAcceptanceState | undefined;
    lastActivityByUser: number;
    messages: UserMessageDetails[];
    tmxSessionId: string;
    userPreferences: UserPreferences;
    returnToGameId: string | undefined;
    showActivityReminderModal: boolean;
    isUserInitialisationComplete: boolean;
    stateLocation: string | undefined;
    isUserTwoKBlocked: boolean;
    isUserReverifyBlocked: boolean;
    togglesLoaded: boolean;
    blockedFeatures: FeatureBlock[];
    mustKyc: boolean;
}

export interface LoadUserConfig extends Action {
    type: typeof LOAD_USER_CONFIG;
    payload: {
        toggles: Toggles;
        togglesLoaded: boolean;
        termsOfUse: UserTermsOfUseAcceptanceState;
        promotionalPlay: PromotionalPlay;
        stateLocation: string | undefined;
        isUserTwoKBlocked: boolean;
        blockedFeatures: FeatureBlock[];
        mustKyc: boolean;
    };
}

interface LoadUserPreferences extends Action {
    type: typeof LOAD_USER_PREFERENCES;
    userPreferences: UserPreferences;
}

export interface SetTMXSessionIDAction extends Action {
    type: typeof SET_TMX_SESSION_ID;
    tmxSessionId: string;
}

// this action should probably be split down to a separate iframe store
export interface SetCurrencyAction extends Action {
    type: typeof SET_CURRENCY;
    currency: CurrencyType;
}

export interface SwitchCurrencyAction extends Action {
    type: typeof SWITCH_CURRENCY;
}

export interface SetServerOffsetAction extends Action {
    type: typeof SET_SERVER_OFFSET;
    serverOffset: number;
}

export interface SetLastActivityByUserAction extends Action {
    type: typeof SET_LAST_ACTIVITY_BY_USER;
    timeEpochMs: number;
}

export interface SetPlayIdAction extends Action {
    type: typeof SET_PLAY_ID;
    playId: string;
}

export interface SetUserMessages extends Action {
    type: typeof SET_USER_MESSAGES;
    payload: UserMessageDetails[];
}

interface InitApp extends Action {
    type: typeof INIT_APP;
}

interface UpdateUserPreferences extends Action {
    type: typeof UPDATE_USER_PREFERENCES;
    userPreferences: UserPreferences;
}

export interface SetReturnToGameId extends Action {
    type: typeof SET_RETURN_TO_GAME_ID;
    gameId: string | undefined;
}

export interface SetShowActivityReminderModal extends Action {
    type: typeof SET_SHOW_ACTIVITY_REMINDER_MODAL;
    showActivityReminderModal: boolean;
}

export interface SetIsUserReverifyBlocked extends Action {
    type: typeof SET_IS_USER_REVERIFY_BLOCKED;
    isUserReverifyBlocked: boolean;
}

export interface PreloadSegmentedStoreAction extends Action {
    type: 'PRELOAD_SEGMENTED_STORE';
}

export interface UserInitialisationComplete extends Action {
    type: typeof USER_INITIALISATION_COMPLETE;
}

export type SystemAction =
    | SetCurrencyAction
    | SwitchCurrencyAction
    | SetPlayIdAction
    | LoadUserConfig
    | LoadUserPreferences
    | SetServerOffsetAction
    | SetLastActivityByUserAction
    | SetUserMessages
    | SetTMXSessionIDAction
    | InitApp
    | UpdateUserPreferences
    | SetReturnToGameId
    | SetShowActivityReminderModal
    | SetIsUserReverifyBlocked
    | UserInitialisationComplete
    | PreloadSegmentedStoreAction;
