import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './gc-topup.scss';
import { GenericModalLayout } from 'components/elements/modal/generic/generic-modal-layout';
import { ThemeButton } from 'components/elements/button/theme-buttons';
import { hideModal, replaceModal } from '../../../../store/modal/actions';
import { GC_TOPUP } from '.';
import { GoldCoinsIcon } from '../../../elements/currency-icons';
import { UPDATE_GOLD_BALANCE } from 'store/user/types';
import axios from 'axios';
import { getDefaultErrorModalProps } from '../common/error';
import { logger } from 'common/services/log/logger';
import { trackGcTopupClaim, trackGcTopupClaimInGame } from 'common/services/analytics/tracked-actions';
import { AppState } from 'store/app-state';
import { ClientConfig } from 'config/client-config';

interface GCTopupModalProps {
    amount: number;
    redirectUrl?: string;
}
export const GCTopupModal: React.FC<GCTopupModalProps> = ({ amount, redirectUrl }) => {
    const dispatch = useDispatch();
    const [hasBeganClaim, setHasBeganClaim] = React.useState(false);
    const { inGame } = useSelector(
        ({ game }: AppState) => ({
            inGame: game.hasGameInit,
        }),
        shallowEqual
    );

    const close = () => {
        dispatch(hideModal(GC_TOPUP));
    };

    const redirect = (url: string) => {
        if (url === window.location.href) {
            return window.location.reload();
        }

        window.location.href = url;
    };

    const claim = async () => {
        try {
            // Used to prevent multiple claim clicks
            setHasBeganClaim(true);

            const { data } = await axios.post('/claim-api/customers/free-gold-coins', null, {
                baseURL: ClientConfig.OFFERS_URL,
                withCredentials: true,
            });

            dispatch({
                type: UPDATE_GOLD_BALANCE,
                balance: Number(data.gold_balance),
            });

            if (inGame) {
                trackGcTopupClaimInGame(amount);
            } else {
                trackGcTopupClaim(amount);
            }

            // Redirect Url provided when modal is shown in game
            if (redirectUrl) redirect(redirectUrl);
            close();
        } catch (e) {
            logger.error('Failed to Claim Free GCs!', e);
            if (inGame) {
                trackGcTopupClaimInGame(amount, (e as Error)?.message ?? 'Unknown Error');
            } else {
                trackGcTopupClaim(amount, (e as Error)?.message ?? 'Unknown Error');
            }
            dispatch(replaceModal(getDefaultErrorModalProps()));
        }
    };

    const modalName = 'gc-topup-modal';
    let modalHeader = 'Free Gold Coins!';
    let modalMessage = 'It’s always free to play at Chumba Casino. Here’s a free top up.';

    if (inGame) {
        modalHeader = 'Not enough coins!';
        modalMessage = 'You don’t have enough Gold Coins for\nthis play. Here’s a free top up.';
    }

    return (
        <GenericModalLayout id={GC_TOPUP} classExtra={modalName}>
            <h2>{modalHeader}</h2>
            <p className={`${modalName}__message`}>{modalMessage}</p>
            <div className={`${modalName}__wrapper`}>
                <GoldCoinsIcon className={`${modalName}__icon`} />
                <div className={`${modalName}__amount`}>GC {amount.toLocaleString()}</div>
            </div>
            <ThemeButton
                id={`${modalName}__claim-btn`}
                actionType='primary'
                className={`${modalName}__claim-btn`}
                size='md'
                onClick={claim}
                disabled={hasBeganClaim}
            >
                CLAIM
            </ThemeButton>
        </GenericModalLayout>
    );
};
