import 'animate.css/animate.min.css'; // TODO FIX THIS PACKAGE
import './referenceGitHash';
import { createLogger, logger } from './common/services/log/logger';
import { createConsoleAppender } from './common/services/log/console-appender';
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals/attribution';
import store from './store';
import { INIT_APP } from './store/system/types';
import { ClientConfig } from './config/client-config';

export const loadApplicationState = (): void => {
    // Create the logger as early as possible before any hooks
    const appenders = [];
    if (ClientConfig.USE_CONSOLE_APPENDER) {
        appenders.push(createConsoleAppender());
    }
    createLogger(...appenders);

    // Attributions are not present in this callback for the types so I have ignored this error for now.
    // @ts-expect-error
    const webVitalsReporter: ReportCallback = ({ name, delta, value, id, attribution }) => {
        let data = {
            event: 'WEB_VITALS_METRIC_RECORDED',
            name,
            id,
            delta,
            value,
            debugTarget: undefined,
        };
        switch (name) {
            case 'CLS':
                data.debugTarget = attribution.largestShiftTarget;
                break;
            case 'FID':
                data.debugTarget = attribution.eventTarget;
                break;
            case 'LCP':
                data.debugTarget = attribution.element;
                break;
        }
        logger.info({ data });
    };

    // Sample 20% of sessions
    if (Math.random() < 0.2) {
        onCLS(webVitalsReporter);
        onFID(webVitalsReporter);
        onLCP(webVitalsReporter);
        onTTFB(webVitalsReporter);
        onFCP(webVitalsReporter);
        onINP(webVitalsReporter);
    }

    // This call sets off the chain that initiates user login and hydrates the state
    store.dispatch({ type: INIT_APP });
};
