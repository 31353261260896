import * as userConnect from './user-connect';
import store from '../store';
import { incrementLocalStorageSessionTotal } from '../helpers/localstorage';
import { setLastActivityByUser, setPlayId, setServerOffset, switchCurrency } from '../store/system/actions';
import { postMessageToIframe, sendMessageToIframe, sendResizeMessage } from './third-party';
import axios from 'axios';
import * as types from './third-party-types';
import { Currency, GameProvider, GoldCoinTopup } from '@chu/http';
import { hideModal, queueModal, queueModalFront } from '../store/modal/actions';
import {
    checkingCanOpenCashout,
    checkingGameCurrency,
    checkingPlayId,
    checkingShowLobby,
    setCurrencyDisplayAnimateAble,
    setCurrencyLockMode,
    setLoadingState,
    setRGSGameToken,
    unloadGame,
} from '../store/game/actions';
import { checkingCanOpenCashier } from 'store/game/actions';
import { parseDateString } from 'helpers/format';
import { httpClient } from '../common/services/net/http-client';
import { History } from 'history';
import { getInsufficientFundsModalProps, PlayTypes } from 'components/elements/modal/insufficient-funds';
import { GENERIC_MODAL, getGenericModalProps } from 'components/elements/modal/generic/generic-modal-prop-creators';
import { trackMachineAction } from 'common/services/analytics/tracked-actions';
import {
    SAVE_USER_PROPERTIES,
    SaveUserProperties,
    UPDATE_GOLD_BALANCE,
    UPDATE_SWEEPS_BALANCE,
    UserVerificationStatus,
} from 'store/user/types';
import { setDestinationRoute } from 'store/ui/actions';
import { DestinationRoute } from 'store/ui/types';
import { GAME_LOADED, GAME_LOADING, GAME_UNLOADING, GAME_UNLOADING_UN_INIT } from 'store/game/types';
import { getStoreModalProps } from 'components/domain/packages/modals/props-creator';
import { logger } from 'common/services/log/logger';
import { getGameUnavailableModalProps } from 'components/elements/modal/game-unavailable';
import { freeGC } from 'store/store/actions';
import { getRedemptionErrorModalProps } from '../components/domain/packages/modals/redeem/redemption-error-prop-creators';
import { Game } from '../store/layout/types';
import {
    GetVerifiedModalProps,
    getGetVerifiedModalProps,
} from 'components/domain/account-verification/modals/get-verified-modal';
import { getPendingVerificationModalProps } from 'components/domain/account-verification/modals/pending-verification-modal';
import { getUnsuccessfulVerificationModalProps } from 'components/domain/account-verification/modals/unsuccessful-verification-modal';
import { getReverifyVerificationModalProps } from 'components/domain/account-verification/modals/reverify-modal';
import { VerificationStatus, getVerifications } from 'common/services/customer';
import { getLinkYourBankAccountModalProps } from 'components/elements/modal/bank-verification';
import { ClientConfig } from 'config/client-config';
import { StoreEntryFrom } from 'common/services/analytics/store/store-types';

export const triggerResize = () => {
    sendResizeMessage();
};

export const clearForcedIframeSize = () => {
    triggerResize();
};

export const forceIframeResize = (message: types.FORCE_IFRAME_RESIZE) => {};
export const showTopHud = () => {}; // Controllers.ui.dispatch(ChumbaCasino.events.ui.TOPHUD_SHOW);
export const hideTopHud = () => {}; // Controllers.ui.dispatch(ChumbaCasino.events.ui.TOPHUD_HIDE);
export const updateTopHud = (e: types.UPDATE_TOPHUD) => {
    if (!e.animate) {
        // only enable the button if the animate state is false. ie the user didn't win anything
        // otherwise defer this behaviour to the useCurrencyCountUp component
        // please note the game is usually set into a lock state at the start of a spin when the iframe calls CURRENCY_BUTTON_STATE
        // FIXES CHU-9756 and makes count up work like play.chumbacasino.com
        store.dispatch(setCurrencyLockMode(false));
    }
    store.dispatch(setCurrencyDisplayAnimateAble(e.animate));
};

export const showInformationWindow = (message: types.SHOW_INFORMATION_WINDOW) => {
    if (typeof message.text !== 'string') {
        console.log('UNEXPECTED VALUE - showInformationWindow');
        return;
    }
    if (message.xButton !== undefined && typeof message.xButton !== 'boolean') {
        console.log('UNEXPECTED VALUE - showInformationWindow');
        return;
    }
    if (message.refreshButton !== undefined && typeof message.refreshButton !== 'boolean') {
        console.log('UNEXPECTED VALUE - showInformationWindow');
        return;
    }

    // THIS CHECK IS NOT AN IDEAL SOLUTION
    // The SHOW_INFORMATION_WINDOW iframe message is used for multiple purposes.
    // In some cases we expect a modal with no buttons, other cases we want a
    // modal with a back to login button (referred to as a 'refresh button'). To
    // send users back to lobby when the chu-inhouse-games clients is unable to
    // connect to the game server we need to capture SHOW_INFORMATION_WINDOW
    // messages with text 'Server could not be reached'.
    if (message.text === 'Server could not be reached') {
        store.dispatch(
            queueModal(
                getGameUnavailableModalProps({
                    heading: '',
                    body: message.text,
                })
            )
        );
    } else {
        store.dispatch(
            queueModal(
                getGenericModalProps({
                    heading: '',
                    body: message.text,
                    refreshButton: message.refreshButton,
                    xButton: message.xButton,
                    id: 'IFRAME_MSG',
                })
            )
        );
    }
};
export const hideInformationWindow = () => {
    store.dispatch(hideModal(GENERIC_MODAL));
    sendMessageToIframe('SHOWING_INFORMATION_WINDOW', false);
};
export const currencyButtonState = (value = false) => {
    store.dispatch(setCurrencyLockMode(!value));
};
export const checkForMessages = () => {}; // Controllers.ui.dispatch(ChumbaCasino.events.ui.PROCESS_INIT_MESSAGE_QUEUE);
export const changeGoldBalance = (e: types.CHANGE_GOLD_BALANCE) => {
    if (typeof e.value !== 'number') {
        console.log('UNEXPECTED VALUE - changeGoldBalance');
        return;
    }
    store.dispatch({ type: UPDATE_GOLD_BALANCE, balance: e.value });
    // user.goldBalance = value
};
export const changeSweepsBalance = (e: types.CHANGE_SWEEPS_BALANCE) => {
    if (typeof e.value !== 'number') {
        console.log('UNEXPECTED VALUE - changeSweepsBalance');
        return;
    }
    store.dispatch({ type: UPDATE_SWEEPS_BALANCE, balance: e.value });
};

export const balanceUpdatedOutsideGame = (e: types.BALANCE_UPDATED_OUTSIDE) => {
    if (typeof e.value !== 'boolean') {
        console.log('UNEXPECTED VALUE - balanceUpdatedOutsideGame');
        return;
    }
    // user.balanceWasUpdatedOutsideGame = value;
};

export const timeWarningWindow = (message: types.TIME_WARNING_WINDOW) => {
    if (typeof message.totalBet !== 'number') {
        console.log('UNEXPECTED VALUE - timeWarningWindow');
        return;
    }
    if (typeof message.winAmount !== 'number') {
        console.log('UNEXPECTED VALUE - timewarningWindow');
        return;
    }
    if (store.getState().system.currency === Currency.GOLD) {
        incrementLocalStorageSessionTotal('sessionTotalGoldBets', message.totalBet);
        incrementLocalStorageSessionTotal('sessionTotalGoldWins', message.winAmount);
    } else if (store.getState().system.currency === Currency.SWEEPS) {
        incrementLocalStorageSessionTotal('sessionTotalSweepsBets', message.totalBet);
        incrementLocalStorageSessionTotal('sessionTotalSweepsWins', message.winAmount);
    }
};
export const doAnalytics = (message: types.ANALYTICS) => {
    if (typeof message.fcn !== 'string') {
        console.log('UNEXPECTED VALUE - doAnalytics');
        return;
    }
    trackMachineAction(message);
};

export const inputByUser = () => {
    store.dispatch(setLastActivityByUser(Date.now()));
};

export const shareOnFacebook = (message: types.SHARE_ON_FACEBOOK) => {}; // facebookConnect.shareOnFB(message.which, message.amt, message.gameId, message.reelname, message.winAmount);

export const attemptGetPlayId = () => {
    const { isCheckingCanOpenCashout, hasGameInit } = store.getState().game;
    if (!hasGameInit) {
        console.error('attempted to get play id before game has init');
    } else if (!isCheckingCanOpenCashout) {
        store.dispatch(checkingPlayId(true));
        sendMessageToIframe('GET_PLAY_ID');
    }
};

const openAccountVerification = () => {
    window.location.href = '/account-verification';
};

const checkBankVerification = async (): Promise<boolean> => {
    let allowToRedeemHub = false;

    const verifications = await getVerifications();

    if (verifications?.bank) {
        allowToRedeemHub = [VerificationStatus.Verified, VerificationStatus.Pending].includes(
            verifications?.bank.status
        );
    }

    return allowToRedeemHub;
};

export const sendToRedeem = (toggleSideBar = () => {}) => {
    const {
        user: { verificationStatus },
    } = store.getState();
    const accountVerified = [UserVerificationStatus.ACCEPT, UserVerificationStatus.VERIFIED].includes(
        verificationStatus
    );

    if (accountVerified) {
        checkBankVerification()
            .then((allowToRedeemHub) => {
                if (allowToRedeemHub) {
                    axios
                        .post(
                            `${ClientConfig.PURCHASE_URL}/redemption-hub`,
                            {},
                            { withCredentials: true, timeout: 10000 }
                        )
                        .then((response) => {
                            window.location.href = response.headers.location;
                            toggleSideBar();
                        })
                        .catch((e) => {
                            logger.error(
                                `Could not connect to ${ClientConfig.PURCHASE_URL} for redemption-hub session`,
                                e
                            );
                            store.dispatch(queueModal(getRedemptionErrorModalProps()));
                            toggleSideBar();
                        });
                } else {
                    store.dispatch(queueModalFront(getLinkYourBankAccountModalProps()));
                    toggleSideBar();
                }
            })
            .catch((err) => {
                logger.error(`Failed to call get verifications`, err);
                store.dispatch(queueModal(getRedemptionErrorModalProps()));
                toggleSideBar();
            });
    } else {
        openAccountVerification();
        toggleSideBar();
    }
};

export const attemptOpenRedeem = (toggleSideBar: () => void) => {
    const {
        game: { isCheckingCanOpenCashout, hasGameInit, isCheckingCurrency, isLockedCurrencyMode, isLoading, company },
        user: { verificationStatus },
    } = store.getState();
    const isCurrencyRestricted = isCheckingCurrency || isLockedCurrencyMode || isLoading;

    if (verificationStatus === UserVerificationStatus.NOT_YET_VERIFIED) {
        const payload: GetVerifiedModalProps = {
            title: `Let's get you verified`,
            description:
                'Take a couple of minutes to verify your account. This helps us keep Chumba safe and allows you to redeem prizes.',
        };

        store.dispatch(queueModalFront(getGetVerifiedModalProps(payload)));
        return;
    }
    if (verificationStatus === UserVerificationStatus.PENDING) {
        store.dispatch(queueModalFront(getPendingVerificationModalProps()));
        return;
    }
    if (verificationStatus === UserVerificationStatus.REJECT) {
        store.dispatch(queueModalFront(getUnsuccessfulVerificationModalProps()));
        return;
    }
    if (verificationStatus === UserVerificationStatus.REVERIFY) {
        store.dispatch(queueModalFront(getReverifyVerificationModalProps()));
        return;
    }

    const gameNotLoadedOrLocked = !hasGameInit && !isCurrencyRestricted;
    const rgsGameNotLocked = isGAPProvider(company) && !isCurrencyRestricted;
    const canBypassCheck = gameNotLoadedOrLocked || rgsGameNotLocked;

    if (canBypassCheck) {
        sendToRedeem(toggleSideBar);
    } else if (!isCheckingCanOpenCashout) {
        store.dispatch(checkingCanOpenCashout(true));
        sendMessageToIframe('CAN_OPEN_CASHOUT');
    }
};

export const openCashier = (entryFrom: StoreEntryFrom = StoreEntryFrom.OTHER) => {
    store.dispatch(queueModalFront(getStoreModalProps(entryFrom)));
};

export const attemptOpenCashier = (entryFrom: StoreEntryFrom = StoreEntryFrom.OTHER) => {
    const { isCheckingCanOpenCashier, hasGameInit, company, isLockedCurrencyMode } = store.getState().game;
    if (!hasGameInit) {
        return openCashier(entryFrom);
    }

    const canBypassCanOpenCashierCheck = isGAPProvider(company);

    if (canBypassCanOpenCashierCheck && !isLockedCurrencyMode) {
        openCashier(entryFrom);
        return;
    }

    if (!isCheckingCanOpenCashier) {
        store.dispatch(checkingCanOpenCashier(true));
        return sendMessageToIframe('CAN_OPEN_CASHIER');
    }
};

export const doSwitchCurrency = () => {
    const newCurrency = store.getState().system.currency === Currency.GOLD ? Currency.SWEEPS : Currency.GOLD;
    sendMessageToIframe('CURRENCY_CHANGED', { currency: newCurrency });
    store.dispatch(switchCurrency());
};

export const attemptSwitchCurrency = () => {
    const gameState = store.getState().game;
    const { hasGameInit, company, isLockedCurrencyMode } = gameState;
    const {
        system: { promotionalPlay, currency, blockedFeatures },
    } = store.getState();

    if (blockedFeatures.includes('SWEEPS_COINS_PLAY')) {
        return;
    }

    // Non-promotional play users may not switch to promotional play
    if (promotionalPlay === 'DISABLED') {
        return;
    }

    if (currency === Currency.GOLD && gameState.playableTokens === 'gold') {
        return;
    }

    if (!hasGameInit) {
        doSwitchCurrency();
        return;
    }

    if (isGAPProvider(company) && !isLockedCurrencyMode) {
        store.dispatch(setRGSGameToken(undefined));
        store.dispatch(switchCurrency());
        store.dispatch(setLoadingState(GAME_LOADING));
        return;
    }

    // check the store state to see if there is any modals open etc
    // do some analytics stuff
    store.dispatch(checkingGameCurrency(true));
    return sendMessageToIframe('CAN_CHANGE_CURRENCY');
};

export const navigateToRoute = (history: History, destinationRoute: DestinationRoute) => {
    if (destinationRoute) {
        history.push(destinationRoute);
        store.dispatch(setDestinationRoute(null));
        return;
    }
    history.push('/');
    window.scrollTo(0, 0);
};

export const attemptLeaveGame = (history: History) => {
    const {
        game: { isCheckingShowLobby, loadingStatus, company },
        ui: { destinationRoute },
    } = store.getState();

    if (isGAPProvider(company) && loadingStatus === GAME_UNLOADING) {
        postMessageToIframe({ _type: 'ucip.closegame.w2gCloseGameRequest' });
        store.dispatch(unloadGame());
        return;
    }

    if (loadingStatus === GAME_UNLOADING_UN_INIT || loadingStatus === GAME_LOADING) {
        navigateToRoute(history, destinationRoute);
        return;
    }

    if ((loadingStatus === GAME_LOADED || loadingStatus === GAME_UNLOADING) && !isCheckingShowLobby) {
        store.dispatch(checkingShowLobby(true));
        sendMessageToIframe('CAN_SHOW_LOBBY');
    }
};

export const showInsufficientFunds = (message: types.SHOW_INSUFFICIENT_FUNDS) => {
    if (message.val === PlayTypes.MAX_PLAY) {
        store.dispatch(queueModal(getInsufficientFundsModalProps(message.val)));
        store.dispatch(freeGC());
    } else {
        if (!store.getState().system.toggles.INSUFFICIENT_COINS_UPDATE || isSCOrAboveGCTopupThreshold()) {
            store.dispatch(queueModal(getInsufficientFundsModalProps(message.val)));
        }
        store.dispatch(freeGC());
    }
};

export const showServerCannotBeReached = () => {
    sendMessageToIframe('SERVER_CANNOT_BE_REACHED', { value: true });

    store.dispatch(
        queueModal(
            getGameUnavailableModalProps({
                heading: '',
                body: 'Server could not be reached',
            })
        )
    );
};

export const saveUserProperties = (newProperties: any) => {
    const oldUserProps = store.getState().user.properties;
    const properties = { ...oldUserProps, ...newProperties };

    const saveUserProperties: SaveUserProperties = {
        type: SAVE_USER_PROPERTIES,
        payload: { properties },
    };

    store.dispatch(saveUserProperties);
    // Yes it is weird to save it to local state when the network request might
    // fail, however this is the contract in chu-client
    httpClient.post('/api/user-properties', {
        body: { properties },
    });
};

export const pushToOpenedWindows = (message: types.PUSH_TO_OPENED_WINDOWS) => {
    if (typeof message.windowName !== 'string') {
        console.log('UNEXPECTED VALUE - pushToOpenedWindows');
        return;
    }
};

export const removeFromOpenedWindows = (message: types.REMOVE_FROM_OPENED_WINDOWS) => {
    if (typeof message.windowName !== 'string') {
        console.log('UNEXPECTED VALUE - removeFromOpenedWindows');
        return;
    }
};

export const parseApiResponse = (message: types.PARSE_API_RESPONSE) => {
    checkAndParseForMessages(message.result);
};

function checkAndParseForMessages(messageResult: types.PARSE_API_RESPONSE['result'] | null = null) {
    if (!messageResult) {
        return;
    }
    const { serverTime } = messageResult;
    if (serverTime) {
        handleServerOffsetMessage(serverTime);
    }
}

function handleServerOffsetMessage(serverTime: string): void {
    try {
        const parsedDate = parseDateString(serverTime);
        const serverOffset = (parsedDate.getTime() - new Date().getTime()) / 1000;
        setServerOffset(serverOffset);
    } catch (e) {
        console.error('Parse date error', e);
    }
}

export const organizeTophudFor = (message: types.ORGANIZE_TOPHUD_FOR) => {
    if ([1, 4].indexOf(message.currency) !== -1) {
        // Controllers.ui.dispatch(ChumbaCasino.events.ui.TOPHUD_ORGANIZE_FOR, message.gameType, message.currency);
    }
};
export const organizeTophudForSlots = (message: types.ORGANIZE_TOPHUD_FOR_SLOTS) => {
    if (message.currency === 1 || message.currency === 4) {
        // Controllers.ui.dispatch(ChumbaCasino.events.ui.TOPHUD_ORGANIZE_FOR, "Slots", message.currency);
    }
};
export const showRecentWinners = () => {}; // Controllers.recentWinners.show();
export const hideRecentWinners = () => {}; // Controllers.recentWinners.hide();
export const changeToFullWindowScreen = () => {
    resize();
};
export const changeToGameWindowScreen = () => {};
export const resize = (o: any = null) => {
    sendResizeMessage();
};

export const sendJackpot = (pushMessage: types.JackpotMessage) => {
    const {
        game,
        layout: { games },
    } = store.getState();

    const loadedGame = game.hasGameInit && games.find((g) => g.gameId === game.gameId);

    if (
        loadedGame &&
        (loadedGame.metadata.grandJackpotName === pushMessage.gameId ||
            loadedGame.metadata.majorJackpotName === pushMessage.gameId)
    ) {
        sendMessageToIframe('UPDATE_JACKPOT', { value: pushMessage });
    }
};

export const checkIfCasinoisBusy = () => {
    sendMessageToIframe('RESPONSE_CASINO_ARE_YOU_BUSY', {
        value: store.getState().game.isCheckingCurrency,
    });
};

export const showGameRules = () => {
    sendMessageToIframe('WINTABLE_SHOW');
};

// ================================================================================================
// API Calls
// ================================================================================================
export const post = (url: string, params: object) => {
    // when end api was call, only accept init calls
    url = url.indexOf('http') > -1 ? url : ClientConfig.CASINO_URL + url;
    axios
        .post(url, getParameters(params), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested-With': 'XMLHttpRequest, XMLHttpRequest',
            },
            withCredentials: true,
        })
        .then((result) => {
            responseSuccess(result.data);
        })
        .catch((e) => {
            logger.error(`Server cannot be reached: url: ${url}`, e);
            showServerCannotBeReached();
        });
};
const getParameters = (extra: object = {}) => {
    const urlParams = new URLSearchParams();
    urlParams.append('jsonString', JSON.stringify(Object.assign({}, extra)));
    return urlParams;
};

const errorResponse = (result: types.IFrameErrorResponse) => {
    if (result && result.errorDesc != null) {
        showInformationWindow({
            type: 'SHOW_INFORMATION_WINDOW',
            text: result.errorDesc,
            xButton: false,
            refreshButton: true,
        });
    } else {
        showServerCannotBeReached();
    }
};

const responseSuccess = async (result: types.IFrameErrorResponse) => {
    if (userConnect.checkForErrors(result)) {
        // this already shows an error window if response is true
        console.log('rejecting due to checkForErrors fail');
    } else if (result.success === false) {
        // checks if there was an error message from the server
        console.log('rejecting due to success=false');
        errorResponse(result);
    } else if (result.errorCode) {
        // checks if there was an error message from the server
        console.log('rejecting due to errorCode');
        errorResponse(result);
    } else {
        // no errors, resolve the promise with the result
        // result messages are formatted differently and have a result payload
        if (result.playId && typeof result.playId === 'number') {
            store.dispatch(setPlayId(String(result['playId'])));
        }
        console.log('Sending RESULT to iframe');
        postMessageToIframe({ type: 'RESULTS_RECEIVED', result });
    }
};

export const isGAPProvider = (provider: GameProvider | string) => {
    const providers = [
        'PLAYTECH',
        'NETENT',
        'RELAX',
        'GAMING_REALMS',
        'SLOTMILL',
        'PRAGMATIC_SLOTS',
        'PRAGMATIC_BINGO',
        'G_GAMES',
        'EVERI',
        'ONEXTWO',
        'GAP',
    ];

    return providers.includes(provider);
};

export const isRGSGame = (gameId: string, games: Game[]): boolean => {
    const game = games.find((game) => game.gameId === gameId);
    if (game) {
        return isGAPProvider(game.metadata.company);
    }
    return false;
};

export const attemptOpenBankDetails = (toggleSidebarOpen: () => void, history: History) => {
    const {
        user: { verificationStatus },
    } = store.getState();

    toggleSidebarOpen();
    switch (verificationStatus) {
        case UserVerificationStatus.NOT_YET_VERIFIED:
            const payload: GetVerifiedModalProps = {
                title: 'Verification required',
                description: 'Your Chumba account needs to be verified before you can link your bank account.',
            };

            store.dispatch(queueModalFront(getGetVerifiedModalProps(payload)));
            return;
        case UserVerificationStatus.PENDING:
            store.dispatch(queueModalFront(getPendingVerificationModalProps()));
            return;
        case UserVerificationStatus.REJECT:
            store.dispatch(queueModalFront(getUnsuccessfulVerificationModalProps()));
            return;
        case UserVerificationStatus.REVERIFY:
            store.dispatch(queueModalFront(getReverifyVerificationModalProps()));
            return;
        case UserVerificationStatus.ACCEPT:
        case UserVerificationStatus.VERIFIED:
            history.push('/bank-verification');
            return;
    }
};

export const isSCOrAboveGCTopupThreshold = () => {
    const {
        user: { goldBalance },
        system: { currency },
    } = store.getState();

    return currency === Currency.SWEEPS || goldBalance >= GoldCoinTopup.THRESHOLD;
};
