import { CookieOverride } from '../cookie-override/cookie-override';
import { IsPlayerInBucket } from '../random-player-distribution/create-random-player-distribution';

export const isInTestGroupWithCookieOverride = (
    cookieOverride: () => CookieOverride,
    isPlayerInBucket: IsPlayerInBucket
): IsPlayerInBucket => {
    return (playerId: number) => {
        switch (cookieOverride()) {
            case 'SET_ENABLE_OVERRIDE':
                return true;

            case 'SET_DISABLE_OVERRIDE':
                return false;

            case 'OVERRIDE_NOT_SET':
                return isPlayerInBucket(playerId);
        }
    };
};
