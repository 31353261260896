export type GtmEvent =
    | CompletedRegistration
    | PurchaseCompleted
    | FirstPurchaseCompleted
    | Pageload
    | OriginalLocation
    | LoginCompletedEvent;

export const GTM_REGISTERED_EVENT = 'completedsignup';
export const GTM_LOGIN_EVENT = 'logincompleted';
export const GTM_PURCHASED_EVENT = 'paymentdone';
export const GTM_FIRST_PURCHASE_EVENT = 'firstPurchase';
export const GTM_PAGE_LOAD_EVENT = 'pageload';
export const GTM_ORIGINAL_LOCATION_EVENT = 'original-location';

type Origination = 'google' | 'facebook' | 'email';

interface CompletedRegistration {
    event: typeof GTM_REGISTERED_EVENT;
    label: Origination;
    emailHash: string;
    userId: string;
}

interface PurchaseCompleted {
    event: typeof GTM_PURCHASED_EVENT;
    label: string;
    emailHash: string;
    value: number;
    id: string;
    channelIdentification: string;
    unhashedUserId: string;
    userId: string;
}

interface FirstPurchaseCompleted {
    event: typeof GTM_FIRST_PURCHASE_EVENT;
    label: string;
    emailHash: string;
    value: number;
    id: string;
    channelIdentification: string;
    unhashedUserId: string;
    userId: string;
}

interface Pageload {
    event: typeof GTM_PAGE_LOAD_EVENT;
    pagePath: string;
}

interface OriginalLocation {
    event: typeof GTM_ORIGINAL_LOCATION_EVENT;
    originalLocation: string;
}

interface LoginCompletedEvent {
    event: typeof GTM_LOGIN_EVENT;
    emailHashed: string;
    emailAliasRemovedHashed: string;
    emailGmailNormalisedHashed: string;
    accountId: string;
    accountIdHashed: string;
}
