import * as React from 'react';
import { Button } from '../base';
import { ReactComponent as LoadingSpinner } from './assets/loading-spinner.svg';

import './styles.scss';

export const ThemeButton = ({
    className,
    text,
    children,
    loading,
    disabled: disabledProp,
    size = 'md',
    theme = 'red-dark',
    actionType = 'primary',
    ...rest
}: ThemeButtonProps) => {
    const buttonClassNames = `theme-btn--${actionType}-${theme} ${disabledProp ? 'disabled' : ''} ${
        loading ? 'loading' : ''
    } theme-btn--${size} ${className || ''}`;

    const content = text || children; // TODO: remove text prop
    const disabled = disabledProp || loading;

    return (
        <Button className={buttonClassNames} disabled={disabled} {...rest}>
            {loading ? <LoadingSpinner /> : content}
        </Button>
    );
};

type ThemeButtonProps = {
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    actionType?: 'primary' | 'secondary';
    size?: 'sm' | 'md';
    theme?: 'red-dark' | 'blue-light';
    /** @deprecated use an anchor tag component or redirect via the button `onClick` handler */
    to?: string;
    /** @deprecated use children instead */
    text?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
