import { cookieOverride } from '../cookie-override/cookie-override';
import { isInTestGroupWithCookieOverride } from './is-in-test-group-with-cookie-override';
import {
    IsPlayerInBucket,
    createRandomPlayerDistribution,
} from '../random-player-distribution/create-random-player-distribution';

interface ABPlayerDistributionParams {
    bucketPercentages: [number, number];
    seed: string;
    name: string;
}

export const createABTest = (params: ABPlayerDistributionParams): [IsPlayerInBucket, IsPlayerInBucket] => {
    const groupACookieOverride = cookieOverride(params.name, 'A');
    const groupBCookieOverride = cookieOverride(params.name, 'B');

    const [isPlayerInBucketA, isPlayerInBucketB] = createRandomPlayerDistribution(params);

    return [
        isInTestGroupWithCookieOverride(groupACookieOverride, isPlayerInBucketA),
        isInTestGroupWithCookieOverride(groupBCookieOverride, isPlayerInBucketB),
    ];
};
