interface ShouldPresentDailyChallengeSetParams {
    latestDailyChallengeSetIdViewed: string | undefined;
    incomingDailyChallengeSet: string;
}

export const shouldPresentDailyChallengeSet = ({
    incomingDailyChallengeSet,
    latestDailyChallengeSetIdViewed,
}: ShouldPresentDailyChallengeSetParams): boolean => {
    return latestDailyChallengeSetIdViewed !== incomingDailyChallengeSet;
};
