import { ChallengesAction, ClaimChallengeRewardSuccess } from './actions';
import { ChallengesState, InflightChallenge } from './types';

export const initState: ChallengesState = {
    isChallengesEnabled: false,
    hasUnseenClaims: false,
    optIn: {
        loadingStatus: 'NONE',
    },
    challengeList: {
        loadingStatus: 'NONE',
        challenges: [],
        dailyChallengeSetId: '',
    },
    showDailyChallengesCTA: false,
    dailyResetTime: new Date('1970-01-01T09:00:00.000Z').getTime(),
    challengeProgressPercentage: 0,
    allRewardsClaimed: false,
    displayOnboarding: false,
    challengeView: 'LOADING',
};

const calculateAllRewardsClaimed = (challenges: InflightChallenge[]): boolean =>
    challenges.every((challenge) => challenge.status === 'REWARD_CLAIMED');

const calculateAverageCompletedPercent = (challenges: InflightChallenge[]): number => {
    const average =
        challenges
            .map(({ currentProgress, goal }) => (currentProgress / goal) * 100)
            .reduce((total, percentage) => total + percentage, 0) / challenges.length;

    return Math.floor(average);
};

const handleRewardsClaimed = (state: ChallengesState, action: ClaimChallengeRewardSuccess): ChallengesState => {
    const challenges = updateChallengeToRewardClaimedStatus(
        state.challengeList.challenges,
        action.inflightChallengeId
    ).sort(orderById);

    const allRewardsClaimed = calculateAllRewardsClaimed(challenges);
    return {
        ...state,
        challengeList: {
            ...state.challengeList,
            challenges,
        },
        allRewardsClaimed,
    };
};

export const challengesReducer = (state: ChallengesState = initState, action: ChallengesAction): ChallengesState => {
    switch (action.type) {
        case 'RECEIVED_IS_CHALLENGES_ENABLED':
            return {
                ...state,
                isChallengesEnabled: action.isChallengesEnabled,
            };
        case 'REQUEST_USER_OPTED_IN': {
            return {
                ...state,
                optIn: {
                    ...state.optIn,
                    loadingStatus: 'RETRIEVING',
                },
                challengeView: 'LOADING',
            };
        }
        case 'RECEIVED_USER_OPTED_IN':
            return {
                ...state,
                optIn: {
                    loadingStatus: 'RECEIVED',
                    hasOptedIn: action.hasOptedIn,
                },
                challengeView: !action.hasOptedIn ? 'OPT-IN' : state.challengeView,
            };
        case 'REQUEST_FETCH_USER_CHALLENGES':
            if (state.challengeList.loadingStatus !== 'NONE') {
                return state;
            }

            return {
                ...state,
                challengeList: {
                    ...state.challengeList,
                    loadingStatus: 'RETRIEVING',
                },
            };
        case 'RECEIVED_USER_CHALLENGES': {
            const allRewardsClaimed = calculateAllRewardsClaimed(action.payload.challenges);

            const challengeView =
                action.payload.challenges.length === 0
                    ? 'NO-CHALLENGES'
                    : allRewardsClaimed
                    ? 'CHALLENGES-COMPLETED'
                    : 'IN-PROGRESS';

            return {
                ...state,
                challengeList: {
                    loadingStatus: 'RECEIVED',
                    challenges: action.payload.challenges.sort(orderById),
                    dailyChallengeSetId: action.payload.dailyChallengeSetId,
                },
                dailyResetTime: action.payload.dailyResetTime.getTime(),
                challengeProgressPercentage: calculateAverageCompletedPercent(action.payload.challenges),
                allRewardsClaimed,
                challengeView,
            };
        }

        case 'CLAIM_CHALLENGE_REWARD_SUCCESS':
            return handleRewardsClaimed(state, action);

        case 'SET_USER_HAS_UNSEEN_CLAIMS': {
            return {
                ...state,
                hasUnseenClaims: action.hasUnseenClaims,
            };
        }

        case 'RECEIVED_ERROR_FETCHING_CHALLENGES': {
            return {
                ...state,
                challengeList: {
                    ...state.challengeList,
                    loadingStatus: 'ERROR',
                },
            };
        }

        case 'CHALLENGE_SET_ONBOARDING': {
            return {
                ...state,
                displayOnboarding: action.visible,
            };
        }

        case 'SHOW_DAILY_CHALLENGES_CTA': {
            return {
                ...state,
                showDailyChallengesCTA: true,
            };
        }

        case 'CHALLENGE_CARD_VIEWED': {
            return {
                ...state,
                showDailyChallengesCTA: false,
            };
        }

        default: {
            return state;
        }
    }
};

const orderById = (challenge1: InflightChallenge, challenge2: InflightChallenge): number => {
    return challenge1.inflightChallengeId > challenge2.inflightChallengeId ? 1 : -1;
};

const updateChallengeToRewardClaimedStatus = (
    challenges: InflightChallenge[],
    inflightChallengeId: string
): InflightChallenge[] => {
    return challenges.map((challenge) => {
        if (challenge.inflightChallengeId === inflightChallengeId) {
            return {
                ...challenge,
                status: 'REWARD_CLAIMED',
            };
        }

        return challenge;
    });
};
