import React from 'react';

import './generic-modal.scss';

interface Props {
    header: JSX.Element;
    children: React.ReactNode;
}

export const GenericModalLayout: React.FC<Props> = (props: Props) => {
    const { header, children } = props;

    return (
        <div className='modal-frame'>
            {header}
            <div className='content'>{children}</div>
        </div>
    );
};
