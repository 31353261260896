import { cookieOverride } from '../cookie-override/cookie-override';
import { isInTestGroupWithCookieOverride } from './is-in-test-group-with-cookie-override';
import {
    IsPlayerInBucket,
    createRandomPlayerDistribution,
} from '../random-player-distribution/create-random-player-distribution';

interface FeatureRolloutParams {
    rolloutPercentage: number;
    seed: string;
    name: string;
}

export const createFeatureRollout = (params: FeatureRolloutParams): IsPlayerInBucket => {
    const { rolloutPercentage, seed, name } = params;
    const rolloutGroupCookieOverride = cookieOverride(name, 'true');

    const [isPlayerInRolloutGroup] = createRandomPlayerDistribution({
        bucketPercentages: [rolloutPercentage, 100 - rolloutPercentage],
        seed,
    });

    return isInTestGroupWithCookieOverride(rolloutGroupCookieOverride, isPlayerInRolloutGroup);
};
