import { createABTest } from './test-groups/create-AB-test';
import { createABCTest } from './test-groups/create-ABC-test';
import { createFeatureRollout } from './test-groups/create-feature-rollout';

// How to create an experiment or a feature rollout?
// (1) Construct your experiment / feature rollout with the test group functions provided
// (2) Get a seed UUID (i.e. from https://www.uuidgenerator.net/)
// (3) Validate behaviour through cookie overrides
// (4) Measure impact of experiment by querying downstream data (`create-random-player-distribution.ts` include a SQL query template)
// (5) Delete when experiment / feature rollout is complete

// ### Examples ###

// Overrides can be set by running:
// (Group A) `document.cookie = "vgw.chumba.overrides.AB_Test=A";`
// (Group B) `document.cookie = "vgw.chumba.overrides.AB_Test=B";`
// To disable the override, delete the cookie.
export const [isPlayerInTestGroupA, isPlayerInTestGroupB] = createABTest({
    bucketPercentages: [30, 70],
    seed: '590f9e7f-bb7f-4d80-b1af-1af254f4bc41',
    name: 'AB_Test',
});

// Overrides can be set by running:
// (In feature rollout) `document.cookie = "vgw.chumba.overrides.Rollout_Test=true";`
// (Not in feature rollout) `document.cookie = "vgw.chumba.overrides.Rollout_Test=false";`
// To disable the override, delete the cookie.
export const isInTestFeatureRollout = createFeatureRollout({
    rolloutPercentage: 5,
    seed: '8d194407-5ce0-4ae5-8627-0a996db83edb',
    name: 'Rollout_Test',
});

// Overrides can be set by running:
// (Group A - Budget) `document.cookie = "vgw.chumba.overrides.DAILY_BONUS_STREAK_EXPERIMENT=A";`
// (Group B - Generous) `document.cookie = "vgw.chumba.overrides.DAILY_BONUS_STREAK_EXPERIMENT=B";`
// (Group C) `document.cookie = "vgw.chumba.overrides.DAILY_BONUS_STREAK_EXPERIMENT=C";`
// To disable the override, delete the cookie.
export const [isInDailyBonusStreakExperimentGroupA, isInDailyBonusStreakExperimentGroupB, isInDailyBonusStreakControl] =
    createABCTest({
        bucketPercentages: [12.5, 12.5, 75],
        seed: 'c8f410b1-94cf-4229-9789-48760ab5137d',
        name: 'DAILY_BONUS_STREAK_EXPERIMENT',
    });
