import {
    HIDE_MODAL,
    ModalEngineAction,
    ModalActionTypeList,
    ModalEngineState,
    QUEUE_MODAL,
    REPLACE_MODAL,
    QUEUE_MODAL_FRONT,
    POP_MODAL,
    DEQUEUE_MODAL,
} from './types';
import { assertNeverAction, isActionType } from '../action-assertions';
import { LobbyReducer } from '../reducer';

export const initialState: ModalEngineState = {
    queuedModals: [],
};

/**
 * Reducer for applying ModalEngineActions to a ModalEngineState to update the current modal view.
 *
 * @param modalEngineState the current state of the modal engine
 * @param action one of the following actions
 * QUEUE_MODAL adds a new modal to the queue
 * REPLACE_MODAL replaces the active modal with a new modal
 * QUEUE_MODAL_FRONT adds a new modal to the front of the queue (queues don't support this)
 * POP_MODAL removes the modal at the front of the queue (aka dequeue)
 * HIDE_MODAL disables the currently active modal
 */
export const modalReducer: LobbyReducer<ModalEngineState> = (modalEngineState = initialState, action) => {
    if (isActionType<ModalEngineAction>(action, ModalActionTypeList)) {
        switch (action.type) {
            case DEQUEUE_MODAL:
                return {
                    ...modalEngineState,
                    queuedModals: [...modalEngineState.queuedModals.filter((m) => m.type !== action.modalType)],
                };
            case QUEUE_MODAL:
                return {
                    ...modalEngineState,
                    queuedModals: [...modalEngineState.queuedModals, action.modalProps],
                };

            case REPLACE_MODAL:
                return {
                    ...modalEngineState,
                    activeModalProps: action.modalProps,
                };
            case QUEUE_MODAL_FRONT: {
                const newQueue = modalEngineState.activeModalProps
                    ? [modalEngineState.activeModalProps, ...modalEngineState.queuedModals]
                    : modalEngineState.queuedModals;
                return {
                    ...modalEngineState,
                    activeModalProps: action.modalProps,
                    queuedModals: newQueue,
                };
            }
            case POP_MODAL: {
                const remainingModals = [...modalEngineState.queuedModals];
                const modalToBecomeActive = remainingModals.splice(0, 1)[0];
                return {
                    ...modalEngineState,
                    queuedModals: remainingModals,
                    activeModalProps: modalToBecomeActive,
                };
            }
            case HIDE_MODAL: {
                document.body.classList.remove('modal-open', `${modalEngineState.activeModalProps?.type}-modal-open`);
                return {
                    ...initialState,
                    queuedModals: modalEngineState.queuedModals,
                };
            }
            default:
                assertNeverAction(action as never);
        }
    }
    return modalEngineState;
};
