const urlParams = new URLSearchParams(window.location.search);

const DEVICE_CONSENT_MARKETING_COOKIE = `vgw.device.consent.marketing`;
const DEVICE_CONSENT_PERFORMANCE_COOKIE = 'vgw.device.consent.performance';
const MARKETING_CONSENT_CHUMBA_HOST_DOMAIN = `.chumbacasino.com`;

const GEO_BLOCK_PAGE_URL = 'https://www.chumbacasino.com/geo-block';
const WHY_DO_I_NEED_TO_VERIFY_MY_ACCOUNT_URL =
    'https://chumbacasino.zendesk.com/hc/en-us/articles/360052176273-Why-do-I-need-to-verify-my-account';
const CONTACT_SUPPORT_URL = 'https://chumbacasino.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001729833';
const TERMS_AND_CONDITIONS_URL = 'https://www.chumbacasino.com/terms-and-conditions';
const CAN_I_PLAY_FROM_ANYWHERE_URL =
    'https://chumbacasino.zendesk.com/hc/en-us/articles/360051572094-Can-I-play-from-anywhere';
const ACCOUNT_VERIFICATION_MORE_INFO_URL =
    'https://chumbacasino.zendesk.com/hc/en-us/articles/360051410494-What-documents-do-I-need-to-send-to-verify-my-account';
const BANK_VERIFICATION_MORE_INO_URL =
    'https://chumbacasino.zendesk.com/hc/en-us/articles/9211122518681-How-do-I-verify-my-Bank-account';
const ZENDESK_CONTACT_SUPPORT_URL = 'https://chumbacasino.zendesk.com/hc/en-us/requests/new';
const ZENDESK_PURCHASE_HELP_URL =
    'https://chumbacasino.zendesk.com/hc/en-us/articles/360052175593-I-am-having-issues-making-Purchases-help';
const DEVICE_ID_COOKIE: string = 'vgw.device.id';

export const LobbyConstants = {
    urlParams,
    deviceIdCookie: DEVICE_ID_COOKIE,
    deviceConsentMarketingCookie: DEVICE_CONSENT_MARKETING_COOKIE,
    deviceConsentPerformanceCookie: DEVICE_CONSENT_PERFORMANCE_COOKIE,
    marketingConsentChumbaHostDomain: MARKETING_CONSENT_CHUMBA_HOST_DOMAIN,
    geoBlockPageUrl: GEO_BLOCK_PAGE_URL,
    whyDoINeedToVerifyMyAccountUrl: WHY_DO_I_NEED_TO_VERIFY_MY_ACCOUNT_URL,
    contactSupportUrl: CONTACT_SUPPORT_URL,
    zendeskContactSupportUrl: ZENDESK_CONTACT_SUPPORT_URL,
    zendeskPurchaseHelpUrl: ZENDESK_PURCHASE_HELP_URL,
    termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL,
    canIPlayFromAnywhereUrl: CAN_I_PLAY_FROM_ANYWHERE_URL,
    accountVerificationMoreInfoUrl: ACCOUNT_VERIFICATION_MORE_INFO_URL,
    bankVerificationMoreInfoUrl: BANK_VERIFICATION_MORE_INO_URL,
} as const;
