import {
    isInDailyBonusStreakExperimentGroupA,
    isInDailyBonusStreakExperimentGroupB,
} from 'common/services/experimentation/experiments';
import { ClientToggleConfig } from './client-toggle-config';
import { isCookieToggleEnabled } from './cookie-toggles';
import { ClientConfig } from '../client-config';

class ClientToggleService {
    featureRestrictionsEnabled(): boolean {
        return ClientToggleConfig.ENABLE_FEATURE_RESTRICTIONS;
    }

    questsEnabled(isStaff: boolean): boolean {
        return ClientToggleConfig.ENABLE_QUESTS && (isCookieToggleEnabled('ENABLE_QUESTS') || isStaff);
    }

    tapToFullScreenEnabled(): boolean {
        return ClientToggleConfig.USE_TAP_TO_FULL_SCREEN;
    }

    dailyBonusStreakEnabled(playerId: number): boolean {
        return isInDailyBonusStreakExperimentGroupA(playerId) || isInDailyBonusStreakExperimentGroupB(playerId);
    }

    newSubNavEnabled(): boolean {
        return ClientToggleConfig.ENABLE_NEW_SUB_NAV;
    }

    useConsoleAppender(): boolean {
        return ClientConfig.USE_CONSOLE_APPENDER;
    }

    usePlayerTokenContext(): boolean {
        return isCookieToggleEnabled('ENABLE_PLAYER_TOKEN_CONTEXT');
    }

    gameEventLoggingEnabled(): boolean {
        return isCookieToggleEnabled('ENABLE_GAME_EVENT_LOGGING');
    }

    canInstallPwa(): boolean {
        return isCookieToggleEnabled('ALLOW_CHUMBA_PWA') || ClientToggleConfig.ALLOW_INSTALL_PWA;
    }
}

export const CLIENT_TOGGLE_SERVICE = new ClientToggleService();
