export const GAME_UNAVAILABLE = 'GAME_UNAVAILABLE';

type GetGameUnavailableModalProps = {
    heading: string;
    body: string;
};

export const getGameUnavailableModalProps = ({ heading, body }: GetGameUnavailableModalProps) =>
    ({
        type: GAME_UNAVAILABLE,
        heading,
        body,
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false,
        id: GAME_UNAVAILABLE,
    } as const);
