export const GC_TOPUP = 'GC_TOPUP';

export const getGCTopupModalProps = (amount: number, redirectUrl?: string) => {
    return {
        type: GC_TOPUP,
        shouldCloseOnOverlayClick: true,
        shouldCloseOnEsc: true,
        amount: amount,
        redirectUrl: redirectUrl,
    } as const;
};
