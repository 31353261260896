import { Appender } from './appender';
import { createCompositeAppender } from './composite-appender';
import { LogBody, LogLevel, LogMessage } from './message';

export let logger: Logger;
export const createLogger = (...appenders: Appender[]): Logger => {
    logger = new LoggerImpl(createCompositeAppender(...appenders));
    return logger;
};

interface Logger {
    error(...args: unknown[]): void;
    warn(...args: unknown[]): void;
    info(data: LogBody): void;
    flush(): void;
    setCoreConfig(sessionId: string, userId: number): void;
}

class LoggerImpl implements Logger {
    private userId?: number;
    private sessionId?: string;

    constructor(private readonly _appender: Appender) {}

    error(...args: unknown[]): void {
        const formatted = args.map(this.formatMessage);
        this._appender.append(
            this.constructLogMessage('ERROR', {
                data: formatted,
            })
        );
    }

    warn(...args: unknown[]): void {
        const formatted = args.map(this.formatMessage);
        this._appender.append(this.constructLogMessage('WARN', { data: formatted }));
    }

    info(data: LogBody): void {
        this._appender.append(this.constructLogMessage('INFO', data));
    }

    flush(): Promise<void> {
        return this._appender.flush();
    }

    setCoreConfig(sessionId: string, userId: number): void {
        this.userId = userId;
        this.sessionId = sessionId;
    }

    private formatMessage(msg: unknown): string {
        if (msg instanceof Error) {
            return `${msg.toString()}, ${msg.stack && msg.stack.toString()}`;
        }

        if (typeof msg == 'object') {
            return `${JSON.stringify(msg)}`;
        }

        return `${msg}`;
    }

    private constructLogMessage(level: LogLevel, body: LogBody): LogMessage {
        return {
            timestamp: new Date().toISOString(),
            level,
            userId: this.userId,
            lobbyCommitHash: window.LOBBY_COMMIT_HASH,
            source: {
                pageName: (document && document.title) || '',
                path: window.location.pathname,
            },
            clientLoggingSessionId: this.sessionId,
            body: { ...body },
        };
    }
}
