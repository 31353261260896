import { getEducationModalShownKey, getLocalStorageValue, initialiseLocalStorageValue } from 'helpers/localstorage';
import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { AppState } from 'store/app-state';
import { queueModal } from 'store/modal/actions';
import { EducateNewUserAction } from '../actions';
import { getEducationModalQueueProps } from '../../../components/domain/education/education-modal-prop-creators';

const alreadySeenEducationModal = (key: string): boolean => {
    return getLocalStorageValue(key);
};

const shouldHandleAction = (appState: AppState): boolean => {
    return appState.user.isFirstSession;
};

const handleAction = async (dispatch: Dispatch<AppAction>, appState: AppState): Promise<void> => {
    const educationKey = getEducationModalShownKey(appState.user.userId);

    if (alreadySeenEducationModal(educationKey)) return;

    dispatch(queueModal(getEducationModalQueueProps()));
    initialiseLocalStorageValue(educationKey, true);
};

/**
 * Show Education Modal once for new user's that have logged in for the first time.
 */
export const educateNewUserHandler = async (
    action: EducateNewUserAction,
    dispatch: Dispatch<AppAction>,
    appState: AppState
) => {
    try {
        if (shouldHandleAction(appState)) {
            await handleAction(dispatch, appState);
        }
    } catch (error) {
        throw error;
    } finally {
        if (action.next) dispatch(action.next);
    }
};
