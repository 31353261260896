import {
    GtmEvent,
    GTM_REGISTERED_EVENT,
    GTM_PURCHASED_EVENT,
    GTM_FIRST_PURCHASE_EVENT,
    GTM_PAGE_LOAD_EVENT,
    GTM_ORIGINAL_LOCATION_EVENT,
} from './tag-types';
import { createWiretapMiddleware } from 'common/services/analytics/wiretap';
import {
    getHashedValue,
    removeAliasFromEmail,
    sanitiseEmail,
    splitDomainAndValue,
} from 'common/services/analytics/helpers';
import { REGISTERED, PURCHASED, LOGIN_USER_FOR_GTM_EVENT } from '../../../../store/user/types';
import { trackingBlocked } from 'common/services/analytics/consent';
import { PAGE_LOAD } from 'store/ui/types';
import { AppState } from '../../../../store/app-state';
import { AppAction } from '../../../../store/app-action';
import { INIT_APP } from 'store/system/types';

/**
 * Created a Redux middleware that will intercept, transform and report certain actions to Google Tag Manager.
 */
export function createGTMMiddleware() {
    return createWiretapMiddleware<AppAction, AppState>((action, getState) => {
        const gtmEvents = mapActionsToGTMEvents(action, getState);
        if (!gtmEvents) {
            return;
        }

        if (Array.isArray(gtmEvents)) {
            for (const event of gtmEvents) {
                raiseGtm(event);
            }
            return;
        }
        raiseGtm(gtmEvents);
    });
}

const persisted: GtmEvent[] = [];

function raiseGtm(...events: GtmEvent[]) {
    // If GTM is unavailable "yet" (probably due to ordering of script tags), we will persist it and push them once it is available
    const gtm = window.dataLayer;
    if (events.length) {
        persisted.push(...events);
    }

    if (!gtm) {
        setTimeout(() => raiseGtm(), 1000);
        return;
    }

    if (persisted.length) {
        gtm.push(...persisted);
        persisted.splice(0);
    }
}

/**
 * Maps Redux actions to the GtmEvent equivalent; returns `undefined` if no mapping.
 */
function mapActionsToGTMEvents(action: AppAction, getState: () => AppState): GtmEvent[] | GtmEvent | undefined {
    const {
        user: { username: email, userId },
    } = getState();

    if (trackingBlocked) {
        return undefined;
    }

    const emailHash = getHashedValue(email);
    const userIdString = userId.toString();

    switch (action.type) {
        case REGISTERED: {
            return {
                event: GTM_REGISTERED_EVENT,
                label: action.channel === 'GOOGLE' ? 'google' : action.channel === 'FACEBOOK' ? 'facebook' : 'email',
                emailHash,
                userId: getHashedValue(userIdString), // hashed to match the server-sent userId for identity matching - see https://virtualgamingworlds.atlassian.net/browse/MP-378
            };
        }

        case LOGIN_USER_FOR_GTM_EVENT: {
            const splittedEmail = splitDomainAndValue(action.payload.email);
            const emailWithoutAlias = removeAliasFromEmail(splittedEmail.value);
            const emailSanitised = sanitiseEmail(emailWithoutAlias, splittedEmail.domain);

            return {
                event: 'logincompleted',
                emailHashed: getHashedValue(action.payload.email.trim()),
                emailAliasRemovedHashed: getHashedValue(emailWithoutAlias + splittedEmail.domain),
                emailGmailNormalisedHashed: getHashedValue(emailSanitised + splittedEmail.domain),
                accountId: String(action.payload.accountId),
                accountIdHashed: getHashedValue(String(action.payload.accountId)),
            };
        }

        case PURCHASED: {
            const events: GtmEvent[] = [];

            if (action.isFirstPurchase) {
                events.push({
                    event: GTM_FIRST_PURCHASE_EVENT,
                    label: action.packageName,
                    emailHash,
                    value: action.usdAmount,
                    id: action.transactionId,
                    channelIdentification: action.channelIdentification,
                    unhashedUserId: userIdString,
                    userId: getHashedValue(userIdString), // hashed to match the server-sent userId for identity matching - see https://virtualgamingworlds.atlassian.net/browse/MP-378
                });
            }

            events.push({
                event: GTM_PURCHASED_EVENT,
                label: action.packageName,
                emailHash,
                value: action.usdAmount,
                id: action.transactionId,
                channelIdentification: action.channelIdentification,
                unhashedUserId: userIdString,
                userId: getHashedValue(userIdString), // hashed to match the server-sent userId for identity matching - see https://virtualgamingworlds.atlassian.net/browse/MP-378
            });

            return events;
        }

        case PAGE_LOAD: {
            return {
                event: GTM_PAGE_LOAD_EVENT,
                pagePath: action.path,
            };
        }

        // Fixes problem with how Google Tag Manger handles pageloads in an SPA.
        // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
        case INIT_APP: {
            return {
                event: GTM_ORIGINAL_LOCATION_EVENT,
                originalLocation: window.location.href,
            };
        }
    }

    return undefined;
}
