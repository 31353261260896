import * as React from 'react';
import { Button, ButtonProps } from '../base';

import './styles.scss';

export const ThemeTextButton = ({ className, context, size, ...rest }: ThemeTextButtonProps & ButtonProps) => {
    const buttonClassNames = `theme-text-btn ${context ? `theme-text-btn--${context}` : ''} theme-text-btn--${size} ${
        className || ''
    }`;
    return <Button className={buttonClassNames} {...rest} />;
};

type ThemeTextButtonProps = {
    context?: 'light';
    size: 'sm' | 'md' | 'lg';
    className?: string;
    to?: string;
};
