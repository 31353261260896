import { DeviceType } from './types';

const machineActionsMapper = {
    machineFeatureStart: 'MACHINE__FEATURE__START',
    machineLoaded: 'MACHINE__LOADED',
    machineSpin: 'MACHINE__SPIN',
    machineAutoPlayStart: 'MACHINE__AUTO_PLAY__START',
    machineAutoPlayStop: 'MACHINE__AUTO_PLAY__STOP',
    machineChangeBetDown: 'MACHINE__CHANGE_BET__DOWN',
    machineChangeBetUp: 'MACHINE__CHANGE_BET__UP',
    machineChangeLinesDown: 'MACHINE__CHANGE_LINES__DOWN',
    machineChangeLinesUp: 'MACHINE__CHANGE_LINES__UP',
    machineAutoPlay: 'MACHINE__AUTO_PLAY',
    machineMaxBet: 'MACHINE__MAX_BET',
    machinePayTable: 'MACHINE__PAY_TABLE',
} as const;

export function mapMachineActionNames(inputActionName: string, deviceType: DeviceType): string | undefined {
    const baseAction = machineActionsMapper[inputActionName as keyof typeof machineActionsMapper];
    if (baseAction === undefined) {
        console.error(`Unknown Machine Action [${inputActionName}]`);
        return;
    }
    return deviceType === 'DESKTOP' ? `D__${baseAction}` : `M__${baseAction}`;
}
