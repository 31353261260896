import { OFFER_MODAL, CONFIRM_MODAL, OFFER_ERROR_MODAL, STORE_MODAL, ConfirmModalProps } from './prop-types';
import { Package } from 'store/store/types';
import { OfferErrorType } from './prop-types';
import { StoreEntryFrom } from 'common/services/analytics/store/store-types';

interface OfferModalProps {
    offer: Package;
}

interface GetOfferErrorModalProps {
    errorType: OfferErrorType;
}

export const getOfferModalProps = ({ offer }: OfferModalProps) =>
    ({
        type: OFFER_MODAL,
        offer,
    } as const);

export const getConfirmModalProps = ({ confirm, cancel, isPromoPackage }: ConfirmModalProps) =>
    ({
        type: CONFIRM_MODAL,
        confirm,
        cancel,
        isPromoPackage,
    } as const);

export const getOfferErrorModalProps = ({ errorType }: GetOfferErrorModalProps) =>
    ({
        type: OFFER_ERROR_MODAL,
        errorType,
    } as const);

export const getStoreModalProps = (entryFrom: StoreEntryFrom = StoreEntryFrom.OTHER, previewCashierId?: string) => {
    return {
        type: STORE_MODAL,
        shouldCloseOnEsc: false,
        previewCashierId,
        entryFrom,
    } as const;
};
