import { logger } from 'common/services/log/logger';
import { getOfferModalProps } from 'components/domain/packages/modals/props-creator';
import { getDismissOfferModalKey, getLocalStorageValue } from 'helpers/localstorage';
import { filterExpired } from 'helpers/packages';
import { getQueryParameter } from 'helpers/url';
import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { AppState } from 'store/app-state';
import { queueModal } from 'store/modal/actions';
import { SpecialOffersAction } from '../actions';
import { Package } from '../types';

const getOfferPackage = (specialOffers: Package[], userId: number, packageUuid?: string): Package | undefined => {
    return packageUuid
        ? specialOffers.find((offer) => offer.packageUuid === packageUuid)
        : specialOffers.filter(filterExpired).find(({ offerId }) => {
              const modalDismissedKey = getDismissOfferModalKey(userId, offerId);
              const modalDismissed = getLocalStorageValue(modalDismissedKey);
              return !modalDismissed;
          });
};

const shouldHandleAction = (appState: AppState): boolean => {
    const { isUserInitialisationComplete, promotionalPlay, blockedFeatures } = appState.system;
    const isUserAllowedToSeeOffers = !blockedFeatures.includes('OFFERS');

    return isUserAllowedToSeeOffers && isUserInitialisationComplete && promotionalPlay === 'ENABLED';
};

const handleAction = async (dispatch: Dispatch<AppAction>, appState: AppState): Promise<void> => {
    const { specialOffers } = appState.store;
    const { userId } = appState.user;

    const packageUuid = getQueryParameter('offer') ?? undefined; // PackageUuid is the offerId
    const offer = getOfferPackage(specialOffers, userId, packageUuid);
    if (offer) {
        dispatch(queueModal(getOfferModalProps({ offer })));
    }
};

export const specialOffersHandler = async (
    action: SpecialOffersAction,
    dispatch: Dispatch<AppAction>,
    appState: AppState
) => {
    try {
        if (shouldHandleAction(appState)) {
            await handleAction(dispatch, appState);
        }
    } catch (error) {
        logger.error(error);
    } finally {
        if (action.next) dispatch(action.next);
    }
};
