import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { ThemeButton } from '../../../elements/button/theme-buttons';
import { GenericModalLayout } from './generic-modal';
import HeaderImage from './get-verified-modal.png';

import './get-verified-modal.scss';

export interface GetVerifiedModalProps {
    title: string;
    description: string;
}

export const GetVerifiedModal = ({ title, description }: GetVerifiedModalProps) => {
    const dispatch = useDispatch();

    return (
        <GenericModalLayout
            header={
                <div className='image-parent'>
                    <img className='header-image' src={HeaderImage} alt={title} />
                </div>
            }
        >
            <div className='get-verified-modal__container'>
                <h2>{title}</h2>
                <p>{description}</p>

                <div className='modal-btns'>
                    <ThemeButton
                        id='dialog-GET_VERIFIED__get-verified-btn'
                        className='primary-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        to='/account-verification'
                        onClick={() => dispatch(hideModal(GET_VERIFIED_MODAL))}
                    >
                        GET VERIFIED
                    </ThemeButton>

                    <ThemeButton
                        id='dialog-GET_VERIFIED__not-now-btn'
                        theme='red-dark'
                        actionType='secondary'
                        size='md'
                        onClick={() => dispatch(hideModal(GET_VERIFIED_MODAL))}
                    >
                        NOT NOW
                    </ThemeButton>
                </div>
                <a
                    className='zendesk-link'
                    href='https://chumbacasino.zendesk.com/hc/en-us/articles/360052176273-Why-do-I-need-to-verify-my-account'
                    target='_blank'
                >
                    Why is this needed?
                </a>
            </div>
        </GenericModalLayout>
    );
};

export const getGetVerifiedModalProps = ({ title, description }: GetVerifiedModalProps) =>
    ({
        type: GET_VERIFIED_MODAL,
        title,
        description,
    } as const);

export const GET_VERIFIED_MODAL = 'GET_VERIFIED_MODAL';
