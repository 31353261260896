import * as React from 'react';

interface CloseButtonProperties {
    onCloseClick: () => void;
    id?: string;
}
export const CloseButton: React.FC<CloseButtonProperties> = ({ onCloseClick, id }: CloseButtonProperties) => (
    <button onClick={onCloseClick} className='btn btn-icon' id={id}>
        &times;
    </button>
);
