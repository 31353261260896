import {
    Jackpots,
    SWITCH_JACKPOT_DISPLAY,
    SwitchJackpotDisplayAction,
    UPDATE_GRAND_JACKPOT,
    UPDATE_JACKPOTS,
    UPDATE_MAJOR_JACKPOT,
    UpdateGrandJackpotAction,
    UpdateJackpotsAction,
    UpdateMajorJackpotAction,
} from './types';

export function updateJackpots(grandJackpots: Jackpots, majorJackpots: Jackpots): UpdateJackpotsAction {
    return {
        type: UPDATE_JACKPOTS,
        grandJackpots: grandJackpots,
        majorJackpots: majorJackpots,
    };
}

export function updateGrandJackpot(name: string, goldCoinValue: number, sweepsValue: number): UpdateGrandJackpotAction {
    return {
        type: UPDATE_GRAND_JACKPOT,
        name,
        values: {
            goldCoinValue,
            sweepsValue,
        },
    };
}

export function updateMajorJackpot(name: string, goldCoinValue: number, sweepsValue: number): UpdateMajorJackpotAction {
    return {
        type: UPDATE_MAJOR_JACKPOT,
        name,
        values: {
            goldCoinValue,
            sweepsValue,
        },
    };
}

export function switchJackpotDisplay(): SwitchJackpotDisplayAction {
    return {
        type: SWITCH_JACKPOT_DISPLAY,
    };
}
