import sha256 from 'crypto-js/sha256';

export type Email = {
    value: string;
    domain: string;
};

export function getHashedValue(value: string): string {
    return sha256(value.toLowerCase()).toString();
}

export function splitDomainAndValue(email: string): Email {
    const split = email.trim().split('@');
    return {
        value: split[0],
        domain: '@' + split[1],
    };
}

export function removeAliasFromEmail(value: string): string {
    const plusIndex = value.indexOf('+');
    if (plusIndex != -1) {
        return value.substring(0, plusIndex);
    }
    return value;
}

export function sanitiseEmail(value: string, domain: string): string {
    //removing dots
    if (domain.includes('gmail')) {
        return value.replace(/\./g, '').toLowerCase();
    }
    return value.toLowerCase();
}
