export interface IsPlayerIdInBucketParams {
    playerId: number;
    seed: string;
    bucketPercentageMin: number;
    bucketPercentageMax: number;
    hashFunction: (message: string) => string;
}

export const isPlayerIdInBucket = ({
    playerId,
    seed,
    bucketPercentageMin,
    bucketPercentageMax,
    hashFunction,
}: IsPlayerIdInBucketParams) => {
    // Construct unique hash for a given playerId and seed
    // We assume the output of the hash function is constructed of a series of hexadecimal values.
    // We only take the first 8 characters (32 bits of randomness, which is plenty, and simplifies database queries).
    const hash = hashFunction(`${playerId}-${seed}`).substring(0, 8);

    // Before normalising, we first need to understand the max value of the hash
    const maxHashHexadecimalValue = Math.pow(16, hash.length) - 1;

    // Normalise to [0, 100]
    const normalisedValue = (parseInt(hash, 16) / maxHashHexadecimalValue) * 100;

    // Specifically handle lower bound.
    if (normalisedValue === 0 && bucketPercentageMin === 0) return true;

    // All bucket ranges are inclusive of upper bound, except for first one (which is inclusive of lower bound also).
    // For example, 5 bucket ranges of equal width result in the following ranges: [0, 20], (20, 40], (40, 60], (60, 80], (80, 100]
    return normalisedValue > bucketPercentageMin && normalisedValue <= bucketPercentageMax;
};
