import React, { ReactChild } from 'react';
import './standard-modal.scss';

import { ThemeButton } from 'components/elements/button/theme-buttons/theme-button';

interface ModalButtonProps {
    onClick: () => void;
    text: string;
    id?: string;
    className?: string;
}

interface StandardModalLayoutProperties {
    header: ReactChild;
    body: ReactChild;
    kind: 'STANDARD' | 'ERROR';
    closeButtonProps: ModalButtonProps;
    confirmButtonProps?: ModalButtonProps;
    svgSource?: string;
}

export const StandardModalLayout: React.FC<StandardModalLayoutProperties> = ({
    header,
    body,
    svgSource,
    kind,
    closeButtonProps,
    confirmButtonProps,
}: StandardModalLayoutProperties) => {
    return (
        <div id='standard-modal' className='standard-modal__container'>
            <h2 className={kind === 'ERROR' ? 'error-heading' : 'standard-heading'}>{header}</h2>
            <div className='content-container'>
                {svgSource && (
                    <div className='inline-modal-icon'>
                        <img src={svgSource} alt={'icon'} />
                    </div>
                )}
                <div className='comment'>{body}</div>
            </div>
            <div className='standard-modal__button-container'>
                {confirmButtonProps && (
                    <ThemeButton
                        onClick={confirmButtonProps.onClick}
                        size='md'
                        actionType='primary'
                        text={confirmButtonProps.text}
                        className='theme-btn__modal'
                        id={confirmButtonProps.id}
                    />
                )}
                <ThemeButton
                    onClick={closeButtonProps.onClick}
                    size='md'
                    actionType={confirmButtonProps ? 'secondary' : 'primary'}
                    text={closeButtonProps.text}
                    className='theme-btn__modal'
                    id={closeButtonProps.id}
                />
            </div>
        </div>
    );
};
