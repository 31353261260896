import { DueDiligenceStatus, UserVerificationStatus } from 'store/user/types';

export function mapDueDiligenceStatusToVerificationStatus(
    verificationStatus: DueDiligenceStatus | null
): UserVerificationStatus {
    switch (verificationStatus) {
        case 'ACCEPT':
            return UserVerificationStatus.ACCEPT;
        case 'VERIFIED':
            return UserVerificationStatus.VERIFIED;
        case 'PENDING':
            return UserVerificationStatus.PENDING;
        case 'REJECT':
            return UserVerificationStatus.REJECT;
        case 'REVERIFY':
            return UserVerificationStatus.REVERIFY;
        case 'NOT_YET_VERIFIED':
        case 'SDD_ACCEPT':
            return UserVerificationStatus.NOT_YET_VERIFIED;
        default:
            return UserVerificationStatus.NOT_YET_VERIFIED;
    }
}
