import axios from 'axios';
import { TwoKVerificationCompliance, VerificationCompliance } from './types';
import { CustomerService } from './customer-service-interface';
import { logger } from '../log/logger';
import { mapToTwoKVerificationCompliance } from './response-mapper';

export const CustomerHttpService = (url: string): CustomerService => {
    const getVerificationCompliance = async (playerId: number): Promise<VerificationCompliance> => {
        try {
            const { data } = await sendGet<VerificationCompliance>(`${url}/verification-compliance/${playerId}`);
            return data;
        } catch (err: unknown) {
            const safeErrorMessage = axios.isAxiosError(err)
                ? JSON.stringify({
                      message: err.message,
                      code: err.code,
                      responseStatus: err.response?.status,
                      responseData: err.response?.data,
                  })
                : err;
            logger.error(`Verification Compliance request to customer API did not succeed, failing open`, {
                safeErrorMessage,
            });
            return { message: 'NO_ACTION_REQUIRED' };
        }
    };

    const getTwoKVerificationCompliance = async (playerId: number): Promise<TwoKVerificationCompliance> => {
        try {
            const { data } = await sendGet<TwoKVerificationCompliance>(
                `${url}/verification-compliance/two-k-verification/${playerId}`
            );
            return mapToTwoKVerificationCompliance(data);
        } catch (err: unknown) {
            const safeErrorMessage = axios.isAxiosError(err)
                ? JSON.stringify({
                      message: err.message,
                      code: err.code,
                      responseStatus: err.response?.status,
                      responseData: err.response?.data,
                  })
                : err;
            logger.error(`Two K Verification Compliance request to customer API did not succeed, failing open`, {
                safeErrorMessage,
            });
            return { message: 'NO_ACTION_REQUIRED' };
        }
    };

    const sendGet = async <T>(url: string) => await axios.get<T>(url, { withCredentials: true });

    return { getVerificationCompliance, getTwoKVerificationCompliance };
};
