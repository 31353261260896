import fetch from 'node-fetch';
import { GameId } from 'store/layout/types';
import { ClientConfig } from '../config/client-config';

interface CategoryService {
    getLobbyCategories: () => Promise<Category[]>;
}

export class LobbyCategoryService implements CategoryService {
    constructor() {}

    async getLobbyCategories(): Promise<Category[]> {
        const categoriesResponse = await fetch(
            ClientConfig.REQUEST_PATH_HOST_SEGMENT + '/api/lobby-management/categories',
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', credentials: 'include' },
            }
        );

        if (categoriesResponse.status === 200) {
            const { categories } = await categoriesResponse.json();
            return categories;
        }

        return [];
    }
}

export const lobbyCategoriesService = new LobbyCategoryService();

export type Category = {
    categoryId: string | undefined;
    title: string;
    iconRef?: CategoryIconReference;
    infoBlob?: string;
    showStandardPlayTooltip?: boolean;
    customTooltipCallback?: () => void;
    tileSize: CategoryTileSize;
    games: GameId[]; // name from chumba_casino.game
    dynamicRef?: DynamicCategoryReference;
    enabled: boolean;
    linkTo?: string | undefined;
};

export enum CategoryIconReference {
    NO_ICON = 'NO_ICON',
    GREEN_PLAY = 'GREEN_PLAY',
    STARS = 'STARS',
    FIRE = 'FIRE',
    THUMBS_UP = 'THUMBS_UP',
    DIAMOND = 'DIAMOND',
    CHUMBA_C = 'CHUMBA_C',
    FAVORITE = 'FAVORITE',
    TAG = 'TAG',
    CARDS = 'CARDS',
    SLINGO = 'SLINGO',
    GOLD_COIN = 'GOLD_COIN',
    FIRESHOT = 'FIRESHOT',
    HOLD_AND_SPIN = 'HOLD_AND_SPIN',
    CLASSIC_SLOTS = 'CLASSIC_SLOTS',
    COWBOY_HAT = 'COWBOY_HAT',
    TRIDENT = 'TRIDENT',
    PLAYTECH = 'PLAYTECH',
    REELPLAY = 'REELPLAY',
    SLOTS = 'SLOTS',
    BATTLE_ROYALE = 'BATTLE_ROYALE',
}

export enum DynamicCategoryReference {
    JACKPOTS = 'JACKPOTS',
    TABLE_GAMES = 'TABLE_GAMES',
    SLINGO = 'SLINGO',
    GOLD_SERIES_GAMES = 'GOLD_SERIES_GAMES',
    MY_FAVOURITES = 'MY_FAVOURITES',
    PLAY_AGAIN = 'PLAY_AGAIN',
    NEW_RELEASES = 'NEW_RELEASES',
    TOP_10 = 'TOP_10',
    RECOMMENDED_FOR_YOU = 'RECOMMENDED_FOR_YOU',
    BATTLE_ROYALE = 'BATTLE_ROYALE',
}

type CategoryTileSize = 'sml' | 'lg';
