// Desktop Actions
export const D__LOBBY_LOADED = 'D__LOBBY_LOADED';
export const D__LOBBY__SELECT_MACHINE = 'D__LOBBY__SELECT_MACHINE';
export const D__TWO_K_VERIFY__CROSS = 'D__TWO_K_VERIFY__CROSS';
export const D__TWO_K_VERIFY__CONTINUE_PLAYING = 'D__TWO_K_VERIFY__CONTINUE_PLAYING';
export const D__TWO_K_VERIFY__VERIFY_NOW = 'D__TWO_K_VERIFY__VERIFY_NOW';
export const D__TWO_K_VERIFY__ZENDESK_LINK = 'D__TWO_K_VERIFY__ZENDESK_LINK';
export const D__TWO_K_VERIFY__VIEWED = 'D__TWO_K_VERIFY__VIEWED';

// Mobile Actions
export const M__LOBBY_LOADED = 'M__LOBBY_LOADED';
export const M__LOBBY__SELECT_MACHINE = 'M__LOBBY__SELECT_MACHINE';
export const M__ROTATE_DEVICE__OPEN = 'M__ROTATE_DEVICE__OPEN';
export const M__ROTATE_DEVICE__CLOSE = 'M__ROTATE_DEVICE__CLOSE';
export const M__TWO_K_VERIFY__CROSS = 'M__TWO_K_VERIFY__CROSS';
export const M__TWO_K_VERIFY__CONTINUE_PLAYING = 'M__TWO_K_VERIFY__CONTINUE_PLAYING';
export const M__TWO_K_VERIFY__VERIFY_NOW = 'M__TWO_K_VERIFY__VERIFY_NOW';
export const M__TWO_K_VERIFY__ZENDESK_LINK = 'M__TWO_K_VERIFY__ZENDESK_LINK';
export const M__TWO_K_VERIFY__VIEWED = 'M__TWO_K_VERIFY__VIEWED';
export const SWIPE_UP_DIALOG_ON = 'SWIPE_UP_DIALOG_ON';
export const SWIPE_UP_DIALOG_OFF = 'SWIPE_UP_DIALOG_OFF';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const TAP_TO_FULLSCREEN_MODAL_SHOWN = 'TAP_TO_FULLSCREEN_MODAL_SHOWN';
export const TAP_TO_FULLSCREEN_MODAL_CLOSED = 'TAP_TO_FULLSCREEN_MODAL_CLOSED';

// Cashier Actions
export const STORE_LOADED = 'STORE_LOADED';

// Freeplay actions
export const STORE_PLAYFORFREE_DAILY_BONUS_MOREINFO = 'STORE_PLAYFORFREE_DAILY_BONUS_MOREINFO';
export const STORE_PLAYFORFREE_FACEBOOK_FOLLOW = 'STORE_PLAYFORFREE_FACEBOOK_FOLLOW';
export const STORE_PLAYFORFREE_POSTAL_REQUEST_MOREINFO = 'STORE_PLAYFORFREE_POSTAL_REQUEST_MOREINFO';
export const STORE_PLAYFORFREE_FREE_GC_MOREINFO = 'STORE_PLAYFORFREE_FREE_GC_MOREINFO';

// Top HUD Actions
export const HUD_CURRENCY_SWITCH = 'HUD_CURRENCY_SWITCH';
export const HUD_CURRENCY_INFO = 'HUD_CURRENCY_INFO';
export const HUD_HOME_LOGO_CLICKED = 'HUD_HOME_LOGO_CLICKED';
export const HUD_SIDE_BAR_TOGGLED = 'HUD_SIDE_BAR_TOGGLED';
export const HUD_BUY_CLICKED = 'HUD_BUY_CLICKED';

// Notifications
export const NOTIFICATION_TRAY = 'NOTIFICATION_TRAY';
export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTIFICATION_ACTIONED = 'NOTIFICATION_ACTIONED';

// Notification states
export const OPEN = 'OPEN';
export const CLOSED = 'CLOSED';

// Modal Actions
export const MODAL_SHOWN = 'MODAL_SHOWN';
export const MODAL_SELECTED = 'MODAL_SELECTED';
export const MODAL_CLOSED = 'MODAL_CLOSED';

export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';

export const REDEEM_SELECTED = 'REDEEM_SELECTED';

export type OfferModalPayload = { offerId: string };

export const MOBILE = 'MOBILE';
export const DESKTOP = 'DESKTOP';

export const TOP_HUD_NAME = 'TOP_HUD';
export const SIDE_BAR_NAME = 'SIDE_BAR';

// Category Names
export const SEARCH_NAME = 'SEARCH'; //TO DO Update Clickstream action name
export const HOME_NAME = 'HOME';
export const INSTANT_WIN_NAME = 'INSTANT_WIN';
export const SLOTS_NAME = 'SLOTS';
export const JACKPOT_NAME = 'JACKPOT';
export const TABLE_GAMES_NAME = 'TABLE_GAMES';
export const SLINGO_NAME = 'SLINGO';
export const SCRATCHCARD_NAME = 'SCRATCHCARD';
export const BINGO_LOBBY_NAME = 'BINGO_LOBBY';

// Activity Reminder Modal
export const ACTIVITY_REMINDER_MODAL_INFO_SELECTED = 'ACTIVITY_REMINDER_MODAL_INFO_SELECTED';
export const ACTIVITY_REMINDER_MODAL_GC_SELECTED = 'ACTIVITY_REMINDER_MODAL_GC_SELECTED';
export const ACTIVITY_REMINDER_MODAL_SC_SELECTED = 'ACTIVITY_REMINDER_MODAL_SC_SELECTED';
export const ACTIVITY_REMINDER_MODAL_CONTINUE_SELECTED = 'ACTIVITY_REMINDER_MODAL_CONTINUE_SELECTED';
export const ACTIVITY_REMINDER_MODAL_LOGOUT_SELECTED = 'ACTIVITY_REMINDER_MODAL_LOGOUT_SELECTED';
export const ACTIVITY_REMINDER_MODAL_ADJUST_REMINDERS_SELECTED = 'ACTIVITY_REMINDER_MODAL_ADJUST_REMINDERS_SELECTED';
export const ACTIVITY_REMINDER_MODAL_GAMEPLAY_HISTORY_SELECTED = 'ACTIVITY_REMINDER_MODAL_GAMEPLAY_HISTORY_SELECTED';

// Activity Reminder Interval Preferences
export const ACTIVITY_REMINDER_PAGE_SELECTED = 'ACTIVITY_REMINDER_PAGE_SELECTED';
export const ACTIVITY_REMINDER_CHANGE_SELECTED = 'ACTIVITY_REMINDER_CHANGE_SELECTED';
export const ACTIVITY_REMINDER_INTERVAL_SELECTED = 'ACTIVITY_REMINDER_INTERVAL_SELECTED';
export const ACTIVITY_REMINDER_PAGE_CLOSE_SELECTED = 'ACTIVITY_REMINDER_PAGE_CLOSE_SELECTED';
export const ACTIVITY_REMINDER_PAGE_CANCEL_SELECTED = 'ACTIVITY_REMINDER_PAGE_CANCEL_SELECTED';
export const ACTIVITY_REMINDER_SET_INTERVAL_SELECTED = 'ACTIVITY_REMINDER_SET_INTERVAL_SELECTED';
export const ACTIVITY_REMINDER_UNSAVED_CHANGES_LEAVE_SELECTED = 'ACTIVITY_REMINDER_UNSAVED_CHANGES_LEAVE_SELECTED';
export const ACTIVITY_REMINDER_UNSAVED_CHANGES_CANCEL_SELECTED = 'ACTIVITY_REMINDER_UNSAVED_CHANGES_CANCEL_SELECTED';
export const ACTIVITY_REMINDER_ERROR_LEAVE_SELECTED = 'ACTIVITY_REMINDER_ERROR_LEAVE_SELECTED';
export const ACTIVITY_REMINDER_ERROR_CANCEL_SELECTED = 'ACTIVITY_REMINDER_ERROR_CANCEL_SELECTED';

export type DeviceType = typeof MOBILE | typeof DESKTOP;

// GC TopUp Actions
export const GC_TOP_UP_CLAIM = 'GC_TOP_UP_CLAIM';
export const GC_TOP_UP_CLAIM_IN_GAME = 'GC_TOP_UP_CLAIM_IN_GAME';

// Game Tile Actions
export const GAME_FAVORITE_EVENT = 'GAME_FAVORITE_EVENT';
export const SCROLL_CATEGORY = 'SCROLL_CATEGORY';

// Search types
export const SEARCH_OPENED = 'SEARCH_OPENED';
export const TERM_SEARCHED = 'TERM_SEARCHED';
export const SEARCH_RESULT_GAME_CLICKED = 'SEARCH_RESULT_GAME_CLICKED';
export const SEARCH_NO_RESULT_FOUND = 'SEARCH_NO_RESULT_FOUND';
export const SEARCH_CANCELLED = 'SEARCH_CANCELLED';

//Dynamic categories types
export const DYNAMIC_CATEGORY_GAME_LAUNCHED = 'DYNAMIC_CATEGORY_GAME_LAUNCHED';
export const DYNAMIC_CATEGORY_TOOLTIP_CLICKED = 'DYNAMIC_CATEGORY_TOOLTIP_CLICKED';

//Quests
export const QUEST_GAME_LAUNCHED = 'QUEST_GAME_LAUNCHED';
