import { GameIframeDataType } from '../components/elements/game-iframe/game-iframe-data-type';
import { ClientConfig } from 'config/client-config';

declare let window: any;

export const getChumbaMonorepoServerApiUrl = ({ gameId, baseMathModel }: GameIframeDataType) => {
    const baseApiUrl = ClientConfig.GAME_SERVER_BASE_URL;

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        return `${baseApiUrl}/${baseMathModel}/${gameId}`;
    }

    return `${baseApiUrl}/models/${baseMathModel}/api`;
};

// This function has a bug when filtering keys.
// The boolean keys with a false value are excluded.
// Should use the following condition to filter keys:
// .filter((key) => parameters[key] !== undefined || parameters[key] !== null)
// Didn't made the change because I have no idea how it will impact the in-house games.
export const generateQueryParameters = (parameters: { [key: string]: any }): string => {
    return Object.keys(parameters)
        .filter((key) => parameters[key])
        .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
        .join('&');
};

export const constructLiveDealerUrl = (parameters: { [key: string]: any }): string => {
    const baseUrl = `${ClientConfig.PLAYTECH_URL}/GameLauncher`;
    const urlParams = generateQueryParameters(parameters);
    return `${baseUrl}?${urlParams}`;
};

export const getQueryParameterNumber = (key: string, defaultValue: number): number => {
    const queryValue = getQueryParameter(key);
    if (!queryValue) {
        return defaultValue;
    }
    const convertedNumber = parseInt(queryValue);
    if (isNaN(convertedNumber)) {
        throw new Error('you attempted to pass a number');
    }
    return convertedNumber;
};

export const getQueryParameter = (key: string): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
};
