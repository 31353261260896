import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeButton } from 'components/elements/button/theme-buttons/theme-button';
import './insufficient-funds-modal.scss';
import { hideModal } from '../../../../store/modal/actions';
import { GenericModalLayout } from 'components/elements/modal/generic/generic-modal-layout';
import { INSUFFICIENT_FUNDS } from '.';
import { isGAPProvider } from '../../../../chumba/third-party-helpers';
import { sendMessageToIframe } from 'chumba/third-party';
import { AppState } from '../../../../store/app-state';

export const InsufficientFundsModal: React.FC = () => {
    const dispatch = useDispatch();
    const company = useSelector((state: AppState) => state.game.company);

    const closeModal = () => {
        if (!isGAPProvider(company)) {
            sendMessageToIframe('INSUFFICIENT_FUNDS_CLOSE');
        }
        dispatch(hideModal(INSUFFICIENT_FUNDS));
    };

    const continueMaxPlay = () => {
        sendMessageToIframe('INSUFFICIENT_FUNDS_CONTINUE_SPIN');
        closeModal();
    };

    const maxBetMessage = 'Your balance is running low, are you sure you want to continue with Max Play?';

    return (
        <GenericModalLayout id='INSUFFICIENT_FUNDS' classExtra='insufficient-funds__container'>
            <h2 className='insufficient-funds__header'>Low on coins</h2>
            <p className='insufficient-funds__comment'>{maxBetMessage}</p>
            <div className='modal-button-deck'>
                <ThemeButton
                    actionType='primary'
                    size='md'
                    onClick={continueMaxPlay}
                    id='insufficient-funds__max-bet__continue-btn'
                >
                    CONTINUE
                </ThemeButton>
                <ThemeButton
                    actionType='secondary'
                    size='md'
                    onClick={closeModal}
                    id='insufficient-funds__max-bet__cancel-btn'
                >
                    CANCEL
                </ThemeButton>
            </div>
        </GenericModalLayout>
    );
};
