import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { setSegmentedStore } from '../actions';
import { logger } from 'common/services/log/logger';
import { newStoreApi } from 'components/domain/new-store/api';
import { AppState } from 'store/app-state';

const handleAction = async (dispatch: Dispatch<AppAction>): Promise<void> => {
    const segmentedStore = await newStoreApi.fetchSegmentedStore();
    dispatch(setSegmentedStore(segmentedStore));
};

export const setSegmentedStoreHandler = async (
    action: AppAction,
    dispatch: Dispatch<AppAction>,
    appState: AppState
) => {
    try {
        await handleAction(dispatch);
    } catch (error) {
        logger.error(error);
    }
};
