import { LayoutState, LayoutAction, LAYOUT_ACTION_TYPES } from './types';
import { createReducer } from 'store/create';

const MAX_RECENTLY_PLAYED = 20;

export const initialLayoutState: LayoutState = {
    games: [],
    inactiveGames: [],
    id: undefined,
    recentlyPlayed: [],
    recommendedGames: [],
    topGames: [],
    justDropped: [],
    favoriteGames: [],
    categories: undefined,
    blockedGameIds: [],
    blockedGameTypes: [],
};

export { reducer as layoutReducer };

const { reducer, handle } = createReducer<LayoutAction, LayoutState>(initialLayoutState);

handle(LAYOUT_ACTION_TYPES.MODIFY_LAYOUT, (_, action) => ({
    ...action.payload,
}));

handle(LAYOUT_ACTION_TYPES.ADD_RECENTLY_PLAYED, (state, action) => {
    const recentlyPlayed = [action.payload, ...state.recentlyPlayed.filter((it) => it !== action.payload)];
    return {
        recentlyPlayed:
            recentlyPlayed.length > MAX_RECENTLY_PLAYED ? recentlyPlayed.slice(0, MAX_RECENTLY_PLAYED) : recentlyPlayed,
    };
});

handle(LAYOUT_ACTION_TYPES.SET_RECOMMENDED_GAMES, (_, action) => ({
    recommendedGames: action.payload,
}));

handle(LAYOUT_ACTION_TYPES.SET_TOP_GAMES, (_, action) => ({
    topGames: action.payload,
}));

handle(LAYOUT_ACTION_TYPES.ADD_FAVORITE_GAMES, (state, action) => ({
    favoriteGames: [action.payload, ...state.favoriteGames.filter((it) => it !== action.payload)],
}));

handle(LAYOUT_ACTION_TYPES.REMOVE_FAVORITE_GAMES, (state, action) => ({
    favoriteGames: [...state.favoriteGames.filter((it) => it !== action.payload)],
}));

handle(LAYOUT_ACTION_TYPES.SET_FAVORITE_GAMES, (_, action) => ({
    favoriteGames: action.payload,
}));

handle(LAYOUT_ACTION_TYPES.SET_CATEGORIES, (_, action) => ({
    categories: action.payload,
}));
