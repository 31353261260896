import { GameLayoutInformation, GameMetadata, GameProvider } from '@chu/http/dist';
import { BlockedGameType } from '@chu/http/src';
import { Category } from 'layout/lobby-categories';
import { Action } from 'redux';

// Describing the different ACTION NAMES available
export enum LAYOUT_ACTION_TYPES {
    MODIFY_LAYOUT = 'MODIFY_LAYOUT',
    ADD_RECENTLY_PLAYED = 'ADD_RECENTLY_PLAYED',
    SET_RECOMMENDED_GAMES = 'SET_RECOMMENDED_GAMES',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_TOP_GAMES = 'SET_TOP_GAMES',
    ADD_FAVORITE_GAMES = 'ADD_FAVORITE_GAMES',
    REMOVE_FAVORITE_GAMES = 'REMOVE_FAVORITE_GAMES',
    SET_FAVORITE_GAMES = 'SET_FAVORITE_GAMES',
}

export type Game = GameLayoutInformation & {
    metadata: GameMetadata & {
        provider: GameProvider;
    };
    alternateGameId?: string;
};

export type GameId = Game['gameId'];

// Describing the shape of the layout slice of state
export interface LayoutState {
    games: Game[];
    inactiveGames: Game[];
    id?: number;
    recentlyPlayed: Game['gameId'][];
    justDropped: Game['gameId'][];
    recommendedGames: Game['gameId'][];
    topGames: Game['gameId'][];
    favoriteGames: Game['gameId'][];
    categories: Category[] | undefined;
    blockedGameIds: string[];
    blockedGameTypes: BlockedGameType[];
}

export interface ModifyLayoutAction extends Action {
    type: LAYOUT_ACTION_TYPES.MODIFY_LAYOUT;
    payload: LayoutState;
}

export interface AddRecentlyPlayedAction extends Action {
    type: LAYOUT_ACTION_TYPES.ADD_RECENTLY_PLAYED;
    payload: GameId;
}

export interface SetRecommendedGamesAction extends Action {
    type: LAYOUT_ACTION_TYPES.SET_RECOMMENDED_GAMES;
    payload: GameId[];
}

export interface SetTopGamesAction extends Action {
    type: LAYOUT_ACTION_TYPES.SET_TOP_GAMES;
    payload: GameId[];
}

export interface AddFavoriteGamesAction extends Action {
    type: LAYOUT_ACTION_TYPES.ADD_FAVORITE_GAMES;
    payload: GameId;
}

export interface RemoveFavoriteGamesAction extends Action {
    type: LAYOUT_ACTION_TYPES.REMOVE_FAVORITE_GAMES;
    payload: GameId;
}

export interface SetFavoriteGamesAction extends Action {
    type: LAYOUT_ACTION_TYPES.SET_FAVORITE_GAMES;
    payload: GameId[];
}

export interface SetCategoriesAction extends Action {
    type: LAYOUT_ACTION_TYPES.SET_CATEGORIES;
    payload: Category[];
}

export type LayoutAction =
    | ModifyLayoutAction
    | AddRecentlyPlayedAction
    | SetTopGamesAction
    | SetRecommendedGamesAction
    | AddFavoriteGamesAction
    | RemoveFavoriteGamesAction
    | SetFavoriteGamesAction
    | SetCategoriesAction;
