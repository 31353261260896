import React, { MouseEvent } from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';

export const UpdateBankAccountModalActions: React.FC<CallToActionProps> = ({
    modalName,
    isLoading,
    handleContinue,
    handleCloseModal,
}: CallToActionProps) => (
    <div className={`${modalName}__button-group`}>
        <ThemeButton
            loading={isLoading}
            id={`${modalName}__continue-btn`}
            data-testid={`${modalName}__continue-btn`}
            className={`${modalName}__continue-btn`}
            actionType='primary'
            size='md'
            theme='blue-light'
            onClick={handleContinue}
        >
            CONTINUE
        </ThemeButton>
        <ThemeButton
            id={`${modalName}__not-now-btn`}
            data-testid={`${modalName}__not-now-btn`}
            className={`${modalName}__not-now-btn`}
            theme='blue-light'
            actionType='secondary'
            size='md'
            onClick={handleCloseModal}
        >
            NOT NOW
        </ThemeButton>
    </div>
);

interface CallToActionProps {
    modalName: string;
    isLoading: boolean;
    handleContinue: (e: MouseEvent<HTMLButtonElement>) => void;
    handleCloseModal: () => void;
}
