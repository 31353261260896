import { Dispatch } from 'redux';
import { AppState } from './app-state';
import { AppAction } from './app-action';

type Action = { type: string };

type ExtractAction<U extends Action, T extends U['type']> = Extract<U, { type: T }>;

interface MiddlewareAPI<S = AppState, A extends { type: string } = AppAction> {
    dispatch: Dispatch<A>;
    getState(): S;
}

interface Middleware<S = AppState, A extends { type: string } = AppAction> {
    (api: MiddlewareAPI<S, A>): (next: Dispatch<A>) => (action: any) => any;
}

type Saga<S = AppState, A extends { type: string } = AppAction> = Middleware<S, A>;

export function createReducer<TAction extends Action, TState>(initialState: TState) {
    type TReturn = Partial<TState> | void;

    const handlers = new Map<TAction['type'], any>();

    const handle = <TType extends TAction['type']>(
        type: TType,
        handler: ((state: TState, action: ExtractAction<TAction, TType>) => TReturn) | TReturn
    ) => {
        const existing = handlers.get(type);

        // This is typically a mistake
        // If this is desired, we should provide "options" to to the createReducer() to
        // specifically states that overriding existing handlers is intended
        if (existing) {
            throw new Error(`Unable to mount reducer handler '${type}': Handler already exists`);
        }
        handlers.set(type, handler);
    };

    const reducer = function (state = initialState, action: TAction | any): TState {
        const handler = handlers.get(action.type);
        if (!handler) {
            return state;
        }
        if (typeof handler === 'function') {
            const nextState = handler(state, action) || state;
            return { ...state, ...nextState };
        }
        return { ...state, ...handler };
    };

    return { handle, reducer };
}

export function createSaga<TState = AppState, TAction extends { type: string } = AppAction>() {
    const handlers = new Map<TAction['type'], any>();
    const saga: Saga<TState, TAction> =
        ({ dispatch, getState }) =>
        (next) =>
        async (action: TAction) => {
            next(action);

            const handler = handlers.get(action.type);
            if (!handler) {
                return;
            }

            if (handler.length === 3) {
                const state = getState();
                await handler(action, dispatch, state);
                return;
            }

            await handler(action, dispatch);
        };

    const handle = <TType extends TAction['type']>(
        type: TType,
        handler: (action: ExtractAction<TAction, TType>, dispatch: Dispatch<AppAction>, state: TState) => any
    ) => {
        handlers.set(type, handler);
    };

    return { handle, saga };
}
