import { sendToClickStream } from 'common/services/analytics/user-actions';
import { createWiretapMiddleware } from 'common/services/analytics/wiretap';
import { AppState } from '../../../../store/app-state';
import { AppAction } from '../../../../store/app-action';

// TODO: After Data Engineering allow schemaless data to be used for clickstream, remove Parameter0 and Parameter1 from these events.
export const createClickstreamMiddleware = () => {
    return createWiretapMiddleware<AppAction, AppState>((action: AppAction, getState: () => AppState) => {
        switch (action.type) {
            case 'TOGGLE_CHALLENGES_POPOUT': {
                const { ui, challenges } = getState();
                if (ui.activePopout === 'CHALLENGES' && !challenges.optIn.hasOptedIn) {
                    sendToClickStream({
                        actionName: 'CHALLENGE_INTRO_SCREEN_SHOWN',
                    });
                }
                break;
            }

            case 'CHALLENGE_CARD_CLICKED': {
                sendToClickStream({
                    actionName: 'CHALLENGE_CLICK',
                    inflightChallengeId: action.inflightChallenge.inflightChallengeId,
                    parameter0: action.inflightChallenge.inflightChallengeId,
                });
                break;
            }

            case 'REQUEST_SET_USER_OPTED_IN': {
                const buttonClicked = action.optIn ? 'Join In' : 'No Thanks';
                sendToClickStream({
                    actionName: 'CHALLENGE_INTRO_SCREEN_ACTION',
                    buttonClicked,
                    parameter0: buttonClicked,
                });
                break;
            }

            case 'QUEUE_MODAL':
            case 'QUEUE_MODAL_FRONT': {
                const { ui } = getState();
                if (action.modalProps.type === 'CHALLENGES_TERMS_AND_CONDITIONS' && ui.activePopout === 'CHALLENGES') {
                    sendToClickStream({
                        actionName: 'CHALLENGE_INTRO_SCREEN_ACTION',
                        buttonClicked: 'Terms and Conditions',
                        parameter0: 'Terms and Conditions',
                    });
                }
                break;
            }

            case 'CHALLENGE_CARD_VIEWED': {
                sendToClickStream({
                    actionName: 'CHALLENGE_SHOWN',
                    inflightChallengeId: action.inflightChallengeId,
                    entryPoint: 'top_hud',
                    parameter0: action.inflightChallengeId,
                    parameter1: 'top_hud',
                });
                break;
            }

            case 'NO_CHALLENGES_MESSAGE_SHOWN': {
                sendToClickStream({
                    actionName: 'NO_MORE_CHALLENGES_MESSAGE_SHOWN',
                });
                break;
            }

            case 'ALL_CHALLENGES_COMPLETE_MESSAGE_SHOWN': {
                sendToClickStream({
                    actionName: 'ALL_CHALLENGES_COMPLETE_MESSAGE_SHOWN',
                });
                break;
            }

            case 'SET_LOADING': {
                if (action.loadingStatus !== 'GAME_LOADING' && action.loadingStatus !== 'GAME_LOADED') {
                    return;
                }

                const { game } = getState();

                const event = {
                    actionName: action.loadingStatus,
                    gameId: game.gameId,
                    provider: game.company,
                    loadingTime:
                        action.loadingStatus === 'GAME_LOADED' ? Date.now() - game.gameLoadStartDate : undefined,
                };

                sendToClickStream(event);
                break;
            }

            case 'CHALLENGE_STAR_CLICKED': {
                const event = {
                    actionName: 'CHALLENGE_STAR_CLICKED',
                    star: action.starAttributes,
                };
                sendToClickStream(event);
                break;
            }

            case 'SHOW_DAILY_CHALLENGES_CTA': {
                sendToClickStream({
                    actionName: 'CHALLENGE_CTA_SHOWN',
                    dailyChallengeSetId: action.dailyChallengeSetId,
                });
            }
        }
    });
};
