type CookieKeySuffix =
    | 'ENABLE_SEGMENTED_ITEM_COLLECTIONS'
    | 'ENABLE_QUESTS'
    | 'ENABLE_PLAYER_TOKEN_CONTEXT'
    | 'ENABLE_GAME_EVENT_LOGGING'
    | 'ALLOW_CHUMBA_PWA';

/**
 * Cookie based toggle check to enable behaviour on a device basis.
 *
 * How to add your own cookie?
 * (1) --> Add your cookie name to `CookieKeySuffix` above
 * (2) --> set and enable the cookie by running: `document.cookie = "vgw.chumba.toggles.<COOKIE_NAME>=true";` in the console (replacing COOKIE_NAME)
 * (3) --> disable the cookie by running `document.cookie = "vgw.chumba.toggles.<COOKIE_NAME>=false";` in the console
 * (4) --> verify your desired behaviour and delete from `CookieKeySuffix` when you are done
 */

export const isCookieToggleEnabled = (cookieKeySuffix: CookieKeySuffix): boolean => {
    return (
        document.cookie
            .split('; ')
            .find((entry) => entry.startsWith(`vgw.chumba.toggles.${cookieKeySuffix}=`))
            ?.endsWith('true') || false
    );
};

export const cookieToggleOverride = (cookieKeySuffix: CookieKeySuffix): boolean | undefined => {
    const cookieOrUndefined = document.cookie
        .split('; ')
        .find((entry) => entry.startsWith(`vgw.chumba.toggles.${cookieKeySuffix}=`));

    if (cookieOrUndefined?.endsWith('true')) {
        return true;
    } else if (cookieOrUndefined?.endsWith('false')) {
        return false;
    } else {
        return undefined;
    }
};
