export const OFFER_MODAL = 'OFFER_MODAL';

export const CONFIRM_MODAL = 'CONFIRM_MODAL';

export const REDEMPTION_ERROR_MODAL = 'REDEMPTION_ERROR_MODAL';

export const OFFER_ERROR_MODAL = 'OFFER_ERROR_MODAL';

export const STORE_MODAL = 'STORE_MODAL';

export type OfferErrorType = 'INVALID' | 'CLAIMED' | 'EXPIRED' | 'EXPIRED_PROMO';

export interface ConfirmModalProps {
    confirm: () => void;
    cancel: () => void;
    isPromoPackage?: boolean;
}
