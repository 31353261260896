import axios from 'axios';
import { logger } from '../log/logger';
import { PlayerAccountService } from './player-account-service-interface';
import { PlayerAccountBlockedFeatures, PlayerAccountRestrictionsResponse } from './types';
import { mapToBlockedFeatures } from './response-mapper';
import { sendGetApiRequest } from '../helpers';

export const createPlayerAccountService = (url: string): PlayerAccountService => {
    const getPlayerRestrictions = async (playerId: number): Promise<PlayerAccountBlockedFeatures> => {
        try {
            const { data } = await sendGetApiRequest<PlayerAccountRestrictionsResponse>(
                `${url}/feature-configuration/restrictions/player/${playerId}`
            );
            return mapToBlockedFeatures(data);
        } catch (err: unknown) {
            const safeErrorMessage = axios.isAxiosError(err)
                ? JSON.stringify({
                      message: err.message,
                      code: err.code,
                      responseStatus: err.response?.status,
                      responseData: err.response?.data,
                  })
                : err;
            logger.error(`Player restriction request to player account API did not succeed, failing open`, {
                safeErrorMessage,
            });
            return { restrictions: [] };
        }
    };

    return { getPlayerRestrictions };
};
