import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

export function createWiretapMiddleware<A extends Action = AnyAction, S = any, D extends Dispatch = Dispatch>(
    callback: (action: A, getState: () => S, distatch: D) => void
): Middleware<{}, S, D> {
    return (api: MiddlewareAPI<D, S>) => (next: Dispatch<A>) => async (action: A) => {
        next(action);

        try {
            callback(action, api.getState, api.dispatch);
        } catch (e) {
            console.error('there was an error in the redux wiretap', e);
        }
    };
}
