import React, { MouseEvent } from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';

export const LinkYourBankAccountModalActions: React.FC<CallToActionProps> = ({
    modalName,
    loadingBtn,
    handleLinkBankAccount,
    handleRedirectToRedeemHub,
}: CallToActionProps) => (
    <div className={`${modalName}__button-group`}>
        <ThemeButton
            id={`${modalName}__link-bank-account-btn`}
            data-testid={`${modalName}__link-bank-account-btn`}
            className={`${modalName}__link-bank-account-btn`}
            actionType='primary'
            size='md'
            theme='red-dark'
            disabled={loadingBtn}
            onClick={handleLinkBankAccount}
        >
            LINK ACCOUNT
        </ThemeButton>
        <ThemeButton
            loading={loadingBtn}
            id={`${modalName}__not-now-btn`}
            data-testid={`${modalName}__not-now-btn`}
            className={`${modalName}__not-now-btn`}
            theme='red-dark'
            actionType='secondary'
            size='md'
            onClick={handleRedirectToRedeemHub}
        >
            NOT NOW
        </ThemeButton>
    </div>
);

interface CallToActionProps {
    modalName: string;
    loadingBtn: boolean;
    handleLinkBankAccount: (e: MouseEvent<HTMLButtonElement>) => void;
    handleRedirectToRedeemHub: () => Promise<void>;
}
