import { Currency, GoldCoinTopup } from '@chu/http';
import { getGCTopupModalProps } from 'components/elements/modal/gc-topup';
import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { AppState } from 'store/app-state';
import { GAME_LOADED } from 'store/game/types';
import { queueModal } from 'store/modal/actions';
import { FreeGCAction } from '../actions';

const shouldHandleAction = (appState: AppState) => {
    const { system, user } = appState;
    const { currency } = system;
    const { goldBalance } = user;

    if (system.blockedFeatures.includes('FREE_GC')) {
        return false;
    }

    return currency === Currency.GOLD && goldBalance < GoldCoinTopup.THRESHOLD;
};

async function handleAction(dispatch: Dispatch<AppAction>, appState: AppState) {
    const { loadingStatus } = appState.game;

    let redirectUrl = loadingStatus === GAME_LOADED ? '/' : undefined;

    if (appState.system.toggles.INSUFFICIENT_COINS_UPDATE) {
        redirectUrl = loadingStatus === GAME_LOADED ? window.location.href : undefined;
    }

    dispatch(queueModal(getGCTopupModalProps(GoldCoinTopup.AMOUNT, redirectUrl)));
}

export const freeGCHandler = async (action: FreeGCAction, dispatch: Dispatch<AppAction>, appState: AppState) => {
    try {
        if (shouldHandleAction(appState)) {
            await handleAction(dispatch, appState);
        }
    } catch (error) {
        throw error;
    } finally {
        if (action.next) dispatch(action.next);
    }
};
