import React from 'react';
import { useDispatch } from 'react-redux';
import { ThemeButton } from '../../button/theme-buttons';
import { hideModal } from '../../../../store/modal/actions';
import { ReactComponent as Exclamation } from './exclamation-icon.svg';
import { GenericModalLayout } from '../../../domain/account-verification/modals/generic-modal';
import { logout } from '../../../../helpers/logout';

export const MustKYCUnsuccessfulVerificationModal = () => {
    const dispatch = useDispatch();

    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Exclamation />
                </div>
            }
        >
            <div className='must-kyc-modal__container'>
                <h2>We were unable to verify you</h2>
                <p>
                    Thank you for providing your details. Unfortunately, we have not been able to verify your account.
                    If you need assistance please contact{' '}
                    <a
                        className='customer-service-link'
                        href='https://chumbacasino.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001729833'
                        target='_blank'
                    >
                        customer service
                    </a>
                    .
                </p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='dialog-UNSUCCESSFUL_VERIFICATION__try-again-btn'
                        className='primary-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        to='/account-verification'
                        onClick={() => dispatch(hideModal(MUST_KYC_UNSUCCESSFUL_VERIFICATION_MODAL))}
                    >
                        TRY AGAIN
                    </ThemeButton>
                    <ThemeButton
                        id='must-kyc-modal__log-out-btn'
                        theme='red-dark'
                        actionType='secondary'
                        size='md'
                        onClick={() => logout()}
                    >
                        LOG OUT
                    </ThemeButton>
                </div>
            </div>
        </GenericModalLayout>
    );
};

export const getMustKYCUnsuccessfulVerificationModalProps = () =>
    ({
        type: MUST_KYC_UNSUCCESSFUL_VERIFICATION_MODAL,
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
    } as const);

export const MUST_KYC_UNSUCCESSFUL_VERIFICATION_MODAL = 'MUST_KYC__VERIFICATION_MODAL';
