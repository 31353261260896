import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { ThemeButton } from '../../../elements/button/theme-buttons';
import { ReactComponent as Exclamation } from './exclamation-icon.svg';
import { GenericModalLayout } from './generic-modal';

export const ReverifyVerificationModal = () => {
    const dispatch = useDispatch();

    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Exclamation />
                </div>
            }
        >
            <div className='reverify-modal__container'>
                <h2>Reverify your account</h2>
                <p>
                    As part of our terms and conditions, we occasionally require customers to reverify their account for
                    security purposes. You will not be able to redeem until your account is verified.
                </p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='dialog-REVERIFY__get-verified-btn'
                        className='primary-btn'
                        actionType='primary'
                        size='md'
                        theme='red-dark'
                        to='/account-verification'
                        onClick={() => dispatch(hideModal(REVERIFY_VERIFICATION_MODAL))}
                    >
                        GET VERIFIED
                    </ThemeButton>
                    <ThemeButton
                        id='dialog-REVERIFY__not-now-btn'
                        theme='red-dark'
                        actionType='secondary'
                        size='md'
                        onClick={() => dispatch(hideModal(REVERIFY_VERIFICATION_MODAL))}
                    >
                        NOT NOW
                    </ThemeButton>
                </div>
                <a
                    className='zendesk-link'
                    href='https://chumbacasino.zendesk.com/hc/en-us/articles/360052176273-Why-do-I-need-to-verify-my-account'
                    target='_blank'
                >
                    Why is this needed?
                </a>
            </div>
        </GenericModalLayout>
    );
};

export const getReverifyVerificationModalProps = () => ({ type: REVERIFY_VERIFICATION_MODAL } as const);

export const REVERIFY_VERIFICATION_MODAL = 'REVERIFY_VERIFICATION_MODAL';
