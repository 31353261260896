import { Action } from 'redux';
import { GAME_HAS_INIT } from '../../chumba/third-party-types';
import { GameType, PlayableTokens } from '@chu/http';
import { History } from 'history';

export const GAME_CHECKING_SHOW_LOBBY = 'GAME_CHECKING_SHOW_LOBBY';
export const GAME_CHECKING_CURRENCY = 'GAME_CHECKING_CURRENCY';
export const GAME_CHECKING_OPEN_CASHIER = 'GAME_CHECKING_OPEN_CASHIER';
export const GAME_CHECKING_OPEN_CASHOUT = 'GAME_CHECKING_OPEN_CASHOUT';
export const GAME_CHECKING_PLAY_ID = 'GAME_CHECKING_PLAY_ID';
export const SET_IS_COUNTING_UP_CURRENCY = 'SET_IS_COUNTING_UP_CURRENCY';
export const GAME_INIT = 'GAME_INIT';
export const CLEAR_GAME = 'CLEAR_GAME';
export const UNLOAD_GAME = 'UNLOAD_GAME';
export const FORCE_IFRAME_RESIZE = 'FORCE_IFRAME_RESIZE';
export const FORCE_IFRAME_RESIZE_CLEAR = 'FORCE_IFRAME_RESIZE_CLEAR';
export const SET_IS_FULLSCREEN = 'SET_IS_FULLSCREEN';
export const SET_IS_LOCKED_CURRENCY_MODE = 'SET_IS_LOCKED_CURRENCY_MODE';
export const SET_CURRENCY_DISPLAY_ANIMATE_ABLE = 'SET_CURRENCY_DISPLAY_ANIMATE_ABLE';

export const SET_LOADING = 'SET_LOADING';

export const SET_GAME_ID = 'SET_GAME_ID';
export const SET_SHARED_RGS_GAME_DATA = 'SET_SHARED_RGS_GAME_DATA';
export const SET_RGS_GAME_TOKEN = 'SET_RGS_GAME_TOKEN';
export const SET_IS_RGS_GAME_LOADING = 'SET_IS_RGS_GAME_LOADING';

export const SET_IS_AUTO_PLAY = 'SET_IS_AUTO_PLAY';

export const SET_GAME_LOAD_START_DATE = 'SET_GAME_LOAD_START_DATE';

export const LAUNCH_GAME = 'LAUNCH_GAME';
export const CHECK_IS_TEMPORARILY_UNAVAILABLE = 'CHECK_IS_TEMPORARILY_UNAVAILABLE';
export const CHECK_PLAYABLE_TOKENS = 'CHECK_PLAYABLE_TOKENS';
export const OPEN_GAME = 'OPEN_GAME';
export const SET_IS_CLOCK_DISABLED = 'SET_IS_CLOCK_DISABLED';

export const GameActionTypeList = {
    GAME_INIT,
    CLEAR_GAME,
    UNLOAD_GAME,
    FORCE_IFRAME_RESIZE,
    FORCE_IFRAME_RESIZE_CLEAR,
    GAME_CHECKING_SHOW_LOBBY,
    GAME_CHECKING_CURRENCY,
    GAME_CHECKING_OPEN_CASHIER,
    GAME_CHECKING_OPEN_CASHOUT,
    GAME_CHECKING_PLAY_ID,
    SET_IS_FULLSCREEN,
    SET_IS_LOCKED_CURRENCY_MODE,
    SET_CURRENCY_DISPLAY_ANIMATE_ABLE,
    SET_LOADING,
    SET_GAME_ID,
    SET_IS_COUNTING_UP_CURRENCY,
    SET_SHARED_RGS_GAME_DATA,
    SET_RGS_GAME_TOKEN,
    SET_IS_AUTO_PLAY,
    SET_IS_RGS_GAME_LOADING,
    SET_GAME_LOAD_START_DATE,
    LAUNCH_GAME,
    CHECK_IS_TEMPORARILY_UNAVAILABLE,
    CHECK_PLAYABLE_TOKENS,
    OPEN_GAME,
    SET_IS_CLOCK_DISABLED,
} as const;

export interface GameState {
    hasGameInit: boolean;
    company: string;
    gameId: string;
    myName?: string;
    gold: boolean;
    sweeps: boolean;
    playableTokens: PlayableTokens;
    comingSoon: boolean;
    isPhone: boolean;
    isCheckingCurrency: boolean;
    isCheckingCanOpenCashier: boolean;
    isCheckingCanOpenCashout: boolean;
    isFullscreen: boolean;
    isLockedCurrencyMode: boolean;
    isCheckingPlayId: boolean;
    isCheckingShowLobby: boolean;
    isLoading: boolean;
    loadingStatus: GAME_LOADING_STATES;
    isCountingUpCurrency: boolean;
    isCurrencyDisplayAnimateAble: boolean;
    gameType?: GameType;
    isAutoPlay: boolean;
    isClockDisabled: boolean;
    rgsGameToken?: string;
    isRgsGameLoading: boolean;
    gameLoadStartDate: number;
}

export const GAME_LOADING = 'GAME_LOADING';
export const GAME_UNLOADING = 'GAME_UNLOADING';
export const GAME_UNLOADING_UN_INIT = 'GAME_UNLOADING_UN_INIT';

export const GAME_LOADED = 'GAME_LOADED';
export const GAME_UNLOADED = 'GAME_UNLOADED';

export type GAME_LOADING_STATES =
    | typeof GAME_LOADING
    | typeof GAME_LOADED
    | typeof GAME_UNLOADING
    | typeof GAME_UNLOADING_UN_INIT
    | typeof GAME_UNLOADED;

export interface GameInitAction extends Action {
    type: typeof GAME_INIT;
    payload: Omit<GAME_HAS_INIT, 'type'>;
}

export interface GameSetCheckPlayIdAction extends Action {
    type: typeof GAME_CHECKING_PLAY_ID;
    checkingPlayId: boolean;
}

export interface GameSetCheckCurrencyAction extends Action {
    type: typeof GAME_CHECKING_CURRENCY;
    checkingCurrency: boolean;
}

export interface GameSetCheckShowLobbyAction extends Action {
    type: typeof GAME_CHECKING_SHOW_LOBBY;
    checkingShowLobby: boolean;
}

export interface SetCurrencyDisplayAnimateAbleAction extends Action {
    type: typeof SET_CURRENCY_DISPLAY_ANIMATE_ABLE;
    isCurrencyDisplayAnimateAble: boolean;
}

export interface GameSetCheckOpenCashierAction extends Action {
    type: typeof GAME_CHECKING_OPEN_CASHIER;
    checkingCanOpenCashier: boolean;
}

export interface GameSetCheckOpenCashoutAction extends Action {
    type: typeof GAME_CHECKING_OPEN_CASHOUT;
    checkingCanOpenCashout: boolean;
}

export interface GameClearAction extends Action {
    type: typeof CLEAR_GAME;
}

export interface UnLoadGameAction extends Action {
    type: typeof UNLOAD_GAME;
}

export interface ForceIframeResizeAction extends Action {
    type: typeof FORCE_IFRAME_RESIZE;
}

export interface ForceIframeResizeClearAction extends Action {
    type: typeof FORCE_IFRAME_RESIZE_CLEAR;
}

export interface SetIsFullscreen extends Action {
    type: typeof SET_IS_FULLSCREEN;
    isFullscreen: boolean;
}

export interface SetIsLockedCurrencyModeAction extends Action {
    type: typeof SET_IS_LOCKED_CURRENCY_MODE;
    isLockedCurrencyMode: boolean;
}

export interface SetIsCountingUpCurrencyAction extends Action {
    type: typeof SET_IS_COUNTING_UP_CURRENCY;
    isCountingUpCurrency: boolean;
}

export interface SetLoadingStateAction extends Action {
    type: typeof SET_LOADING;
    loadingStatus: GAME_LOADING_STATES;
}

export interface SetGameIdAction extends Action {
    type: typeof SET_GAME_ID;
    gameId: string;
}

interface SetSharedRGSGameData extends Action {
    type: typeof SET_SHARED_RGS_GAME_DATA;
    gameId: string;
    gameName: string;
    company: string;
    isClockDisabled: boolean;
}

export interface SetRGSGameToken extends Action {
    type: typeof SET_RGS_GAME_TOKEN;
    gameToken?: string;
}

interface SetIsAutoPlay extends Action {
    type: typeof SET_IS_AUTO_PLAY;
    autoPlay: boolean;
}

export interface SetIsRGSGameLoading extends Action {
    type: typeof SET_IS_RGS_GAME_LOADING;
    isLoading: boolean;
}

export interface SetGameLoadStartDateAction extends Action {
    type: typeof SET_GAME_LOAD_START_DATE;
    timestamp: number;
}

export interface LaunchGame extends Action {
    type: typeof LAUNCH_GAME;
    gameId: string;
    history: History;
}

export interface CheckIsTemporarilyUnavailable extends Action {
    type: typeof CHECK_IS_TEMPORARILY_UNAVAILABLE;
    gameId: string;
    active: number;
    history: History;
}

export interface CheckPlayableTokens extends Action {
    type: typeof CHECK_PLAYABLE_TOKENS;
    gameId: string;
    playableTokens: PlayableTokens;
    history: History;
}

export interface OpenGame extends Action {
    type: typeof OPEN_GAME;
    slug: string;
    history: History;
}

export interface SetIsClockDisabled extends Action {
    type: typeof SET_IS_CLOCK_DISABLED;
    isClockDisabled: boolean;
}

export type GameAction =
    | GameInitAction
    | ForceIframeResizeAction
    | ForceIframeResizeClearAction
    | GameClearAction
    | UnLoadGameAction
    | GameSetCheckShowLobbyAction
    | GameSetCheckCurrencyAction
    | GameSetCheckPlayIdAction
    | GameSetCheckOpenCashierAction
    | GameSetCheckOpenCashoutAction
    | SetIsFullscreen
    | SetIsLockedCurrencyModeAction
    | SetLoadingStateAction
    | SetIsCountingUpCurrencyAction
    | SetCurrencyDisplayAnimateAbleAction
    | SetGameIdAction
    | SetSharedRGSGameData
    | SetRGSGameToken
    | SetIsAutoPlay
    | SetIsRGSGameLoading
    | SetGameLoadStartDateAction
    | LaunchGame
    | CheckIsTemporarilyUnavailable
    | CheckPlayableTokens
    | OpenGame
    | SetIsClockDisabled;
