import * as React from 'react';
import { StandardModal } from '../standard/standard-modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideModal } from 'store/modal/actions';
import { getGameUnavailableModalProps } from './game-unavailable-modal-props-creator';

type GameUnavailableModalProperties = Omit<ReturnType<typeof getGameUnavailableModalProps>, 'type'>;

export const GameUnavailableModal: React.FC<GameUnavailableModalProperties> = ({ body, heading }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onCloseClick = (): void => {
        history.push('/');
        dispatch(hideModal('GAME_UNAVAILABLE'));
    };
    const buttonText = 'BACK TO LOBBY';
    return (
        <StandardModal
            body={body}
            closeButtonText={buttonText}
            header={heading}
            kind={'ERROR'}
            onCloseClick={onCloseClick}
            svgSource={undefined}
        />
    );
};
