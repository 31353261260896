import { CheckoutStatusData, SegmentedStore } from '../../../../store/store/new-store/types';
import axios from 'axios';
import { logger } from '../../../../common/services/log/logger';

export class NewStoreHttpClient {
    constructor(private readonly newStoreBaseUrl: string) {}

    async fetchSegmentedStore(): Promise<SegmentedStore> {
        const { data } = await axios.get<SegmentedStore>(`${this.newStoreBaseUrl}/segmented-store`, {
            withCredentials: true,
        });
        return data;
    }

    async checkout(args: CheckoutItem): Promise<CheckoutSegmentedStoreItemResult> {
        const url = `${this.newStoreBaseUrl}/checkout/segmented-store`; // conditionally build from args.type
        const config = { withCredentials: true, validateStatus: () => true };
        const reqBody = {
            itemId: args.itemId,
            tmxSessionId: args.tmxSessionId,
        };
        try {
            const res = await axios.post(url, reqBody, config);

            if (res.status === 201) {
                return { type: 'SUCCESS', redirectUrl: res.data.purchasePortalUrl };
            }
            if (res.status == 412 || res.status == 404) {
                logger.warn(`Checkout rejected, { resData: ${res.data} }`);
                return { type: 'REJECTED', reason: mapCheckoutRejectedReasons(res.data.reason) };
            }
            logger.warn(`Checkout failed, { resData: ${res.data} }`);
            return { type: 'FAILED' };
        } catch (err: unknown) {
            if (axios.isAxiosError(err)) {
                logger.warn('Checkout failed', { resData: err.response?.data });
                return { type: 'FAILED' };
            }
            return { type: 'FAILED' };
        }
    }

    async fetchCheckoutStatus(checkoutId: string): Promise<CheckoutStatusResult> {
        try {
            const url = `${this.newStoreBaseUrl}/checkout-state/${checkoutId}`;

            const res = await axios.get<CheckoutStatusData>(url, {
                withCredentials: true,
            });

            if (res.status === 200) {
                return {
                    type: 'SUCCESS',
                    status: res.data.checkoutStatus,
                };
            }
            if (res.status == 204) {
                return {
                    type: 'NOT_FOUND',
                };
            }
            logger.warn(`Checkout status failed`, { resData: res.data });
            return {
                type: 'FAILED',
            };
        } catch (err: unknown) {
            if (axios.isAxiosError(err)) {
                logger.warn(`Checkout status check failed`, { resData: err.response?.data });
            }
            return {
                type: 'FAILED',
            };
        }
    }
}

export type CheckoutItem = {
    readonly type: 'SEGMENTED_STORE_ITEM' | 'FIRST_PURCHASE_STORE_ITEM'; // | .....
    readonly itemId: string;
    readonly tmxSessionId: string;
};

export type CheckoutSegmentedStoreItemResult =
    | CheckoutSegmentedStoreItemSuccess
    | CheckoutSegmentedStoreItemRejected
    | CheckoutSegmentedStoreItemFailed;

type CheckoutSegmentedStoreItemSuccess = {
    readonly type: 'SUCCESS';
    readonly redirectUrl: string;
};

type CheckoutSegmentedStoreItemRejected = {
    readonly type: 'REJECTED';
    readonly reason: CheckoutRejectedReason;
};

export type CheckoutRejectedReason =
    | 'MUST_SDD'
    | 'MUST_KYC'
    | 'ITEM_UNAVAILABLE'
    | 'INELIGIBLE_REGION'
    | 'PURCHASE_LIMIT_REACHED';

type CheckoutSegmentedStoreItemFailed = {
    readonly type: 'FAILED';
    readonly message?: string;
};

export type CheckoutStatusResult = CheckoutStatusSuccess | CheckoutStatusNotFound | CheckoutStatusFailed;

export enum CheckoutStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    FINALIZED = 'FINALIZED',
    PURCHASED = 'PURCHASED',
}

type CheckoutStatusSuccess = {
    readonly type: 'SUCCESS';
    readonly status: CheckoutStatus;
};

type CheckoutStatusNotFound = {
    readonly type: 'NOT_FOUND';
};

type CheckoutStatusFailed = {
    readonly type: 'FAILED';
    readonly reason?: string;
};

function mapCheckoutRejectedReasons(reason: any): CheckoutRejectedReason {
    switch (reason) {
        case 'KYC_REQUIRED':
            return 'MUST_KYC';
        case 'SDD_REQUIRED':
            return 'MUST_SDD';
        case 'INELIGIBLE_JURISDICTION':
            return 'INELIGIBLE_REGION';
        case 'RSG_PURCHASE_LIMITS':
            return 'PURCHASE_LIMIT_REACHED';
        case 'ITEM_NOT_AVAILABLE':
            return 'ITEM_UNAVAILABLE';
        case 'ITEM_CONSTRAINTS_NOT_MET':
            return 'ITEM_UNAVAILABLE';
        default:
            return 'ITEM_UNAVAILABLE';
    }
}
