import * as React from 'react';

import { StandardModalLayout } from './standard-modal-layout';
import { getStandardModalProps } from './standard-modal-prop-creators';

export type StandardModalProperties = Omit<ReturnType<typeof getStandardModalProps>, 'type'>;

/**
 * This functional component includes a common layout for rendering modals.
 *
 * 'GenericModal' is a similar component that serves different types of modals.
 *
 * @param {ReactChild} body  Child node containing body content to be rendered
 * @param {ReactChild} header Child node containing header content to be rendered
 * @param {string?} svgSource SVG source of the icon to display in the modal
 * @param {'STANDARD'|'ERROR'} kind
 * @param {string} closeButtonText text to be displayed on close button
 * @param {()=>void} onCloseClick callback function to call when close button is clicked
 */
export const StandardModal: React.FC<StandardModalProperties> = ({
    body,
    header,
    svgSource,
    kind,
    closeButtonText,
    onCloseClick,
}: StandardModalProperties) => {
    return (
        <StandardModalLayout
            body={body}
            header={header}
            svgSource={svgSource}
            closeButtonProps={{ onClick: onCloseClick, text: closeButtonText }}
            kind={kind}
        />
    );
};
