import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThemeButton } from 'components/elements/button/theme-buttons';

import { TEMPORARILY_UNAVAILABLE } from './modal-prop-types';
import { hideModal } from 'store/modal/actions';

import { ReactComponent as TemporarilyUnavailableIcon } from './hourglass-large.svg';
import './temporarily-unavailable.scss';

export const TemporarilyUnavailabe: React.FC<{ redirectToLobby: boolean }> = ({ redirectToLobby }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleCloseModal = () => {
        dispatch(hideModal(TEMPORARILY_UNAVAILABLE));
    };

    const handleRedirectToLobby = () => {
        dispatch(hideModal(TEMPORARILY_UNAVAILABLE));
        history.push('/');
    };

    return (
        <div className={'GENERIC-modal__modal-inner'} id={`dialog-${TEMPORARILY_UNAVAILABLE}`}>
            <div className='temp-modal-container'>
                <h2 className='temp-modal-header'>Temporarily Unavailable</h2>
                <TemporarilyUnavailableIcon />
                <span className='temp-modal-text'>
                    We are working on making this game available again soon. Please check back later.
                </span>
            </div>
            {redirectToLobby ? (
                <ThemeButton
                    onClick={handleRedirectToLobby}
                    size='md'
                    actionType={'primary'}
                    text={'BACK TO LOBBY'}
                    className='theme-btn__modal'
                    id='TEMPORARILY_UNAVAILABLE'
                />
            ) : (
                <ThemeButton
                    onClick={handleCloseModal}
                    size='md'
                    actionType={'primary'}
                    text={'CLOSE'}
                    className='theme-btn__modal'
                    id='TEMPORARILY_UNAVAILABLE'
                />
            )}
        </div>
    );
};
