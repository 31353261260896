import { CurrencyType, Currency } from '@chu/http';

export const SWEEPS = {
    CODE: 'SC',
    TEXT: 'Sweeps Coin',
    CLASS: 'sweeps',
    FACTOR: 0.01,
} as const;

export const GOLD = {
    CODE: 'GC',
    TEXT: 'Gold Coin',
    CLASS: 'gold-coins',
    FACTOR: 1,
} as const;

const getCurrencyDetails = (currency: CurrencyType) => {
    switch (currency) {
        case Currency.GOLD:
            return GOLD;
        case Currency.SWEEPS:
            return SWEEPS;
    }
};

export const getCoinTypeCode = (currency: CurrencyType) => {
    return getCurrencyDetails(currency).CODE;
};

export const getCurrencyText = (currency: CurrencyType) => {
    return getCurrencyDetails(currency).TEXT;
};

export const getCurrencyDisplayValue = (currency: CurrencyType, value: number): number => {
    if (currency === Currency.GOLD) {
        return value * GOLD.FACTOR;
    }
    return value * SWEEPS.FACTOR;
};
