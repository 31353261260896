import { GameIframeDataType } from '../../elements/game-iframe/game-iframe-data-type';
import store from '../../../store';
import { Game } from 'store/layout/types';

export const getChumbaGameServerClientUrl = ({ gameId }: GameIframeDataType) => getClientUrl(gameId);

export const getClientUrl = (gameId: string): string => {
    const games = store.getState().layout.games;
    const inactiveGames = store.getState().layout.inactiveGames;
    const isStaff = store.getState().user.isStaff;
    const availableGames = isStaff ? [...games, ...inactiveGames] : games;
    const game = availableGames.find((game) => game.gameId === gameId);
    if (game && game.metadata.clientUrl) {
        return game.metadata.clientUrl as string;
    } else {
        console.error(`Game [${gameId}] has no client url`);
        return '';
    }
};

export const isChumbaGameServerGame = (gameId: string, games: Game[]): boolean => {
    return !!games.find((game) => game.gameId === gameId && game.metadata.clientUrl);
};
