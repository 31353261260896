import React from 'react';
import { ThemeButton } from 'components/elements/button/theme-buttons';

export const MarketExitRejectModalActions: React.FC<CallToActionProps> = ({
    handleClickTryAgainBtn,
    handleLogOut,
    modalName,
}: CallToActionProps) => (
    <div className={`${modalName}__button-group`}>
        <ThemeButton
            id={`${modalName}__try-again-btn`}
            data-testid={`${modalName}__try-again-btn`}
            className={`${modalName}__try-again-btn`}
            actionType='primary'
            size='md'
            theme='red-dark'
            onClick={handleClickTryAgainBtn}
        >
            TRY AGAIN
        </ThemeButton>
        <ThemeButton
            id={`${modalName}__log-out-btn`}
            data-testid={`${modalName}__log-out-btn`}
            className={`${modalName}__log-out-btn`}
            theme='red-dark'
            actionType='secondary'
            size='md'
            onClick={handleLogOut}
        >
            LOG OUT
        </ThemeButton>
    </div>
);

interface CallToActionProps {
    handleClickTryAgainBtn: () => void;
    handleLogOut: () => void;
    modalName: string;
}
