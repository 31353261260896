import React, { ReactNode } from 'react';
import './generic-modal.scss';
import { CloseButton } from './close-button';
import { GenericModalTypes } from './generic-modal-prop-creators';

interface GenericModalLayoutProperties {
    children: ReactNode;
    callToActionChildren?: ReactNode;
    xButton?: boolean;
    xIcon?: boolean;
    refreshButton?: boolean;
    onCloseClick?: () => void;
    onRefreshClick?: () => void;
    classExtra?: string;
    id: GenericModalTypes;
}

export const GenericModalLayout: React.FC<GenericModalLayoutProperties> = (props: GenericModalLayoutProperties) => {
    const {
        children,
        xButton,
        refreshButton,
        onCloseClick,
        onRefreshClick,
        xIcon,
        callToActionChildren,
        classExtra,
        id,
    } = props;
    const hasCallToAction = xButton || refreshButton || callToActionChildren;

    return (
        <div className={`GENERIC-modal__modal-inner ${classExtra}`} id={`dialog-${id}`} data-testid={`dialog-${id}`}>
            {xIcon && onCloseClick && <CloseButton onCloseClick={onCloseClick} />}

            <div className={`GENERIC-modal__modal-inner__content ${hasCallToAction && 'callToAction'}`}>
                <div className={'GENERIC-modal__modal-inner__content-children'}>{children}</div>
            </div>

            <div>
                {xButton && (
                    <button
                        onClick={onCloseClick}
                        className='btn btn-generic-primary btn-lg mx-1'
                        id={`dialog-${id}__close-btn`}
                    >
                        Close
                    </button>
                )}
                {/* Changing wording for now to back to login until we fix sesssions */}
                {refreshButton && (
                    <button
                        className='btn btn-generic-primary btn-lg mx-1'
                        onClick={onRefreshClick}
                        id={`dialog-${id}__refresh-btn`}
                    >
                        Back to Login
                    </button>
                )}
            </div>
            {callToActionChildren}
        </div>
    );
};

interface GenericModalHeadingLayoutProperties extends GenericModalLayoutProperties {
    heading: string;
}

export const GenericModalWithHeadingLayout: React.FC<GenericModalHeadingLayoutProperties> = (
    props: GenericModalHeadingLayoutProperties
) => {
    const {
        heading,
        children,
        xButton,
        refreshButton,
        onCloseClick,
        onRefreshClick,
        callToActionChildren,
        xIcon,
        classExtra,
        id,
    } = props;
    return (
        <GenericModalLayout
            onCloseClick={onCloseClick}
            onRefreshClick={onRefreshClick}
            xButton={xButton}
            refreshButton={refreshButton}
            callToActionChildren={callToActionChildren}
            xIcon={xIcon}
            classExtra={classExtra}
            id={id}
        >
            <>
                <h2>{heading}</h2>
                <div>{children}</div>
            </>
        </GenericModalLayout>
    );
};
