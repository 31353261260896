import {
    CLEAR_GAME,
    FORCE_IFRAME_RESIZE,
    FORCE_IFRAME_RESIZE_CLEAR,
    GAME_CHECKING_CURRENCY,
    GAME_CHECKING_OPEN_CASHIER,
    GAME_CHECKING_OPEN_CASHOUT,
    GAME_CHECKING_PLAY_ID,
    GAME_CHECKING_SHOW_LOBBY,
    GAME_INIT,
    GAME_LOADED,
    GAME_LOADING,
    GAME_UNLOADED,
    GAME_UNLOADING_UN_INIT,
    GameAction,
    GameActionTypeList,
    GameState,
    SET_CURRENCY_DISPLAY_ANIMATE_ABLE,
    SET_GAME_ID,
    SET_GAME_LOAD_START_DATE,
    SET_IS_AUTO_PLAY,
    SET_IS_COUNTING_UP_CURRENCY,
    SET_IS_FULLSCREEN,
    SET_IS_LOCKED_CURRENCY_MODE,
    SET_IS_RGS_GAME_LOADING,
    SET_LOADING as SET_LOADING_STATE,
    SET_RGS_GAME_TOKEN,
    SET_SHARED_RGS_GAME_DATA,
    UNLOAD_GAME,
    LAUNCH_GAME,
    CHECK_IS_TEMPORARILY_UNAVAILABLE,
    CHECK_PLAYABLE_TOKENS,
    OPEN_GAME,
    SET_IS_CLOCK_DISABLED,
} from './types';
import { assertNeverAction, isActionType } from '../action-assertions';
import { LobbyReducer } from '../reducer';

export const initialGameState: GameState = {
    hasGameInit: false,
    company: '',
    gameId: '',
    gold: false,
    sweeps: false,
    playableTokens: 'all',
    comingSoon: false,
    isPhone: false,
    isFullscreen: false,
    isCheckingCurrency: false,
    isLockedCurrencyMode: false,
    isCheckingCanOpenCashier: false,
    isCheckingCanOpenCashout: false,
    isCheckingPlayId: false,
    isCheckingShowLobby: false,
    isLoading: false,
    isRgsGameLoading: false,
    loadingStatus: GAME_UNLOADED,
    isCountingUpCurrency: false,
    isCurrencyDisplayAnimateAble: true,
    isAutoPlay: false,
    isClockDisabled: false,
    rgsGameToken: undefined,
    gameLoadStartDate: 0,
};

export const gameReducer: LobbyReducer<GameState> = (gameState = initialGameState, action): GameState => {
    if (isActionType<GameAction>(action, GameActionTypeList)) {
        switch (action.type) {
            case GAME_INIT: {
                const { currency, ...payload } = action.payload;
                return {
                    ...payload,
                    hasGameInit: true,
                    playableTokens: 'all',
                    isCheckingCurrency: false,
                    isFullscreen: false,
                    isLockedCurrencyMode: false,
                    isCheckingCanOpenCashier: false,
                    isCheckingCanOpenCashout: false,
                    isCheckingPlayId: false,
                    isCheckingShowLobby: false,
                    isLoading: false,
                    isRgsGameLoading: false,
                    loadingStatus: GAME_LOADED,
                    isCountingUpCurrency: false,
                    isCurrencyDisplayAnimateAble: true,
                    isAutoPlay: false,
                    isClockDisabled: false,
                    gameLoadStartDate: gameState.gameLoadStartDate,
                };
            }
            case SET_GAME_ID:
                return { ...gameState, gameId: action.gameId };
            case GAME_CHECKING_SHOW_LOBBY:
                return { ...gameState, isCheckingShowLobby: action.checkingShowLobby };
            case GAME_CHECKING_PLAY_ID:
                return { ...gameState, isCheckingPlayId: action.checkingPlayId };
            case GAME_CHECKING_CURRENCY:
                return { ...gameState, isCheckingCurrency: action.checkingCurrency };
            case GAME_CHECKING_OPEN_CASHIER:
                return {
                    ...gameState,
                    isCheckingCanOpenCashier: action.checkingCanOpenCashier,
                };
            case GAME_CHECKING_OPEN_CASHOUT:
                return {
                    ...gameState,
                    isCheckingCanOpenCashout: action.checkingCanOpenCashout,
                };
            case UNLOAD_GAME:
                return {
                    ...gameState,
                    loadingStatus: GAME_UNLOADING_UN_INIT,
                    hasGameInit: false,
                };
            case CLEAR_GAME:
                return initialGameState;
            case SET_IS_FULLSCREEN:
                return { ...gameState, isFullscreen: action.isFullscreen };
            case SET_IS_LOCKED_CURRENCY_MODE:
                return {
                    ...gameState,
                    isLockedCurrencyMode: action.isLockedCurrencyMode,
                };
            case FORCE_IFRAME_RESIZE:
                return gameState;
            case FORCE_IFRAME_RESIZE_CLEAR:
                return gameState;
            case SET_LOADING_STATE:
                return {
                    ...gameState,
                    loadingStatus: action.loadingStatus,
                    isLoading: action.loadingStatus === GAME_LOADING,
                };
            case SET_IS_COUNTING_UP_CURRENCY: {
                return {
                    ...gameState,
                    isCountingUpCurrency: action.isCountingUpCurrency,
                };
            }
            case SET_CURRENCY_DISPLAY_ANIMATE_ABLE: {
                return {
                    ...gameState,
                    isCurrencyDisplayAnimateAble: action.isCurrencyDisplayAnimateAble,
                };
            }
            case SET_SHARED_RGS_GAME_DATA: {
                return {
                    ...gameState,
                    gameId: action.gameId,
                    myName: action.gameName,
                    company: action.company,
                    hasGameInit: true,
                    isCurrencyDisplayAnimateAble: true,
                    isClockDisabled: action.isClockDisabled,
                };
            }
            case SET_RGS_GAME_TOKEN: {
                return {
                    ...gameState,
                    rgsGameToken: action.gameToken,
                };
            }
            case SET_IS_AUTO_PLAY: {
                return {
                    ...gameState,
                    isAutoPlay: action.autoPlay,
                };
            }
            case SET_IS_RGS_GAME_LOADING: {
                return {
                    ...gameState,
                    isRgsGameLoading: action.isLoading,
                };
            }
            case SET_GAME_LOAD_START_DATE: {
                return {
                    ...gameState,
                    gameLoadStartDate: action.timestamp,
                };
            }
            case LAUNCH_GAME: {
                return {
                    ...gameState,
                };
            }
            case CHECK_IS_TEMPORARILY_UNAVAILABLE: {
                return {
                    ...gameState,
                };
            }
            case CHECK_PLAYABLE_TOKENS: {
                return {
                    ...gameState,
                };
            }
            case OPEN_GAME: {
                return {
                    ...gameState,
                };
            }
            case SET_IS_CLOCK_DISABLED: {
                return {
                    ...gameState,
                    isClockDisabled: action.isClockDisabled,
                };
            }
            default:
                assertNeverAction(action);
        }
    }
    return gameState;
};
