import { Appender } from './appender';
import { LogMessage } from './message';

export const createCompositeAppender = (...appenders: Appender[]): Appender => {
    return {
        append: (message: LogMessage) => {
            appenders.forEach((a) => a.append(message));
        },
        flush: () => {
            return Promise.all(appenders.map((a) => a.flush)).then(() => Promise.resolve());
        },
    };
};
