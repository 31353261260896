import React from 'react';
import { ThemeButton } from '../../button/theme-buttons';
import { ReactComponent as Pending } from './exclamation-icon.svg';
import { GenericModalLayout } from '../../../domain/account-verification/modals/generic-modal';
import { logout } from '../../../../helpers/logout';

export const MustKYCAccountPendingVerificationModal = () => {
    return (
        <GenericModalLayout
            header={
                <div className='header'>
                    <Pending />
                </div>
            }
        >
            <div className='must-kyc-modal__container'>
                <h2>Verification in progress</h2>
                <p>You will not be able to access Chumba until your account is verified.</p>
                <div className='modal-btns'>
                    <ThemeButton
                        id='must-kyc-modal__log-out-btn'
                        theme='red-dark'
                        actionType='primary'
                        size='md'
                        onClick={() => logout()}
                    >
                        LOG OUT
                    </ThemeButton>
                </div>
            </div>
        </GenericModalLayout>
    );
};

export const getMustKYCAccountPendingVerificationModalProps = () =>
    ({
        type: MUST_KYC_ACCOUNT_PENDING_VERIFICATION_MODAL,
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
    } as const);

export const MUST_KYC_ACCOUNT_PENDING_VERIFICATION_MODAL = 'MUST_KYC_ACCOUNT_PENDING_VERIFICATION_MODAL';
