import { Game, LAYOUT_ACTION_TYPES } from './types';
import { Jackpots } from 'store/jackpot/types';
import { updateJackpots } from 'store/jackpot/actions';
import { createSaga } from 'store/create';
import { getTopGames } from '../../layout/lobby-layout';
import { setCategories, setFavoriteGames, setRecommendedGames, setTopGames } from './actions';
import { lobbyCategoriesService } from 'layout/lobby-categories';
import { playerFavoritesService } from 'common/services/favorites';
import { recommendedGamesService } from 'common/services/recommended';

const getJackpots = (games: Game[]) => {
    const grandJackpots: Jackpots = {};
    const majorJackpots: Jackpots = {};
    games.forEach((game) => {
        const grandJackpotName = game.metadata.grandJackpotName;
        const majorJackpotName = game.metadata.majorJackpotName;
        if (grandJackpotName) {
            grandJackpots[grandJackpotName] = {
                goldCoinValue: 0,
                sweepsValue: 0,
                mustHitValueGold: 0,
                mustHitValueSweeps: 0,
            };
        }
        if (majorJackpotName) {
            majorJackpots[majorJackpotName] = {
                goldCoinValue: 0,
                sweepsValue: 0,
                mustHitValueGold: game.metadata.mustHitValueGold ? +game.metadata.mustHitValueGold : 0,
                mustHitValueSweeps: game.metadata.mustHitValueSweeps ? +game.metadata.mustHitValueSweeps : 0,
            };
        }
    });
    return { majorJackpots, grandJackpots };
};

export { saga as layoutSaga };

const { saga, handle } = createSaga();

handle(LAYOUT_ACTION_TYPES.MODIFY_LAYOUT, (_, dispatch, state) => {
    const { games } = state.layout;
    if (games) {
        const { grandJackpots, majorJackpots } = getJackpots(games);
        dispatch(updateJackpots(grandJackpots, majorJackpots));
    }
});

handle('USER_INITIALISATION_COMPLETE', async (_, dispatch, state) => {
    const { user } = state;
    const { userId } = user;

    const [topGames, recommendedGames, favoriteGames, categories] = await Promise.all([
        getTopGames(),
        recommendedGamesService.getRecommendedGames(userId),
        playerFavoritesService.getFavorites(userId),
        lobbyCategoriesService.getLobbyCategories(),
    ]);

    dispatch(setTopGames(topGames));
    dispatch(setRecommendedGames(recommendedGames));
    dispatch(setFavoriteGames(favoriteGames));
    dispatch(setCategories(categories));
});

handle(LAYOUT_ACTION_TYPES.ADD_FAVORITE_GAMES, (action, _dispatch, state) => {
    const { userId } = state.user;
    playerFavoritesService.addFavorites(userId, action.payload);
});

handle(LAYOUT_ACTION_TYPES.REMOVE_FAVORITE_GAMES, (action, _dispatch, state) => {
    const { userId } = state.user;
    playerFavoritesService.removeFavorites(userId, action.payload);
});
