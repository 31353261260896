import { Game } from 'store/layout/types';

export const DEFAULT_GAMES_LIST = [
    'bombShellJackpots',
    'slingo-redhot',
    'cascadeBeats',
    'jadesJackpots',
    'vs20fruitsw',
    'twinspinmw_not_mobile',
    'fruit-smash',
    'arrr10kways',
    'vs25wolfgold',
    'moneytrain2',
];

export const MIN_GAMES_TO_RECOMMEND = 4;

// TODO: This mapping function is to be removed after GameSage Update to SF.
export function mapToGameIdsWithinGamesList(gameIdsOrNames: string[], gamesList: Game[]): string[] {
    const gameIds = gameIdsOrNames.map((gameIdOrName) => {
        let gameIdFound = '';
        gamesList.forEach((game) => {
            const { gameName, gameId } = game.metadata;
            if (gameIdOrName === gameName || gameIdOrName === gameId) {
                gameIdFound = gameId;
            }
        });
        return gameIdFound;
    });
    const gameIdsWithinGamesList = gameIds.filter((gameId) => {
        return gameId !== '';
    });
    return gameIdsWithinGamesList;
}
