import { Dispatch } from 'redux';
import { AppAction } from 'store/app-action';
import { AppState } from 'store/app-state';
import { queueModal } from 'store/modal/actions';
import { createSession } from 'components/domain/packages/api/amoe-hub';
import { getDefaultErrorModalProps } from 'components/elements/modal/common/error';
import { AmoeCreateSessionAction } from '../actions';

const shouldHandleAction = (appState: AppState) => {
    const { isUserInitialisationComplete, promotionalPlay } = appState.system;
    return isUserInitialisationComplete && promotionalPlay === 'ENABLED';
};

const redirectUser = (url: string) => {
    window.location.href = url;
};

async function handleAction(dispatch: Dispatch<AppAction>, action: AmoeCreateSessionAction) {
    const { returnUrl } = action;
    const amoeSession = await createSession({ returnUrl });

    if (!amoeSession.success) {
        dispatch(queueModal(getDefaultErrorModalProps()));
        return;
    }

    redirectUser(amoeSession.redirectUrl ?? '/');
}

export const amoeCreateSessionHandler = async (
    action: AmoeCreateSessionAction,
    dispatch: Dispatch<AppAction>,
    appState: AppState
) => {
    if (shouldHandleAction(appState)) {
        await handleAction(dispatch, action);
    }
};
